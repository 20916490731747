import "../estilos/MD_modales.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/BF_BordesFondos.css";

import ic_cancelar_gris from '../recGraficos/ic_cancelar_gris.png'
import { C_colorAccent } from "../constantes/generales";

const Modal = ({ children, isOpen, closeModal, imgModal, titulo="", txtAceptar="ACEPTAR", txtCancelar="CANCELAR", clickCerrar=null, clickCancelar=null, clickAceptar, isCancelable=true}) => {
  const handleModalContainerClick = (e) => e.stopPropagation();

  let margenAbajo="50px";
  if(txtAceptar==="" && txtCancelar==="") margenAbajo="0px";

  let estiloChildren = {
      marginTop:"40px", 
      marginBottom:margenAbajo, 
      overflow: "auto", 
      maxHeight:"75vh"
  };

  const cerrar = () => {
    if(clickCerrar===null) closeModal();
    else clickCerrar();    
  } 
  
  const cancelar = () => {
    if(clickCancelar===null) cerrar();
    else clickCancelar();    
  } 

  const nada = () => {} 

  return (
    <article className={`MD_Modal ${isOpen && "isOpen"}`} onClick={isCancelable ? (cerrar) : (nada)}>
      <div className="MD_Contenedor" onClick={handleModalContainerClick} style={{margin:"25px"}}>
        <div style={{position: "absolute", top: "0rem", left: "0rem", width:"85%", marginLeft:"10px", marginTop:"10px", display:"flex"}}>
            {imgModal!=null && <div><img className="IM_imgIcono24" src={imgModal} style={{marginRight:"10px"}}/></div>}
            {titulo!=="" && <div className="TX_unaSolaLinea2" style={{fontSize:"18px", fontWeight:"bold", borderBottom:"2px solid " + C_colorAccent, paddingBottom:"4px", textAlign:"left"}}>{titulo}</div>}
        </div>
        <div className="MD_Cerrar" onClick={cerrar}><img className="IM_imgIcono24" src={ic_cancelar_gris} style={{marginLeft:"10px"}}/></div>


        <div style={estiloChildren}>
            {children}
        </div>

        {txtAceptar!=="" && txtCancelar!=="" && (
          <div className="MD_Botones">
            {txtCancelar!=="" && (
              <div className="BF_botonTransparenteFull" onClick={cancelar} style={{flexGrow:"3", flexShrink:"1"}}>{txtCancelar}</div>
            )}
            {txtCancelar==="" && (
              <div style={{flexGrow:"3", flexShrink:"1"}}>{""}</div>
            )}
            
            <div style={{flexGrow:"1", flexShrink:"1"}}></div>
            
            {txtAceptar!=="" && (
              <div className="BF_botonPrimaryFull" onClick={clickAceptar} style={{flexGrow:"3", flexShrink:"1"}}>{txtAceptar}</div>
            )}
            {txtAceptar==="" && (
              <div style={{flexGrow:"3", flexShrink:"1"}}>{""}</div>
            )}

          </div>
        )}
        
      </div>
    </article>
  );
};

export default Modal;