import {  useState, useEffect } from 'react';

import { reportarError, guardarDatoLocal, esformatoNumero, getParamsLink, showMensajePantalla} from '../helpers/funciones';
import { APP_ES_PRODUCCION, ID_MODULO_ADMIN_JUGADORES, ID_MODULO_BASE, ID_MODULO_DETALLE_EQUIPO, ID_MODULO_DETALLE_JUGADOR, ID_MODULO_DETALLE_PARTIDO, ID_MODULO_DETALLE_TORNEO, ID_MODULO_GESTION_MICUENTA, ID_MODULO_INFO_COBROS, ID_MODULO_INFO_COBROS_JUGADOR, ID_MODULO_INFO_EQUIPO, ID_MODULO_INFO_JUGADOR, ID_MODULO_INFO_MENSAJES, ID_MODULO_PRINCIPAL_INVITADO, ID_MODULO_PRINCIPAL_JUGADOR, SERVIDOR_PRINCIPAL, SERVIDOR_PRUEBAS } from '../constantes/generales';

import Main from './Main';
import Detalle_Torneo from "./Detalle_Torneo";
import Detalle_Partido from './Detalle_Partido';
import Detalle_Equipo from './Detalle_Equipo';
import Detalle_Jugador from './Detalle_Jugador';
import Principal_Jugador from './Principal_Jugador';
import Principal_Invitados from "./Principal_Invitados";
import Info_Equipo from './Info_Equipo';
import Admin_Jugadores from './Admin_Jugadores';
import Info_Jugador from './Info_Jugador';
import Gestion_MiCuenta from './Gestion_MiCuenta';
import Info_Cobros from './Info_Cobros';
import Info_Cobros_Jugador from './Info_Cobros_Jugador';
import { useParams } from 'react-router-dom';
import Info_Mensajes from './Info_Mensajes';

let ventanaMensaje;

const nomClase="Inicial_WB";
//const idModulo="0";


const Inicial = () => {
    const [paramOrigen, setParamOrigen] = useState("");
    const [paramDestino, setParamDestino] = useState("");
    const [moduloOrigen, setModuloOrigen] = useState(-1);
    const [moduloDestino, setModuloDestino] = useState(ID_MODULO_BASE);    

    const [showMensaje, setShowMensaje] = useState(false);

    let params = useParams();

    let presentar=false;
   

    if(APP_ES_PRODUCCION===true) guardarDatoLocal("rutaRecursos",SERVIDOR_PRINCIPAL + "recursos/");
    else guardarDatoLocal("rutaRecursos", SERVIDOR_PRUEBAS + "recursos/");
    

    const onPop=(e)=>{
        window.history.pushState("","","/");
        definirModulo(0,0,"",ID_MODULO_BASE,"");
    }


    useEffect(() => {
        const nomFuncion="useEffect";

        try{         

            window.addEventListener('popstate',onPop);

            let hayEmpresa=false;
            let haySede=false;
            let hayTorneo=false;

            if(moduloOrigen===-1){
                setModuloOrigen(0);
                
                if(Object.keys(params).length>0){
                    let getParams=getParamsLink(params.dato);
                                       
                    if(getParams[0]==="E" && esformatoNumero(getParams[1])) hayEmpresa=true;
                    else if(getParams[0]==="S" && esformatoNumero(getParams[1])) haySede=true;
                    else if(getParams[0]==="T" && esformatoNumero(getParams[1])) hayTorneo=true;

                    if(hayEmpresa || haySede){
                        setParamDestino(getParams[0] + "#" + getParams[1]);
                        setModuloDestino(ID_MODULO_PRINCIPAL_INVITADO);
                    }
                    else if(hayTorneo){
                        setParamDestino(getParams[1]);
                        setModuloDestino(ID_MODULO_DETALLE_TORNEO);
                        guardarDatoLocal("esLinkTorneo","1");
                    }
                    else{
                        ventanaMensaje=showMensajePantalla("LINK INCORRECTO","El Link ingresado NO es valido, por favor verifique la información e intente nuevamente.",setShowMensaje,null,"ERROR","");
                    }

                }
                
            } 


   
           return () =>{
            window.removeEventListener("popstate", onPop);
            } 

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }        

    }, []);



    const definirModulo = (registro, modOrigen, paramOrigen, modDestino, paramDestino) => {
        if(modDestino!==ID_MODULO_BASE && modDestino!==ID_MODULO_PRINCIPAL_JUGADOR && modDestino!==ID_MODULO_PRINCIPAL_INVITADO && 
            modDestino!==ID_MODULO_DETALLE_TORNEO && modDestino!==ID_MODULO_DETALLE_PARTIDO && modDestino!==ID_MODULO_DETALLE_EQUIPO && 
            modDestino!==ID_MODULO_DETALLE_JUGADOR && modDestino!==ID_MODULO_INFO_EQUIPO && modDestino!==ID_MODULO_ADMIN_JUGADORES && 
            modDestino!==ID_MODULO_INFO_JUGADOR && modDestino!==ID_MODULO_GESTION_MICUENTA && modDestino!==ID_MODULO_INFO_COBROS &&
            modDestino!==ID_MODULO_INFO_COBROS_JUGADOR && modDestino!==ID_MODULO_INFO_MENSAJES){
                modDestino=ID_MODULO_BASE;
        }  

        setParamOrigen(paramOrigen);
        setParamDestino(paramDestino);
        setModuloOrigen(modOrigen);
        setModuloDestino(modDestino);        
    }

    
    if(moduloOrigen===-1) return(<></>);

    
    return (
        <>

        {showMensaje && (ventanaMensaje)}

        {moduloDestino===ID_MODULO_BASE && (
            <Main  definirModulo={definirModulo}/>
        )}

        {(moduloDestino===ID_MODULO_PRINCIPAL_JUGADOR) && (
            <Principal_Jugador params={paramDestino} definirModulo={definirModulo} />
        )}

        {(moduloDestino===ID_MODULO_PRINCIPAL_INVITADO) && (
            <Principal_Invitados params={paramDestino}  modOrigen={moduloOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo}/>
        )}

        {moduloDestino===ID_MODULO_DETALLE_TORNEO && (
            <Detalle_Torneo params={paramDestino} modOrigen={moduloOrigen} paramOrigen={paramOrigen}  definirModulo={definirModulo} />
        )}

        {moduloDestino===ID_MODULO_DETALLE_PARTIDO && (
            <Detalle_Partido params={paramDestino} modOrigen={moduloOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />
        )}  

        {moduloDestino===ID_MODULO_DETALLE_EQUIPO && (
            <Detalle_Equipo params={paramDestino} modOrigen={moduloOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />
        )}  

        {moduloDestino===ID_MODULO_DETALLE_JUGADOR && (
            <Detalle_Jugador params={paramDestino} modOrigen={moduloOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />
        )}  

        {moduloDestino===ID_MODULO_INFO_EQUIPO && (
            <Info_Equipo params={paramDestino} modOrigen={moduloOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />
        )}  

        {moduloDestino===ID_MODULO_INFO_JUGADOR && (
            <Info_Jugador params={paramDestino} modOrigen={moduloOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />
        )}  

        {moduloDestino===ID_MODULO_GESTION_MICUENTA && (
            <Gestion_MiCuenta params={paramDestino} modOrigen={moduloOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />
        )} 

        {moduloDestino===ID_MODULO_INFO_COBROS && (
            <Info_Cobros params={paramDestino} modOrigen={moduloOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />
        )} 

        {moduloDestino===ID_MODULO_INFO_COBROS_JUGADOR && (
            <Info_Cobros_Jugador params={paramDestino} modOrigen={moduloOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />
        )} 

        {moduloDestino===ID_MODULO_ADMIN_JUGADORES && (
            <Admin_Jugadores params={paramDestino} modOrigen={moduloOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />
        )} 

        {moduloDestino===ID_MODULO_INFO_MENSAJES && (
            <Info_Mensajes params={paramDestino} modOrigen={moduloOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />
        )} 
           
        </>
    );

};

export default Inicial;