import { useEffect, useState } from "react";
import ShowError from "../recVisual/ShowError";
import Loader from "../recVisual/Loader";
import { getDatos } from "../gestionDatos/getDatos";
import { C_colorPrimary, C_colorPrimaryDark, C_uniformeAzulClaro, C_uniformeBlanco, C_uniformeGrisClaro, C_uniformeGrisOscuro, C_uniformeGrisSuperClaro, C_uniformeNegro, C_uniformeRosa, ID_MODULO_INFO_MENSAJES, ID_MODULO_PRINCIPAL_JUGADOR, SEP_4, SEPARADOR_PARAMETROS_BD } from "../constantes/generales";

import ic_salir from '../recGraficos/ic_salir_w.png';
import ic_mensaje_blanco from '../recGraficos/ic_mensaje_blanco.png';
import ic_mensaje_negro from '../recGraficos/ic_mensaje_negro.png';
import ic_flecha_expandir from '../recGraficos/ic_flecha_expandir.png';
import ic_buscar_gris from '../recGraficos/ic_buscar_gris.png';
import ic_transparente from '../recGraficos/ic_transparente.png';
import ic_manual_primary from '../recGraficos/ic_manual_primary.png';
import ic_borrar_rojo from '../recGraficos/ic_borrar_rojo.png';
import ic_guardar_gris from '../recGraficos/ic_guardar_gris.png';
import ic_responder_gris from '../recGraficos/ic_responder_gris.png';
import ic_responder_negro from '../recGraficos/ic_responder_negro.png';

import "../estilos/CT_Contenedor.css";
import "../estilos/BF_BordesFondos.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/TX_Textos.css";


import { crearTabla, dividirLista, formatearFecha, getConfirmacion, guardarDatoLocal, isFlagUpdate, presentarError, presentarLog, quitarCharEspeciales, quitarTildes, reconstruirDatosOrigen, recuperarDatoLocal, registrarLogEventos, reportarError, setFlagUpdate, showMensajePantalla, superaTiempoActualizacion } from "../helpers/funciones";
import DinamicoListaMensajes from "../gestionVisual/DinamicoListaMensajes";
import Modal from "../recVisual/Modal";
import { useModal } from "../helpers/useModal";


const ruta=recuperarDatoLocal("rutaRecursos");
const nomClase="Info_Mensajes_WB";
const idModulo=ID_MODULO_INFO_MENSAJES;


let idUser="";
//let tipoUser="";

let tipoLista="todos";

let titulo="";
let msjVentana="";
let ventanaMensaje;

let matrizCampos=[
    {id: "MD1_RESPUESTA",  idx: 0, valor: "", error: "Ingrese un texto válido. (Mínimo 2 caracteres)"}
];

let cancelarBusqueda=false;
let datosProcesadosTI=false;
let datosProcesados=[];

let mensajeTotal = {
    idmensaje:"",
    idestado_mensaje:"",
    idusuario:"",
    fecha_vencimiento:"",
    textoini:"",
    textofin:"",
    imagen:"",
    link:"",
    textolink:"",
    correoUser:"",
    sedeUser:"",
    leido:"",
    guardado:"",
    borrado:"",
    fecha_respuesta:"",
    respuesta:"",
    puede_responder:""
}

let mensaje = {
    index:-1,
    idmensaje:"",
    fotoUser:"",
    empresaUser:"",
    nombreUser:"",
    titulo:"",
    idestado_mensaje:"",
    fecha_envio:"",
    imagen:"",
    leido:"",
    guardado:"",
    contestado:"",
    regDetalle:-1
}

var infoMensajesO = [];
var infoMensajes = [];
function crearInfoMensajes(datos){   
    const nomFuncion="crearInfoMensajes";

    try{
        
    
        infoMensajesO = [];
        infoMensajes = [];
    
        let i, j, k;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , mensaje);
            registro.index=i;
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idmensaje=datos[i][j];
                if(j===1) registro.fotoUser=datos[i][j];
                if(j===2) registro.empresaUser=datos[i][j];
                if(j===3) registro.nombreUser=datos[i][j];
                if(j===4) registro.titulo=datos[i][j];
                if(j===5) registro.idestado_mensaje=datos[i][j];
                if(j===6) registro.fecha_envio=datos[i][j];
                if(j===7) registro.imagen=datos[i][j];
                if(j===8) registro.leido=datos[i][j];
                if(j===9) registro.guardado=datos[i][j];
                if(j===10) registro.contestado=datos[i][j];
            }          

            for(k=0;k<infoMensajeTotalO.length;k++){
                if(registro.idmensaje===infoMensajeTotalO[k].idmensaje){
                    registro.regDetalle=k;
                    //datos[i][11]=k;
                    k=infoMensajeTotalO.length;
                }
            }

            infoMensajesO.push(registro);
            infoMensajes.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}

var infoMensajeTotalO = [];
var infoMensajeTotal = [];
function crearInfoMensajeTotal(datos, tipo="NUEVO"){   
    const nomFuncion="crearInfoMensajeTotal";

    try{
        
        if(tipo==="NUEVO"){
            infoMensajeTotalO = [];
            infoMensajeTotal = [];
        }
        
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , mensajeTotal);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idmensaje=datos[i][j];
                if(j===1) registro.idestado_mensaje=datos[i][j];
                if(j===2) registro.idusuario=datos[i][j];
                if(j===3) registro.fecha_vencimiento=datos[i][j];
                if(j===4) registro.textoini=datos[i][j];
                if(j===5) registro.textofin=datos[i][j];
                if(j===6) registro.imagen=datos[i][j];
                if(j===7) registro.link=datos[i][j];
                if(j===8) registro.textolink=datos[i][j];
                if(j===9) registro.correoUser=datos[i][j];
                if(j===10) registro.sedeUser=datos[i][j];
                if(j===11) registro.leido=datos[i][j];
                if(j===12) registro.guardado=datos[i][j];
                if(j===13) registro.borrado=datos[i][j];
                if(j===14) registro.fecha_respuesta=datos[i][j];
                if(j===15) registro.respuesta=datos[i][j];
                if(j===16) registro.puede_responder=datos[i][j];
            }

            infoMensajeTotalO.push(registro);
            infoMensajeTotal.push(datos[i]);
            

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}


const Info_Mensajes = ({params="", definirModulo, modOrigen=0, paramOrigen=""}) => {
    const [esError, setEsError] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingZonal, setLoadingZonal] = useState(false);
    const [showMensaje, setShowMensaje] = useState(false);

    const [filtroUS, setFiltroUS] = useState("");
    const [tipoListaUS, setTipoListaUS] = useState("nada");
    const [mensajesUS, setMensajesUS] = useState([]);
    const [mensajeSelUS, setMensajeSelUS] = useState(mensajeTotal);
    const [regMsjSelUS, setRegMsjSelUS] = useState(-1);

    const [isOpenMensajeSel, openMensajeSel, closeMensajeSel] = useModal(false);
    const [isOpenRespuesta, openRespuesta, closeRespuesta] = useModal(false);
    const [clickAceptarModal, setClickAceptarModal] = useState(false);

    const [arrCampos, setArrCampos] = useState(matrizCampos);
   

     var respuesta = {
            datos: "",
            exito: false,
            conectado: false,
            hayDatos: false,
            mensaje: ''
        }
    
        useEffect(() => {
            const nomFuncion="useEffect";
    
            try{     
                
                idUser=recuperarDatoLocal("idUser");
        
                if(true){
                    let actualizar=false;
                    let info=dividirLista(params,SEP_4,2);                    
                    
    
                    if(info[0]==="1"){
                        actualizar=true;
                    }
    
                    if(!actualizar) {
                        actualizar= (isFlagUpdate() || superaTiempoActualizacion(idModulo));
                    }
                    
                    //actualizar=true;
                    if(actualizar){
                        BD_solicitarDatos("INFO_INICIAL",[]);    
                    }
                    else{
                        cargarDatosGuardadosModulo();
                    }

                    
                    
                }
        
    
            }catch(err){
                reportarError( nomClase, nomFuncion, "", err);
            }     
            
    
        }, [params]);

    
        useEffect(() => {
            const nomFuncion="useEffect.tipolista.filtro";
    
            try{     

                cancelarBusqueda=true;
                buscarTexto();
        
    
            }catch(err){
                reportarError( nomClase, nomFuncion, "", err);
            }     
            
    
        }, [tipoListaUS, filtroUS]);


    //if(params==="") return null;

    const buscarTexto = () => {
            let nomFuncion="buscarTexto";
    
            try{
                cancelarBusqueda=false;

                let textoBuscar=filtroUS;

                let regPagina=200;
                let tempDatosEncontrados=[];
    
    
    
                if(infoMensajesO.length>0){
                    
                    let datos=JSON.parse(JSON.stringify(infoMensajesO));
      
                    if(!datosProcesadosTI && datos.length>0){
                        datosProcesados=JSON.parse(JSON.stringify(datos));

                        for(let i=0;i<datos.length;i++){
                            datosProcesados[i].empresaUser=quitarTildes(datos[i].empresaUser).toUpperCase();
                            datosProcesados[i].nombreUser=quitarTildes(datos[i].nombreUser).toUpperCase();
                            datosProcesados[i].titulo=quitarTildes(datos[i].titulo).toUpperCase();
                        }
                        
                        datosProcesadosTI=true;
                    }

    
                    let total=0;
                    let textoBuscado="";
                    
                    textoBuscado=quitarTildes(textoBuscar).toUpperCase();
    
                    if(datos.length>0){
                        let i;
                        cancelarBusqueda=false;

                        for(i=0;i<datos.length;i++){
                            if(cancelarBusqueda) return;

                            if(!cancelarBusqueda){
                                let incluir=false;

                                if(!incluir){
                                    if(tipoListaUS==="sinleer" && datos[i].leido==="0") incluir=true;
                                    if(tipoListaUS==="leido" && datos[i].leido==="1") incluir=true;
                                    if(tipoListaUS==="todos") incluir=true;
                                }

                                if(datos[i].idmensaje==="") incluir=false;

                                if(incluir && textoBuscado!==""){
                                    if(!datosProcesados[i].empresaUser.includes(textoBuscado) &&
                                        !datosProcesados[i].nombreUser.includes(textoBuscado) &&
                                        !datosProcesados[i].titulo.includes(textoBuscado)) incluir=false;
                                }   
                                

                                if(incluir){
                                    total++;                                    

                                    tempDatosEncontrados.push(datos[i]);

                                    if(total>=regPagina) i=datos.length;
                                }

                            }
                            else i=datos.length;

                        }

                    }
    
                        
                }
                
                
                setMensajesUS(tempDatosEncontrados);
                setRegMsjSelUS(-1);
                setMensajeSelUS(mensajeTotal);
    
                 
    
    
            }catch(err){
                reportarError( nomClase, nomFuncion, "", err);
            } 
    
        }
    
    
    const BD_solicitarDatos = (tipoDatos, params) => {
        let nomFuncion="BD_solicitarDatos"; 

        try{
            let actualizar=true;
            let SEP=SEPARADOR_PARAMETROS_BD;
            let paramQuery="";
            let tipoConsulta="";
            let funcion="";
            let consulta="";
            let zonaHoraria="0";
            let mostrarError=true;
            let mostrarProcesando=true;

            
            if(actualizar){                

                if(tipoDatos==="INFO_INICIAL"){
                    tipoConsulta="consultarTablaSeparada";
                    funcion="getListaMensajes";
                    consulta="INFO_LISTA_MENSAJES_ENTRADA";
                    
                    paramQuery= idUser;
                } else if(tipoDatos==="INFO_MENSAJE_ESPECIFICO"){
                    tipoConsulta="consultarTablaSeparada";
                    funcion="getListaMensajes";
                    consulta="INFO_MENSAJE_ESPECIFICO";

                    mostrarProcesando=false;
                    setLoadingZonal(true);
                    
                    let idMensaje=params[0];
                    paramQuery=idUser + SEP + idMensaje;
                } else if(tipoDatos==="ACTUALIZAR_LECTURA_MENSAJE"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarMensajes";
                    consulta="ACTUALIZAR_LECTURA_MENSAJE";

                    mostrarProcesando=false;
                    
                    let idSede=recuperarDatoLocal("idSede");
                    let idMensaje=params[0];

                    paramQuery=idSede + SEP + idMensaje + SEP + idUser;

                    setFlagUpdate(true);
                } else if(tipoDatos==="BORRAR_MENSAJE_USUARIO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarMensajes";
                    consulta="BORRAR_MENSAJE_USUARIO";

                    mostrarProcesando=false;
                    
                    let idSede=recuperarDatoLocal("idSede");
                    let idMensaje=params[0];

                    paramQuery=idSede + SEP + idMensaje + SEP + idUser;

                } else if(tipoDatos==="GUARDAR_MENSAJE_USUARIO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarMensajes";
                    consulta="GUARDAR_MENSAJE_USUARIO";

                    mostrarProcesando=false;
                    
                    let idSede=recuperarDatoLocal("idSede");
                    let idMensaje=params[0];

                    paramQuery=idSede + SEP + idMensaje + SEP + idUser;

                } else if(tipoDatos==="GUARDAR_RESPUESTA_MENSAJE_USUARIO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarMensajes";
                    consulta="GUARDAR_RESPUESTA_MENSAJE_USUARIO";

                    mostrarProcesando=false;
                    
                    let idSede=recuperarDatoLocal("idSede");
                    let idMensaje=params[0];
                    let txtRespuesta=arrCampos[0].valor;

                    paramQuery=idSede + SEP + idMensaje + SEP + idUser + SEP + txtRespuesta;

                } 
                
                if(mostrarProcesando) setLoading(true);

                getDatos()
                .get(tipoConsulta,funcion,consulta,paramQuery,zonaHoraria)
                .then((res) => {
                    nomFuncion="BD_solicitarDatos.respuesta"; 

                    try{
                        respuesta=res;
                        if (respuesta.exito) {
                            if(tipoDatos==="INFO_INICIAL"){
                                presentarLog(nomClase + " - SOLICITA_DATOS"); 
    
                                guardarDatoLocal("ultAcceso" + idModulo,Date.now());
                                guardarDatoLocal("datos" + idModulo, respuesta.datos);
    
                                crearInfoMensajeTotal(crearTabla(respuesta.datos,"2"));
                                crearInfoMensajes(crearTabla(respuesta.datos,"1"));


                                setTipoListaUS("todos");
                            
                                registrarLogEventos(idModulo, "3", "","1");
                            } else if(tipoDatos==="INFO_MENSAJE_ESPECIFICO"){
                                presentarLog(nomClase + " - SOLICITA_DATOS_MENSAJE"); 
        
                                crearInfoMensajeTotal(crearTabla(respuesta.datos,"2"),"AGREGAR");

                                let regNuevo=infoMensajeTotalO.length-1;

                                let idmensaje=params[0];
                                let regMsjBase=params[1];
                                let regMsjMostradosUS=params[2];
                                infoMensajesO[regMsjBase].regDetalle=regNuevo;

                                setLoadingZonal(false);
                                setRegMsjSelUS(regMsjMostradosUS);
                                setMensajeSelUS(infoMensajeTotalO[regNuevo]);

                                if(infoMensajeTotalO[regNuevo].leido!=="1") 
                                    BD_solicitarDatos("ACTUALIZAR_LECTURA_MENSAJE",[idmensaje, regMsjBase, regNuevo]);
                            
                                reconstruirListasModulo();

                                registrarLogEventos(idModulo, "4", idmensaje,"");

                            } else if(tipoDatos==="ACTUALIZAR_LECTURA_MENSAJE"){
                                presentarLog(nomClase + " - ACTUALIZAR_LECTURA_MENSAJE"); 

                                let regMsjBase=params[1];
                                let regMsjTotal=params[2];        
                                
                                infoMensajesO[regMsjBase].leido="1";
                                infoMensajes[regMsjBase][8]="1";
                                infoMensajeTotalO[regMsjTotal].leido="1";
                                infoMensajeTotal[regMsjTotal][11]="1";

                                reconstruirListasModulo();
                            } else if(tipoDatos==="BORRAR_MENSAJE_USUARIO"){
                                presentarLog(nomClase + " - BORRAR_MENSAJE_USUARIO"); 

                                let regMsjBase=params[1];
                                let regMsjTotal=params[2];       
                                                                
                                infoMensajesO[regMsjBase].idmensaje="";
                                infoMensajes[regMsjBase][0]="";
                                infoMensajeTotalO[regMsjTotal].borrado="1";
                                infoMensajeTotal[regMsjTotal][13]="1";

                                reconstruirListasModulo();

                                titulo="";
                                msjVentana="Mensaje borrado con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,msjVentana,setShowMensaje,null,"EXITO","BAJA");

                            } else if(tipoDatos==="GUARDAR_MENSAJE_USUARIO"){
                                presentarLog(nomClase + " - GUARDAR_MENSAJE_USUARIO"); 

                                let regMsjBase=params[1];
                                let regMsjTotal=params[2];       
                                                                
                                infoMensajesO[regMsjBase].guardado="1";
                                infoMensajes[regMsjBase][9]="1";
                                infoMensajeTotalO[regMsjTotal].guardado="1";
                                infoMensajeTotal[regMsjTotal][12]="1";

                                reconstruirListasModulo();

                                titulo="";
                                msjVentana="Mensaje guardado con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,msjVentana,setShowMensaje,null,"EXITO","BAJA");
                            } else if(tipoDatos==="GUARDAR_RESPUESTA_MENSAJE_USUARIO"){
                                presentarLog(nomClase + " - GUARDAR_RESPUESTA_MENSAJE_USUARIO"); 

                                let fecha=respuesta.datos;

                                let regMsjBase=params[1];
                                let regMsjTotal=params[2]; 
                                let txtRespuesta=arrCampos[0].valor;      
                                                                
                                infoMensajesO[regMsjBase].contestado="1";
                                infoMensajes[regMsjBase][10]="1";
                                infoMensajeTotalO[regMsjTotal].fecha_respuesta=fecha;
                                infoMensajeTotalO[regMsjTotal].respuesta=txtRespuesta;
                                infoMensajeTotal[regMsjTotal][14]=fecha;
                                infoMensajeTotal[regMsjTotal][15]=txtRespuesta;

                                reconstruirListasModulo();

                                titulo="";
                                msjVentana="Respuesta guardada con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,msjVentana,setShowMensaje,null,"EXITO","BAJA");
                            }                        
            
                        } else {
                            if(respuesta.conectado){
                                reportarError( nomClase, nomFuncion, respuesta.mensaje, "");
                                if(mostrarError) setEsError(1);
                            } 
                            else{    
                                presentarError(nomClase,nomFuncion,respuesta.mensaje,"");                        
                                setEsError(2);
                            }                       
                        }

                    }catch(err){
                        reportarError( nomClase, nomFuncion, "", err);
                    } 
                    
        
                    setLoading(false);
                    
                    
                });
            }
            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 


    const cargarDatosGuardadosModulo = () => {
            let nomFuncion="cargarDatosGuardadosModulo"; 
    
            try{             
                presentarLog(nomClase + " - RECUPERA_DATOS"); 
                let datosModulo=recuperarDatoLocal("datos" + idModulo);
    
                if(datosModulo!==""){
                    crearInfoMensajeTotal(crearTabla(datosModulo,"2"));
                    crearInfoMensajes(crearTabla(datosModulo,"1"));

                    setTipoListaUS("todos");

                    registrarLogEventos(idModulo, "3", "","0");
                } 
                else BD_solicitarDatos("INFO_INICIAL",[]);
    
            }catch(err){
                reportarError( nomClase, nomFuncion, "", err);
            } 
        }

    
    const reconstruirListasModulo = () => {
            let nomFuncion="reconstruirListasModulo"; 
    
            try{ 
                
                let datosFinales=[];
                datosFinales=reconstruirDatosOrigen(datosFinales,infoMensajes,"1");
                datosFinales=reconstruirDatosOrigen(datosFinales,infoMensajeTotal,"2");
                
                guardarDatoLocal("datos" + idModulo, datosFinales);
    
            }catch(err){
                reportarError( nomClase, nomFuncion, "", err);
            }  
        } 


    const cambiarFiltro  = (elemento) => {
        setFiltroUS(elemento.target.value.toUpperCase());
    };


    const salir = () => {       
        definirModulo(0,idModulo,params,ID_MODULO_PRINCIPAL_JUGADOR,recuperarDatoLocal("idUser"));     
    }


    const clickGenerico = (donde, params=null) => {
            let nomFuncion="clickGenerico"; 
    
            try{
                
                //let tempArreglo=null;
                let tempMensajes=null;
                let regMsjBase;
                let regMsjMostradosUS;
                
                switch(donde) {
                      
                    case "CLICK_MENSAJE_BASE":

                        regMsjBase=params[0];
                        regMsjMostradosUS=params[2];
                        

                        if(params[1]==="SELECCIONAR"){
                            let regMsjTotal=infoMensajesO[regMsjBase].regDetalle;

                            
                            setRegMsjSelUS(regMsjMostradosUS);
                            if(regMsjTotal>=0){
                                setMensajeSelUS(infoMensajeTotalO[regMsjTotal]);
                                

                                if(infoMensajeTotalO[regMsjTotal].leido!=="1")
                                    BD_solicitarDatos("ACTUALIZAR_LECTURA_MENSAJE",[infoMensajesO[regMsjBase].idmensaje, regMsjBase, regMsjTotal]);
                            }
                            else{
                                BD_solicitarDatos("INFO_MENSAJE_ESPECIFICO",[infoMensajesO[regMsjBase].idmensaje, regMsjBase, regMsjMostradosUS]);
                            }
                            openMensajeSel();
                        }
                        else{
                            closeMensajeSel();
                            setRegMsjSelUS(-1);
                            setMensajeSelUS(mensajeTotal);

                            
                        }
    
                        break;
                    case "SOLICITAR_CONFIRMACION_BORRADO_MENSAJE":
                        titulo="CONFIRMAR BORRAR";
                        msjVentana="Esta acción BORRARÁ TODA la información asociada al Mensaje seleccionado. Esta de acuerdo?";
                        ventanaMensaje=getConfirmacion(titulo,msjVentana,setShowMensaje,null,clickGenerico,"CONFIRMO_BORRAR_MENSAJE");
                    
                        break;
                    case "CONFIRMO_BORRAR_MENSAJE":

                        setShowMensaje(false);

                        regMsjBase=mensajesUS[regMsjSelUS].index;
                        regMsjMostradosUS=regMsjSelUS;

                        tempMensajes=JSON.parse(JSON.stringify(mensajesUS));
                        tempMensajes[regMsjMostradosUS].idmensaje="";

                        closeMensajeSel();
                        setMensajesUS(tempMensajes);
                        setRegMsjSelUS(-1);
                        setMensajeSelUS(mensajeTotal);
                        
                        BD_solicitarDatos("BORRAR_MENSAJE_USUARIO",[infoMensajesO[regMsjBase].idmensaje, regMsjBase, infoMensajesO[regMsjBase].regDetalle]);

                        
                    
                        break;
                    case "SOLICITAR_CONFIRMACION_GUARDADO_MENSAJE":
                            titulo="CONFIRMAR GUARDAR";
                            msjVentana="Esta acción guardará este mensaje y podrá consultarlo despues de superada la fecha de VIGENCIA del mensaje.\n\nEsta de acuerdo?";
                            ventanaMensaje=getConfirmacion(titulo,msjVentana,setShowMensaje,null,clickGenerico,"CONFIRMO_GUARDAR_MENSAJE");
                        
                            break;
                    case "CONFIRMO_GUARDAR_MENSAJE":
    
                            setShowMensaje(false);
    
                            regMsjBase=mensajesUS[regMsjSelUS].index;
                            regMsjMostradosUS=regMsjSelUS;
    
                            tempMensajes=JSON.parse(JSON.stringify(mensajesUS));
                            tempMensajes[regMsjMostradosUS].guardado="1";
                            setMensajesUS(tempMensajes);

                            tempMensajes=JSON.parse(JSON.stringify(mensajeSelUS));
                            tempMensajes.guardado="1";
                            setMensajeSelUS(tempMensajes);
                            
                            BD_solicitarDatos("GUARDAR_MENSAJE_USUARIO",[infoMensajesO[regMsjBase].idmensaje, regMsjBase, infoMensajesO[regMsjBase].regDetalle]);
    
                            
                        
                            break;
                    case "ABRIR_MODAL_RESPUESTA":
                        setArrCampos(matrizCampos);
                        setClickAceptarModal(false);
                        openRespuesta();
                        
                        break;
                    case "GUARDAR_RESPUESTA_MENSAJE_USUARIO":
                        setClickAceptarModal(true);
                        if(arrCampos[0].error==="" && arrCampos[0].valor!==""){
                            closeRespuesta();
    
                            regMsjBase=mensajesUS[regMsjSelUS].index;
                            regMsjMostradosUS=regMsjSelUS;
    
                            tempMensajes=JSON.parse(JSON.stringify(mensajesUS));
                            tempMensajes[regMsjMostradosUS].contestado="1";
                            setMensajesUS(tempMensajes);

                            const tiempoTranscurrido = Date.now();
                            const hoy = new Date(tiempoTranscurrido);
                            let fecha=hoy.getFullYear() + "-" + (hoy.getMonth()+1) + "-" + hoy.getDate() + " " + hoy.getHours() + ":" + hoy.getMinutes() + ":" + hoy.getSeconds();

                            
                            tempMensajes=JSON.parse(JSON.stringify(mensajeSelUS));
                            tempMensajes.fecha_respuesta=fecha;
                            tempMensajes.respuesta=arrCampos[0].valor;
                            setMensajeSelUS(tempMensajes);
                            
                            BD_solicitarDatos("GUARDAR_RESPUESTA_MENSAJE_USUARIO",[infoMensajesO[regMsjBase].idmensaje, regMsjBase, infoMensajesO[regMsjBase].regDetalle]);

                        }

                        break;
                    case "CERRAR_MODAL_MENSAJE":
                        closeMensajeSel();
                        setRegMsjSelUS(-1);
                        setMensajeSelUS(mensajeTotal);

                        break;
                    default:
                        break;
                }
    
            }catch(err){
                reportarError( nomClase, nomFuncion, "", err);
            }  
        } 

    const validarCampo = (e, campo, maxlen) => {
        let nomFuncion="validarCampo"; 

        try{
            let newValor = e.target.value;
            if(maxlen!==null && newValor.length>maxlen) newValor=newValor.substring(0,maxlen);

            let tempArreglo=JSON.parse(JSON.stringify(arrCampos));

            if(campo==="MD1_RESPUESTA"){  
                tempArreglo[0].error=matrizCampos[0].error;
                newValor=quitarCharEspeciales(newValor);
                if(newValor===""){
                    tempArreglo[0].valor="";                    
                }
                else{
                    tempArreglo[0].valor=newValor; 
                    if(newValor.length>=2) tempArreglo[0].error="";                  
                }   
                
                setArrCampos(tempArreglo);
            }
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }         
        
    }

    function mostrarMensajeSel(tipo="LOCAL"){

        
        if(tipo==="LOCAL"){
            return(
                <div style={{display:"flex", flexDirection:"column", flexWrap:"nowrap", height:"100%", maxWidth:"100%" }}>
        
                    <div style={{padding:"8px", backgroundColor:C_uniformeGrisSuperClaro}}>{regMsjSelUS===-1 ? 
                        (<div style={{fontSize:"16px", fontWeight:"bold"}}>Seleccione el mensaje a visualizar</div>) : 
                        (
                        <div>
                            <div style={{display:"flex", flexWrap:"nowrap", fontSize:"14px"}}>
                                <div >Enviado:</div><div style={{color:C_uniformeAzulClaro, marginLeft:"10px"}}>{formatearFecha(mensajesUS[regMsjSelUS].fecha_envio,4)}</div>                                           
                            </div>
                            <div style={{display:"flex", flexWrap:"nowrap"}}>
                                <div >Vigente hasta:</div>
                                {mensajeSelUS.idmensaje!=="" && (<div style={{color:C_uniformeRosa, marginLeft:"10px"}}>{formatearFecha(mensajeSelUS.fecha_vencimiento,9)}</div>)}                                            
                            </div>
                        </div>
                        )}
                    </div>
                    <div style={{height:"1px", borderBottom:"1px solid " + C_uniformeGrisClaro}}></div>
        
                        
        
                    <div style={{flex:"1", overflowY:"auto",  height:"100%",  maxHeight:"100%", maxWidth:"100%"}}>
        
                        
        
                            {regMsjSelUS>=0 && mensajeSelUS.idmensaje!=="" && (
                                <div style={{padding:"10px", margin:"10px"}}>
        
                                    <div style={{textAlign:"left", fontSize:"16px", color:C_uniformeNegro, fontWeight:"bold"}}>{mensajesUS[regMsjSelUS].titulo}</div>
        
                                    {mensajeSelUS.textoini!=="" && (
                                        <div style={{textAlign:"left", fontSize:"14px", color:C_uniformeGrisOscuro, marginTop:"20px", whiteSpace:"pre-wrap"}}>{mensajeSelUS.textoini}</div>
                                    )}
        
                                    {mensajeSelUS.imagen!=="" && (
                                    <div style={{marginTop:"20px", marginInline:"10px", display:"flex", alignItems:"start"}}>
                                        <img src={ruta + mensajeSelUS.imagen} style={{minWidth:"250px", maxWidth:"400px"}} alt=""/>
                                    </div>
                                    )}
        
                                    {mensajeSelUS.textofin!=="" && (
                                        <div style={{textAlign:"left", fontSize:"14px", color:C_uniformeGrisOscuro, marginTop:"20px", whiteSpace:"pre-wrap"}}>{mensajeSelUS.textofin}</div>
                                    )}
        
                                    {mensajeSelUS.link!=="" && (
                                        <div style={{marginTop:"30px"}}  >
                                        <a href={mensajeSelUS.link} target="_blank" style={{textDecoration:"none"}} >
                                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"left", cursor:"pointer"}}  >
                                                <img src={ic_manual_primary} className="IM_imgIcono24" alt=""/>
                                                <div className='TX_unaSolaLinea2' style={{marginLeft:"20px", color:C_colorPrimary, textAlign:"left", fontWeight:"bold"}}>{(mensajeSelUS.textolink!=="" ? (mensajeSelUS.textolink) : (mensajeSelUS.link))}</div>
                                            </div>
                                        </a>
                                        </div>
                                    )}                  
        
        
                                    <div className="BF_cajaGrisClara" style={{marginTop:"20px", padding:"10px", textAlign:"left", fontSize:"14px", fontWeight:"bold", color:C_uniformeNegro}}>
                                        <div >Enviado Por:</div>
                                        <div style={{display:"flex", flexWrap:"nowrap"}}>
                                            <div><img className="IM_imgRedondeada36" src={ruta + mensajesUS[regMsjSelUS].fotoUser} style={{margin:"5px"}} alt=""/></div>
                                            <div style={{flex:"1", marginLeft:"4px"}}>
                                                <div>{mensajesUS[regMsjSelUS].nombreUser}</div>
                                                <div style={{color:C_colorPrimary, fontWeight:"normal"}}>{mensajeSelUS.correoUser}</div>
                                                <div><span>Empresa:</span><span style={{fontWeight:"normal", marginLeft:"6px"}}>{mensajesUS[regMsjSelUS].empresaUser}</span></div>
                                                <div><span>Sede:</span><span style={{fontWeight:"normal", marginLeft:"6px"}}>{mensajeSelUS.sedeUser}</span></div>
                                            </div>
                                        </div>
        
                                    </div>
        
                                    {mensajeSelUS.fecha_respuesta !=="" && (
                                        <div className="BF_cajaAccent" style={{marginTop:"20px", textAlign:"left", fontSize:"14px", color:C_uniformeNegro}}>
                                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                                                <img src={ic_responder_negro} className="IM_imgIcono24" alt="" style={{padding:"4px"}}/>
                                                <div >Respuesta: {formatearFecha(mensajeSelUS.fecha_respuesta,3)}</div>
                                            </div>
                                            
                                            <div className="BF_cajaBlanca" style={{padding:"10px"}}> {mensajeSelUS.respuesta} </div>
                                        </div>
                                    )}
        
        
        
                                </div> 
                            )}
        
                            {loadingZonal && (
                                <Loader  tipo={"PARCIAL"} />
                            )}                                                                     
        
                    </div>
        
                    {regMsjSelUS>=0 && mensajeSelUS.idmensaje!=="" && (
                        <div style={{fontSize:"14px", fontWeight:"bold", color:C_uniformeBlanco, borderBottomRightRadius:"5px", borderBottomLeftRadius:"5px", backgroundColor:C_colorPrimaryDark, display:"flex", flexWrap:"nowrap", justifyContent:"space-around", paddingBlock:"4px"}}>
                            <div style={{flex:"1", display:"flex", flexWrap:"nowrap", gap:"4px", justifyContent:"center", alignItems:"center", cursor:"pointer"}} onClick={() => {clickGenerico("SOLICITAR_CONFIRMACION_BORRADO_MENSAJE")}}>
                                <img className='IM_imgIcono24' src={ic_borrar_rojo} alt='' />Borrar</div>
                            
                            {(mensajeSelUS.guardado!=="1") ? (
                                <div style={{flex:"1", display:"flex", flexWrap:"nowrap", gap:"4px", justifyContent:"center", alignItems:"center", cursor:"pointer"}} onClick={() => {clickGenerico("SOLICITAR_CONFIRMACION_GUARDADO_MENSAJE")}}>
                                    <img className='IM_imgIcono24' src={ic_guardar_gris} alt='' />Guardar</div>
                            ) : (
                                <div style={{flex:"1"}}></div>
                            )}
        
        
                            {(mensajeSelUS.puede_responder==="1" && mensajeSelUS.fecha_respuesta==="") ? (
                                <div style={{flex:"1", display:"flex", flexWrap:"nowrap", gap:"4px", justifyContent:"center", alignItems:"center", cursor:"pointer"}} onClick={() => {clickGenerico("ABRIR_MODAL_RESPUESTA")}}>
                                    <img className='IM_imgIcono24' src={ic_responder_gris} alt='' />Responder</div>
                            ) : (
                                <div style={{flex:"1"}}></div>
                            )}
                            
                        </div>
                    )}
        
                </div> 
                );
        }
        else{
            return(
                <div className="CT_mensajeModal" style={{display:"flex", flexDirection:"column", flexWrap:"nowrap", maxHeight:"73vh", maxWidth:"100%", marginTop:"4px", marginBottom:"0px"}}>
        
                         
                        
        
                    <div style={{flex:"1", overflowY:"auto",  height:"100%",  maxHeight:"100%", maxWidth:"100%"}}>
                    
                    <div style={{padding:"8px", backgroundColor:C_uniformeGrisSuperClaro}}>{regMsjSelUS===-1 ? 
                        (<div style={{fontSize:"16px", fontWeight:"bold"}}>Seleccione el mensaje a visualizar</div>) : 
                        (
                        <div style={{fontSize:"14px"}}>
                            <div style={{display:"flex", flexWrap:"nowrap", fontSize:"14px"}}>
                                <div >Enviado:</div><div style={{color:C_uniformeAzulClaro, marginLeft:"10px"}}>{formatearFecha(mensajesUS[regMsjSelUS].fecha_envio,4)}</div>                                           
                            </div>
                            <div style={{display:"flex", flexWrap:"nowrap"}}>
                                <div >Vigente hasta:</div>
                                {mensajeSelUS.idmensaje!=="" && (<div style={{color:C_uniformeRosa, marginLeft:"10px"}}>{formatearFecha(mensajeSelUS.fecha_vencimiento,9)}</div>)}                                            
                            </div>
                        </div>
                        )}
                    </div>
                    <div style={{height:"1px", borderBottom:"1px solid " + C_uniformeGrisClaro}}></div>
                        
        
                            {regMsjSelUS>=0 && mensajeSelUS.idmensaje!=="" && (
                                <div style={{padding:"10px", margin:"10px"}}>
        
                                    <div style={{textAlign:"left", fontSize:"16px", color:C_uniformeNegro, fontWeight:"bold"}}>{mensajesUS[regMsjSelUS].titulo}</div>
        
                                    {mensajeSelUS.textoini!=="" && (
                                        <div style={{textAlign:"left", fontSize:"14px", color:C_uniformeGrisOscuro, marginTop:"20px", whiteSpace:"pre-wrap"}}>{mensajeSelUS.textoini}</div>
                                    )}
        
                                    {mensajeSelUS.imagen!=="" && (
                                    <div style={{marginTop:"20px", marginInline:"10px", display:"flex", alignItems:"start"}}>
                                        <img src={ruta + mensajeSelUS.imagen} style={{minWidth:"250px", maxWidth:"400px"}} alt=""/>
                                    </div>
                                    )}
        
                                    {mensajeSelUS.textofin!=="" && (
                                        <div style={{textAlign:"left", fontSize:"14px", color:C_uniformeGrisOscuro, marginTop:"20px", whiteSpace:"pre-wrap"}}>{mensajeSelUS.textofin}</div>
                                    )}
        
                                    {mensajeSelUS.link!=="" && (
                                        <div style={{marginTop:"30px"}}  >
                                        <a href={mensajeSelUS.link} target="_blank" style={{textDecoration:"none"}} >
                                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"left", cursor:"pointer"}}  >
                                                <img src={ic_manual_primary} className="IM_imgIcono24" alt=""/>
                                                <div className='TX_unaSolaLinea2' style={{marginLeft:"20px", color:C_colorPrimary, textAlign:"left", fontWeight:"bold"}}>{(mensajeSelUS.textolink!=="" ? (mensajeSelUS.textolink) : (mensajeSelUS.link))}</div>
                                            </div>
                                        </a>
                                        </div>
                                    )}                  
        
        
                                    <div className="BF_cajaGrisClara" style={{marginTop:"20px", padding:"10px", textAlign:"left", fontSize:"14px", fontWeight:"bold", color:C_uniformeNegro}}>
                                        <div >Enviado Por:</div>
                                        <div style={{display:"flex", flexWrap:"nowrap"}}>
                                            <div><img className="IM_imgRedondeada36" src={ruta + mensajesUS[regMsjSelUS].fotoUser} style={{margin:"5px"}} alt=""/></div>
                                            <div style={{flex:"1", marginLeft:"4px"}}>
                                                <div>{mensajesUS[regMsjSelUS].nombreUser}</div>
                                                <div style={{color:C_colorPrimary, fontWeight:"normal"}}>{mensajeSelUS.correoUser}</div>
                                                <div><span>Empresa:</span><span style={{fontWeight:"normal", marginLeft:"6px"}}>{mensajesUS[regMsjSelUS].empresaUser}</span></div>
                                                <div><span>Sede:</span><span style={{fontWeight:"normal", marginLeft:"6px"}}>{mensajeSelUS.sedeUser}</span></div>
                                            </div>
                                        </div>
        
                                    </div>
        
                                    {mensajeSelUS.fecha_respuesta !=="" && (
                                        <div className="BF_cajaAccent" style={{marginTop:"20px", textAlign:"left", fontSize:"14px", color:C_uniformeNegro}}>
                                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                                                <img src={ic_responder_negro} className="IM_imgIcono24" alt="" style={{padding:"4px"}}/>
                                                <div >Respuesta: {formatearFecha(mensajeSelUS.fecha_respuesta,3)}</div>
                                            </div>
                                            
                                            <div className="BF_cajaBlanca" style={{padding:"10px"}}> {mensajeSelUS.respuesta} </div>
                                        </div>
                                    )}
        
        
        
                                </div> 
                            )}
        
                            {loadingZonal && (
                                <Loader  tipo={"PARCIAL"} />
                            )}                                                                     
        
                    </div>
        
                    {regMsjSelUS>=0 && mensajeSelUS.idmensaje!=="" && (
                        <div style={{fontSize:"14px", fontWeight:"bold", color:C_uniformeBlanco, borderBottomRightRadius:"5px", borderBottomLeftRadius:"5px", backgroundColor:C_colorPrimaryDark, display:"flex", flexWrap:"nowrap", justifyContent:"space-around", paddingBlock:"4px"}}>
                            <div style={{flex:"1", display:"flex", flexWrap:"nowrap", gap:"4px", justifyContent:"center", alignItems:"center", cursor:"pointer"}} onClick={() => {clickGenerico("SOLICITAR_CONFIRMACION_BORRADO_MENSAJE")}}>
                                <img className='IM_imgIcono24' src={ic_borrar_rojo} alt='' />Borrar</div>
                            
                            {(mensajeSelUS.guardado!=="1") ? (
                                <div style={{flex:"1", display:"flex", flexWrap:"nowrap", gap:"4px", justifyContent:"center", alignItems:"center", cursor:"pointer"}} onClick={() => {clickGenerico("SOLICITAR_CONFIRMACION_GUARDADO_MENSAJE")}}>
                                    <img className='IM_imgIcono24' src={ic_guardar_gris} alt='' />Guardar</div>
                            ) : (
                                <div style={{flex:"1"}}></div>
                            )}
        
        
                            {(mensajeSelUS.puede_responder==="1" && mensajeSelUS.fecha_respuesta==="") ? (
                                <div style={{flex:"1", display:"flex", flexWrap:"nowrap", gap:"4px", justifyContent:"center", alignItems:"center", cursor:"pointer"}} onClick={() => {clickGenerico("ABRIR_MODAL_RESPUESTA")}}>
                                    <img className='IM_imgIcono24' src={ic_responder_gris} alt='' />Responder</div>
                            ) : (
                                <div style={{flex:"1"}}></div>
                            )}
                            
                        </div>
                    )}
        
                </div> 
                );
        }
        
    }
 

    return (<>

            

            {isOpenMensajeSel && (
                <div className="CT_cajaleermensaje2">
                    <Modal isOpen={isOpenMensajeSel} closeModal={() => clickGenerico("CERRAR_MODAL_MENSAJE")} isCancelable={true} imgModal={ic_mensaje_negro} titulo="Mensaje Recibido" txtAceptar={""} txtCancelar={""}>
                        {mostrarMensajeSel("MODAL")}  
                    </Modal>
                </div>
            )}

            {isOpenRespuesta && (
                <Modal isOpen={isOpenRespuesta} closeModal={closeRespuesta} imgModal={ic_responder_gris} titulo={"Responder"}  isCancelable={false} clickAceptar={() => clickGenerico("GUARDAR_RESPUESTA_MENSAJE_USUARIO")} >
                <div style={{margin:"10px", minHeight:"100px", maxHeight:"500px", maxWidth:"400px", fontSize:"12px", color:C_uniformeGrisOscuro}}>                    
                    
                    <div style={{marginTop:"20px"}}>{"Ingrese la respuesta o comentario al mensaje recibido, la respuesta podrá ser vista unicamente por los administradores de la sede o empresa que envió el mensaje. (Máximo 125 caracteres)"}</div>

                    <div style={{marginTop:"0px", display:"flex", justifyContent:"center"}}>                        
                        <textarea rows={4}  value={arrCampos[0].valor} className='IP_entradasTA' style={{flexGrow: 1, flexShrink: 1, flexBasis: "250px", minWidth:"150px", maxWidth: "400px", marginBlock: "20px", marginInline:"10px"}} placeholder="" onChange={(e) => validarCampo(e, "MD1_RESPUESTA", 125)}/>
                    </div>
                    {arrCampos[0].error!=="" && clickAceptarModal && (
                        <div style={{marginTop:"-20px", marginBottom:"20px"}}>
                        <span className="TX_error">{arrCampos[0].error}</span>
                    </div>
                    )}
                    

                </div> 
                </Modal>
            )}

            {showMensaje && (ventanaMensaje)}

        <div style={{minHeight:"98vh", display:"flex", flexDirection:"column", flexWrap:"nowrap"}}>

            <div className="CT_zonaFija">
                <div className="CT_encabezado">
                    <div className="CT_elementoEncabezado" onClick={salir} style={{cursor:"pointer"}}><img src={ic_salir} className="IM_imgIcono24" alt="" style={{padding: "8px"}} />  </div>
                    <div className="CT_elementoEncabezado"><img className="IM_imgRedondeada36" height="36px" width="36px" src={ic_mensaje_blanco} alt="" /></div>
                    <div className="CT_elementoEncabezado"><p className="TX_unaSolaLinea">{"Mensajes"}</p></div>
                </div>
            </div>

            <div style={{paddingTop:"70px", paddingInline:"10px"}}>
                    <div className="CT_contenedorTitulo" style={{marginBottom:"0px"}} >
                        <img className='IM_imgIcono28' src={ic_flecha_expandir} alt='' onClick={salir} style={{cursor:"pointer"}}/>   
                        <img className='IM_imgRedondeada36' src={ic_mensaje_negro} alt='' style={{marginInline:"10px"}}/>   
                        <div style={{fontSize:"18px"}}>Mensajes Recibidos</div>             
                    </div>
            </div>

            {!loading && esError===0 && (
                <div className="CT_ventanaMensajes" style={{margin: "10px", flex:"1", display:"flex"}}> 

                    <div style={{maxheight:"100%", minWidth:"100%", display:"flex", flexWrap:"nowrap", justifyContent:"space-around"}}>                   

                        <div className="CT_listaMensajes" style={{ border:"0px solid green"}}>

                            <div style={{display:"flex", flexDirection:"column", flexWrap:"nowrap", maxHeight:"100%", maxWidth:"100%"}}>
                            
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingInline:"6px"}}>
                                    <input type="text" className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, flexBasis: "150px"}} placeholder="Filtrar Mensajes"  onChange={cambiarFiltro} />
                                    <img className='IM_imgIcono28' src={ic_buscar_gris} alt='' />  
                                </div>     

                                <div style={{ display: "flex", justifyContent: "space-around", alignItems:"center"}}>
                                                <div style={{flex:"1", display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center", cursor:"pointer"}}  onClick={() => {setTipoListaUS("sinleer"); tipoLista="sinleer";}}>
                                                    <input type="radio" value="sinleer" name="tipoLista" style={{marginBlock: "0px", marginRight:"20px"}} checked={tipoListaUS==="sinleer" ? true : false} onChange={() => {setTipoListaUS("sinleer"); tipoLista="sinleer";}}/>
                                                    <div>Sin Leer</div>
                                                </div>
                                                <div style={{flex:"1", display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center", cursor:"pointer"}}  onClick={() => {setTipoListaUS("leido"); tipoLista="leido";}}>
                                                    <input type="radio" value="leido" name="tipoLista" style={{marginBlock: "15px", marginRight:"20px"}} checked={tipoListaUS==="leido" ? true : false} onChange={() => {setTipoListaUS("leido"); tipoLista="leido";}}/>
                                                    <div>Leidos</div>
                                                </div>
                                                <div style={{flex:"1", display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center", cursor:"pointer"}}  onClick={() => {setTipoListaUS("todos"); tipoLista="todos";}}>
                                                    <input type="radio" value="todos" name="tipoLista" style={{marginBlock: "0px", marginRight:"20px"}} checked={tipoListaUS==="todos" ? true : false} onChange={() => {setTipoListaUS("todos"); tipoLista="todos";}}/>
                                                    <div>Todos</div>
                                                </div>
                                                <img className='IM_imgIcono28' src={ic_transparente} alt='' />  
                                </div>    

                                <div style={{flex:"1", overflowY:"auto",  maxHeight:"100%", maxWidth:"100%"}}>
                                    
                                    {mensajesUS.length>0 ? (<> 
                                        {(mensajesUS.map((reg, index) => (<DinamicoListaMensajes key={index} registro={index} numRegistro={index+1} tipoLista={"Mensajes_Recibidos"} datos={reg} registroSel={regMsjSelUS} clickGenerico={clickGenerico} />))  )}     
                                    </>) : (
                                        <div className="TX_sinInfo">No existen mensajes que coincidan con los filtros definidos</div>
                                    )}                                    

                                </div>

                                

                            </div>

                        </div> 

                        <div className="CT_cajaleermensaje">

                            {mostrarMensajeSel()}  
                            {/* <div style={{display:"flex", flexDirection:"column", flexWrap:"nowrap", height:"100%", maxWidth:"100%" }}>

                                <div style={{padding:"8px", backgroundColor:C_uniformeGrisSuperClaro}}>{regMsjSelUS===-1 ? 
                                    (<div style={{fontSize:"16px", fontWeight:"bold"}}>Seleccione el mensaje a visualizar</div>) : 
                                    (
                                    <div>
                                        <div style={{display:"flex", flexWrap:"nowrap", fontSize:"14px"}}>
                                            <div >Enviado:</div><div style={{color:C_uniformeAzulClaro, marginLeft:"10px"}}>{formatearFecha(mensajesUS[regMsjSelUS].fecha_envio,4)}</div>                                           
                                        </div>
                                        <div style={{display:"flex", flexWrap:"nowrap"}}>
                                            <div >Vigente hasta:</div>
                                            {mensajeSelUS.idmensaje!=="" && (<div style={{color:C_uniformeRosa, marginLeft:"10px"}}>{formatearFecha(mensajeSelUS.fecha_vencimiento,9)}</div>)}                                            
                                        </div>
                                    </div>
                                    )}
                                </div>
                                <div style={{height:"1px", borderBottom:"1px solid " + C_uniformeGrisClaro}}></div>
                                
                                    

                                <div style={{flex:"1", overflowY:"auto",  height:"100%",  maxHeight:"100%", maxWidth:"100%"}}>

                                    

                                        {regMsjSelUS>=0 && mensajeSelUS.idmensaje!=="" && (
                                            <div style={{padding:"10px", margin:"10px"}}>

                                                <div style={{textAlign:"left", fontSize:"16px", color:C_uniformeNegro, fontWeight:"bold"}}>{mensajesUS[regMsjSelUS].titulo}</div>

                                                {mensajeSelUS.textoini!=="" && (
                                                    <div style={{textAlign:"left", fontSize:"14px", color:C_uniformeGrisOscuro, marginTop:"20px", whiteSpace:"pre-wrap"}}>{mensajeSelUS.textoini}</div>
                                                )}

                                                {mensajeSelUS.imagen!=="" && (
                                                <div style={{marginTop:"20px", marginInline:"10px", display:"flex", alignItems:"start"}}>
                                                    <img src={ruta + mensajeSelUS.imagen} style={{minWidth:"250px", maxWidth:"400px"}} alt=""/>
                                                </div>
                                                )}

                                                {mensajeSelUS.textofin!=="" && (
                                                    <div style={{textAlign:"left", fontSize:"14px", color:C_uniformeGrisOscuro, marginTop:"20px", whiteSpace:"pre-wrap"}}>{mensajeSelUS.textofin}</div>
                                                )}

                                                {mensajeSelUS.link!=="" && (
                                                    <div style={{marginTop:"30px"}}  >
                                                    <a href={mensajeSelUS.link} target="_blank" style={{textDecoration:"none"}} >
                                                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"left", cursor:"pointer"}}  >
                                                            <img src={ic_manual_primary} className="IM_imgIcono24" alt=""/>
                                                            <div className='TX_unaSolaLinea2' style={{marginLeft:"20px", color:C_colorPrimary, textAlign:"left", fontWeight:"bold"}}>{(mensajeSelUS.textolink!=="" ? (mensajeSelUS.textolink) : (mensajeSelUS.link))}</div>
                                                        </div>
                                                    </a>
                                                    </div>
                                                )}                  


                                                <div className="BF_cajaGrisClara" style={{marginTop:"20px", padding:"10px", textAlign:"left", fontSize:"14px", fontWeight:"bold", color:C_uniformeNegro}}>
                                                    <div >Enviado Por:</div>
                                                    <div style={{display:"flex", flexWrap:"nowrap"}}>
                                                        <div><img className="IM_imgRedondeada36" src={ruta + mensajesUS[regMsjSelUS].fotoUser} style={{margin:"5px"}} alt=""/></div>
                                                        <div style={{flex:"1", marginLeft:"4px"}}>
                                                            <div>{mensajesUS[regMsjSelUS].nombreUser}</div>
                                                            <div style={{color:C_colorPrimary, fontWeight:"normal"}}>{mensajeSelUS.correoUser}</div>
                                                            <div><span>Empresa:</span><span style={{fontWeight:"normal", marginLeft:"6px"}}>{mensajesUS[regMsjSelUS].empresaUser}</span></div>
                                                            <div><span>Sede:</span><span style={{fontWeight:"normal", marginLeft:"6px"}}>{mensajeSelUS.sedeUser}</span></div>
                                                        </div>
                                                    </div>

                                                </div>

                                                {mensajeSelUS.fecha_respuesta !=="" && (
                                                    <div className="BF_cajaAccent" style={{marginTop:"20px", textAlign:"left", fontSize:"14px", color:C_uniformeNegro}}>
                                                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                                                            <img src={ic_responder_negro} className="IM_imgIcono24" alt="" style={{padding:"4px"}}/>
                                                            <div >Respuesta: {formatearFecha(mensajeSelUS.fecha_respuesta,3)}</div>
                                                        </div>
                                                        
                                                        <div className="BF_cajaBlanca" style={{padding:"10px"}}> {mensajeSelUS.respuesta} </div>
                                                    </div>
                                                )}



                                            </div> 
                                        )}

                                        {loadingZonal && (
                                            <Loader  tipo={"PARCIAL"} />
                                        )}                                                                     

                                </div>

                                {regMsjSelUS>=0 && mensajeSelUS.idmensaje!=="" && (
                                    <div style={{fontSize:"14px", fontWeight:"bold", color:C_uniformeBlanco, borderBottomRightRadius:"5px", borderBottomLeftRadius:"5px", backgroundColor:C_colorPrimaryDark, display:"flex", flexWrap:"nowrap", justifyContent:"space-around", paddingBlock:"4px"}}>
                                        <div style={{flex:"1", display:"flex", flexWrap:"nowrap", gap:"4px", justifyContent:"center", alignItems:"center", cursor:"pointer"}} onClick={() => {clickGenerico("SOLICITAR_CONFIRMACION_BORRADO_MENSAJE")}}>
                                            <img className='IM_imgIcono24' src={ic_borrar_rojo} alt='' />Borrar</div>
                                        
                                        {(mensajeSelUS.guardado!=="1") ? (
                                            <div style={{flex:"1", display:"flex", flexWrap:"nowrap", gap:"4px", justifyContent:"center", alignItems:"center", cursor:"pointer"}} onClick={() => {clickGenerico("SOLICITAR_CONFIRMACION_GUARDADO_MENSAJE")}}>
                                                <img className='IM_imgIcono24' src={ic_guardar_gris} alt='' />Guardar</div>
                                        ) : (
                                            <div style={{flex:"1"}}></div>
                                        )}


                                        {(mensajeSelUS.puede_responder==="1" && mensajeSelUS.fecha_respuesta==="") ? (
                                            <div style={{flex:"1", display:"flex", flexWrap:"nowrap", gap:"4px", justifyContent:"center", alignItems:"center", cursor:"pointer"}} onClick={() => {clickGenerico("ABRIR_MODAL_RESPUESTA")}}>
                                                <img className='IM_imgIcono24' src={ic_responder_gris} alt='' />Responder</div>
                                        ) : (
                                            <div style={{flex:"1"}}></div>
                                        )}
                                        
                                    </div>
                                )}

                            </div> */}                              

                        </div> 

                    </div>                    

                        

                </div> 
            )}
             

        
        </div>


        {esError!==0 && <ShowError tipoError={esError} funcionCerrar={salir} />}

        {loading && <Loader tipo={"TOTAL"}/>}   


    </>);
};


export default Info_Mensajes;