import ic_torneo_gris from '../recGraficos/ic_torneo_gris_w.png';
import ic_entrar from '../recGraficos/ic_entrar_gris.png';
import ic_activo from '../recGraficos/ic_activo.png';
import ic_inactivo from '../recGraficos/ic_inactivo.png';
import ic_uniforme_oscuro from '../recGraficos/ic_uniforme_oscuro.png';
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_escudo_base_gris from '../recGraficos/ic_escudo_base_gris.png';
import ic_silbato from '../recGraficos/ic_silbato.png';
import ic_jugador from '../recGraficos/ic_jugador.png';
import ic_gestion_recursos from '../recGraficos/ic_gestion_recursos.png';
import ic_bajar_gris from '../recGraficos/ic_bajar_gris.png';
import ic_cancelar_gris from '../recGraficos/ic_cancelar_gris.png';
import ic_informacion_accent from '../recGraficos/ic_informacion_accent.png';
import ic_programar from '../recGraficos/ic_programar.png';
import ic_seleccionar_imagen from '../recGraficos/ic_seleccionar_imagen.png';


import { esformatoNumero, formatearFecha, quitarCharEspeciales, recuperarDatoLocal, reportarError, valorLong, valorNumero } from '../helpers/funciones';
import { C_colorPrimary, C_fondoAccentSuave, C_uniformeAzulClaro, C_uniformeGrisOscuro, C_uniformeNegro, C_uniformeRosa } from '../constantes/generales';
import { useEffect, useState } from 'react';


const ruta=recuperarDatoLocal("rutaRecursos");   

let matrizCampos={valor: "", ultValor:"", error: ""};


const DinamicoElementosTorneo = ({ registro, numRegistro, tipoLista, datos, modOrigen=0, paramOrigen="", modDestino=0, paramDestino="", definirModulo, incluirLinea=true, fechaModificacionesActiva=false }) => {
    const nomClase="DinamicoElementosTorneo_WB";
    const nomFuncion="DinamicoElementosTorneo";

    const [listaOrigenUS, setListaOrigenUS] = useState("");

    const [hayCamposJug, setHayCamposJug] = useState(false);
    const [hayCamposSinRegistrar, setHayCamposSinRegistrar] = useState(false);
    const [showCampos, setShowCampos] = useState(false);
    const [infoCampoSel, setInfoCampoSel] = useState(-1);

    const [arrCampos, setArrCampos] = useState([]);




    useEffect(() => {
            const nomFuncion="useEffect";
    
            try{ 
    
                if(datos!==null){    
    
                    if(tipoLista==="jugadores_Info_Equipos" || tipoLista==="tecnicos_Info_Equipos" || tipoLista==="EQUIPOS_INFO_JUGADOR") {

                        if(tipoLista==="jugadores_Info_Equipos") setListaOrigenUS("J");
                        if(tipoLista==="tecnicos_Info_Equipos") setListaOrigenUS("T");
                        else setListaOrigenUS("J");


                        if(datos.camposAdicionales.length>0){
                            let tempArrCampos=[];

                            setHayCamposJug(true);
                            if(datos.hayCamposSinInfo) setHayCamposSinRegistrar(true);
                            else setHayCamposSinRegistrar(false);

                            for(let i=0;i<datos.camposAdicionales.length;i++){
                                let tempRegistro=Object.assign({} , matrizCampos);
                                tempRegistro.valor=datos.camposAdicionales[i].valor;
                                tempRegistro.ultValor=datos.camposAdicionales[i].ultValor;
                                tempRegistro.error=datos.camposAdicionales[i].error;

                                tempArrCampos.push(tempRegistro);
                            }

                            setArrCampos(tempArrCampos);

                        } 
                        
                    }                            
    
                    
        
                }
                
            }catch(err){                            
                reportarError( nomClase, nomFuncion, "", err);                        
            }     
                            
                    
        }, [registro, tipoLista, datos]);



    try{
        if(!datos) return null;

        
        
        let imagen_inicial=ic_torneo_gris;
        let imagen_camiseta="";
        let imagen_final=ic_activo;
        let imagen_entrar=ic_entrar;
        let txtPrincipal="";
        let txtSecundario="";
        let txtCamiseta="";
        let showTxtSecundario=true;
        let textoDestino="";
        let cursor="pointer";
        let colorTxtPrincipal=C_uniformeNegro;

        let estilo= {display:"flex", alignItems:"center", padding:"2px", cursor:cursor, backgroundColor:"transparent"};
        let estiloCampos= {padding:"2px", backgroundColor:"transparent"};

        

            if(tipoLista==="torneo-destacado"){        
                if(datos[1]!=="") imagen_inicial= ruta + datos[1];
                txtPrincipal=datos[2];
                txtSecundario="Empresa: " + datos[4] + "\nSede: " + datos[5] + "";
                imagen_final="";
            }
            else if(tipoLista==="params_Info_Equipos"){     
                imagen_inicial="";   
                txtPrincipal=datos[0]
                txtSecundario=datos[1];
                imagen_camiseta="";
                imagen_final="";
                imagen_entrar="";
                estilo.cursor="";
                if(datos[2]==="0") imagen_final=ic_inactivo;
                else if(datos[2]==="1") imagen_final=ic_activo;
                if(datos[3]==="1") {
                    imagen_entrar=ic_entrar;
                    estilo.cursor="pointer";
                }
                if(datos[4]!=="") textoDestino=datos[4];
            }
            else if(tipoLista==="jugadores_Info_Equipos"  || tipoLista==="tecnicos_Info_Equipos"){     
                imagen_inicial=ic_jugadorsinimagen; 

                if(datos.foto!=="") imagen_inicial= ruta + datos.foto;
                txtPrincipal=datos.nombre;

                txtSecundario="";
                let tempTelefono=datos.telefono;
                if(tempTelefono.length>14) tempTelefono="Sin Número";
                txtSecundario="Tel. " + tempTelefono + "\nPosición: " + datos.nomPosicion;
                
                imagen_camiseta=ic_uniforme_oscuro;
                txtCamiseta=datos.numero;

                imagen_final=ic_inactivo;
                if(datos.activo==="1") imagen_final=ic_activo;

                imagen_entrar=ic_entrar;

                estilo.cursor="pointer";
                if(datos.esadmin==="1") {
                    estilo.backgroundColor=C_fondoAccentSuave;
                    estiloCampos.backgroundColor=C_fondoAccentSuave;
                }
                
                textoDestino="CLICK_JUGADOR";
            }
            else if(tipoLista==="busqueda_jugadores"){
                imagen_inicial=ic_jugadorsinimagen; 

                if(datos.foto!=="") imagen_inicial= ruta + datos.foto;
                txtPrincipal=datos.nombre;

                txtSecundario="";
                let tempTelefono=datos.telefono;
                if(tempTelefono.length>14 && valorLong(tempTelefono)>0) tempTelefono="Sin Número";
                txtSecundario="Tel. " + tempTelefono;

                imagen_final="";
                imagen_entrar=ic_entrar;

                estilo.cursor="pointer";
                
                textoDestino="CLICK_INCLUIR_JUGADOR_BUSQUEDA";
            }
            else if(tipoLista==="Equipos_de_Jugador"){

                imagen_inicial=ic_jugadorsinimagen; 
                if(datos.escudo!=="") imagen_inicial= ruta + datos.escudo;

                txtPrincipal=datos.nombreTorneo + "\n" + datos.nombreEquipo;
                txtSecundario="POSICIÓN: " + datos.nomPosicion;
                imagen_camiseta=ic_uniforme_oscuro;
                txtCamiseta=datos.camiseta;

                imagen_final=ic_inactivo;
                if(datos.activo==="1") imagen_final=ic_activo;

                imagen_entrar="";

                estilo.cursor="";
                if(datos.esadmin==="1") estilo.backgroundColor=C_fondoAccentSuave;
                
                textoDestino="";
            }
            else if(tipoLista==="EQUIPOS_INFO_JUGADOR"){

                imagen_inicial=ic_escudo_base_gris; 
                if(datos.escudo!=="") imagen_inicial= ruta + datos.escudo;

                txtPrincipal=datos.nomTorneo + "\n" + datos.nomEquipo + "\nPosición: " + datos.posicion;
                txtSecundario=datos.nomEmpresa + "\nSede: " + datos.nomSede;
                imagen_camiseta=ic_uniforme_oscuro;
                txtCamiseta=datos.camiseta;

                imagen_final=ic_inactivo;
                if(datos.activo==="1") imagen_final=ic_activo;

                imagen_entrar=ic_entrar;

                estilo.cursor="pointer";
                if(datos.esadmin==="1") {
                    estilo.backgroundColor=C_fondoAccentSuave;
                    estiloCampos.backgroundColor=C_fondoAccentSuave;
                }
                
                textoDestino="EDITAR_DATOS_DE_JUEGO";
            }
            else if(tipoLista==="ARBITRO_INFO_JUGADOR"){

                imagen_inicial=ic_silbato; 

                txtPrincipal=datos.nomEmpresa + "\nSede: " + datos.nomSede;
                txtSecundario=datos.horario;

                imagen_final="";

                imagen_entrar=ic_entrar;

                estilo.cursor="pointer";
                
                textoDestino="POR_DEFINIR";
            }
            else if(tipoLista==="ROLES_GESTION_MICUENTA"){

                
                imagen_inicial=ic_jugador; 
                txtPrincipal="Jugador";
                if(datos.idrol==="1" || datos.idrol==="2") {
                    imagen_inicial=ic_gestion_recursos; 
                    txtPrincipal="Administrador";
                }
                else if(datos.idrol==="3") {
                    imagen_inicial=ic_silbato;  
                    txtPrincipal="Arbitro";
                }
                else if(datos.idrol==="4") {
                    imagen_inicial=ic_jugador; 
                    txtPrincipal="Jugador";
                } 

                txtSecundario=datos.nomEmpresa + "\nSede: " + datos.nomSede;

                imagen_final="";

                imagen_entrar="";

                estilo.cursor="";
                
                textoDestino="";
                colorTxtPrincipal=C_colorPrimary;
            }

            

        const manejarClick = () => {
            if(tipoLista==="torneo-destacado") definirModulo(registro, modOrigen, paramOrigen, modDestino, paramDestino); 
            else if(tipoLista==="params_Info_Equipos"){
                if(textoDestino!=="") definirModulo(textoDestino, datos);
            } 
            else if(tipoLista==="jugadores_Info_Equipos" || tipoLista==="tecnicos_Info_Equipos"){
                if(textoDestino!=="") definirModulo(textoDestino, datos.indice);
            } 
            else if(tipoLista==="busqueda_jugadores"){
                if(textoDestino!=="") definirModulo(textoDestino, datos);
            }
            else if(tipoLista==="EQUIPOS_INFO_JUGADOR"){
                if(textoDestino!=="") definirModulo(textoDestino, registro);
            }
        }

        const clickInfoAdicional = (tipo) => {
            if(tipo==="SHOW_CAMPOS") {
                setShowCampos(true);                
            }
            else setShowCampos(false);     
            
        }

        const validarCampo = (e, tipoCampo, indice) => {
                let nomFuncion="validarCampo"; 
        
                try{
                    let newValor = e.target.value;
        
                    let tempArreglo=JSON.parse(JSON.stringify(arrCampos));

                    let minimo=valorNumero(datos.camposAdicionales[indice].min);
                    let maximo=valorNumero(datos.camposAdicionales[indice].max);
        
                    if(tipoCampo==="1"){  
                        tempArreglo[indice].error="Longitud mínima: " + minimo + ", máxima: " + maximo;
                        let texto=quitarCharEspeciales(newValor); 
                        let hayError=false;
                        
                        tempArreglo[indice].valor=texto; 
                        if(texto.length<minimo){
                            hayError=true;
                        }
                        else if(texto.length>maximo){
                            texto=texto.substring(0,texto.length-1);
                            tempArreglo[indice].valor=texto;
                            hayError=true;
                        }
                        
                        if(!hayError) tempArreglo[indice].error="";   
                        
                        setArrCampos(tempArreglo);  
                    }  
                    else if(tipoCampo==="2"){  
                        tempArreglo[indice].error="Valor mínimo: " + minimo + ", máximo: " + maximo;
                        let texto=0;
                        if(newValor==="" || esformatoNumero(newValor)) {
                            texto=valorNumero(newValor); 
                        }
                        
                        let hayError=false;
                        
                        tempArreglo[indice].valor="" + texto; 
                        if(texto<minimo){
                            hayError=true;
                        }
                        else if(texto>maximo){
                            let textFin="" + texto;
                            textFin=textFin.substring(0,textFin.length-1);
                            tempArreglo[indice].valor=textFin;
                            hayError=true;
                        }
                        
                        if(!hayError) tempArreglo[indice].error="";   
                        
                        setArrCampos(tempArreglo);  
                    }  
                    else if(tipoCampo==="3"){  
                        tempArreglo[indice].error="Longitud mínima: " + minimo + ", máxima: " + maximo;
                        let texto="";
                        if(newValor==="" || esformatoNumero(newValor)) {
                            texto=newValor; 
                        }
                        
                        let hayError=false;
                        
                        tempArreglo[indice].valor="" + texto; 
                        if(texto.length<minimo){
                            hayError=true;
                        }
                        else if(texto.length>maximo){
                            let textFin="" + texto;
                            textFin=textFin.substring(0,textFin.length-1);
                            tempArreglo[indice].valor=textFin;
                            hayError=true;
                        }
                        
                        if(!hayError) tempArreglo[indice].error="";   
                        
                        setArrCampos(tempArreglo);  
                    }         
                    
        
                }catch(err){
                    reportarError( nomClase, nomFuncion, "", err);
                }         
                
            }

        const clickGenerico = (donde, params=null) => {
            let nomFuncion="clickGenerico"; 
        
            try{
                    
                let tempArreglo=null;
                
                switch(donde) {
                    case "ABRIR_CALENDARIO":
                        //params: Coreresponde al indice del campo adicional seleccionado
                        definirModulo("ABRIR_CALENDARIO_CAMPO_ADICIONAL", [listaOrigenUS,registro,params]);
                        
                        break; 
                    case "CLICK_SELECCIONAR_FOTO":

                            definirModulo("SELECCIONAR_IMAGEN_CAMPO_ADICIONAL", [listaOrigenUS,registro,params]);
                            
                            break;
                    case "SALE_CAMPO_ADICIONAL":
                        let tipoCampo=params[0];
                        let indice=params[1];

                        tempArreglo=JSON.parse(JSON.stringify(arrCampos));

                        let minimo=valorNumero(datos.camposAdicionales[indice].min);
                        let maximo=valorNumero(datos.camposAdicionales[indice].max);

                        if(tipoCampo==="1"){
                            tempArreglo[indice].error="Longitud mínima: " + minimo + ", máxima: " + maximo;
                            let nuevoDato=quitarCharEspeciales(arrCampos[indice].valor);
                            let hayError=false;
                            
                            tempArreglo[indice].valor=nuevoDato; 
                            if(nuevoDato.length<minimo){                                
                                hayError=true;
                            }
                            else if(nuevoDato.length>maximo){
                                nuevoDato=nuevoDato.substring(0,maximo);
                                tempArreglo[indice].error="";
                                tempArreglo[indice].valor=nuevoDato;
                                hayError=false;
                            }
                            
                            if(!hayError){
                                tempArreglo[indice].error="";
                                if(nuevoDato!==arrCampos[indice].ultValor){
                                    if(datos.camposAdicionales[indice].regCampos>=0) {
                                        definirModulo("ACTUALIZAR_CAMPOS_ADICIONALES", [listaOrigenUS,registro,indice, nuevoDato]);
                                    }
                                    else {
                                        definirModulo("INSERTAR_CAMPOS_ADICIONALES", [listaOrigenUS,registro,indice, nuevoDato]);
                                    }
                                } 
                            }  
                            
                            setArrCampos(tempArreglo);  
                        }
                        else if(tipoCampo==="2"){
                            tempArreglo[indice].error="Valor mínimo: " + minimo + ", máximo: " + maximo;
                            let nuevoDato=0;
                            let texto=arrCampos[indice].valor;                            
                            if(texto==="" || esformatoNumero(texto)) {
                                nuevoDato=valorNumero(texto); 
                            }
                            let hayError=false;
                            
                            texto="" + nuevoDato; 
                            tempArreglo[indice].valor=texto; 
                            if(nuevoDato<minimo){                                
                                hayError=true;
                            }
                            else if(nuevoDato>maximo){
                                let textFin=texto;
                                textFin=textFin.substring(0,textFin.length-1);
                                tempArreglo[indice].valor=textFin;
                                tempArreglo[indice].error="";
                                texto=textFin;
                                hayError=false;
                            }
                            
                            if(!hayError){
                                tempArreglo[indice].error="";
                                if(texto!==arrCampos[indice].ultValor){
                                    if(datos.camposAdicionales[indice].regCampos>=0) {
                                        definirModulo("ACTUALIZAR_CAMPOS_ADICIONALES", [listaOrigenUS,registro,indice, texto]);
                                    }
                                    else {
                                        definirModulo("INSERTAR_CAMPOS_ADICIONALES", [listaOrigenUS,registro,indice, texto]);
                                    }
                                } 
                            }  
                            
                            setArrCampos(tempArreglo);  
                        }
                        else if(tipoCampo==="3"){
                            tempArreglo[indice].error="Longitud mínima: " + minimo + ", máxima: " + maximo;
                            let nuevoDato="";
                            let texto=arrCampos[indice].valor;                            
                            if(texto==="" || esformatoNumero(texto)) {
                                nuevoDato=texto; 
                            }
                            let hayError=false;
                            
                            texto="" + nuevoDato; 
                            tempArreglo[indice].valor=texto; 
                            if(texto.length<minimo){                                
                                hayError=true;
                            }
                            else if(texto.length>maximo){
                                let textFin=texto;
                                textFin=textFin.substring(0,maximo);
                                tempArreglo[indice].valor=textFin;
                                tempArreglo[indice].error="";
                                texto=textFin;
                                hayError=false;
                            }
                            
                            if(!hayError){
                                tempArreglo[indice].error="";
                                if(texto!==arrCampos[indice].ultValor){
                                    if(datos.camposAdicionales[indice].regCampos>=0) {
                                        definirModulo("ACTUALIZAR_CAMPOS_ADICIONALES", [listaOrigenUS,registro,indice, texto]);
                                    }
                                    else {
                                        definirModulo("INSERTAR_CAMPOS_ADICIONALES", [listaOrigenUS,registro,indice, texto]);
                                    }
                                } 
                            }  
                            
                            setArrCampos(tempArreglo);  
                        }

                        break;
                    case "CLICK_MODIFCAR_CAMPO_ADICIONAL":
                        if(!fechaModificacionesActiva){
                            definirModulo("CLICK_MODIFCAR_CAMPO_ADICIONAL");                            
                        }

                        break;
                    case "MOSTRAR_FOTO":
                        if(!fechaModificacionesActiva){
                            let imagen=arrCampos[params].valor;
                            if(imagen==="") imagen=ic_seleccionar_imagen;
                            definirModulo("MOSTRAR_FOTO",imagen);                            
                        }
    
                            break;
                    default:
                        break;
                }
    
            }catch(err){
                reportarError( nomClase, nomFuncion, "", err);
            }  
        } 


        return(
            <>

           
            <div style={estilo} onClick={manejarClick}>
                <span style={{width:"25px", fontSize:"14px", color:C_uniformeNegro, fontWeight:"bold", textAlign:"center"}}>{numRegistro}</span>
                {imagen_inicial!=="" && (<img className="IM_imgRedondeada36" src={imagen_inicial} style={{margin:"5px"}} alt=""/>)}  
                <div style={{flex:"1", textAlign:"left", whiteSpace: "pre-wrap"}}>
                    <div style={{fontSize:"14px", color: colorTxtPrincipal}}>{txtPrincipal}</div>
                    {showTxtSecundario && (<div style={{fontSize:"12px", color:C_uniformeGrisOscuro}}>{txtSecundario}</div>)}                
                </div>
                {imagen_camiseta!=="" && (
                    <div style={{position:"relative", justifyContent:"center"}}>
                        <img className="IM_camiseta" src={imagen_camiseta} alt="" style={{padding:"0px", zIndex:"1"}}/>
                        <div style={{position:"absolute", top:"5px", left:"0", zIndex:"2", width:"95%", display:"flex", justifyContent:"center"}}>
                            <span style={{zIndex:"2", color:"white", fontSize:"12px"}}>{txtCamiseta}</span>
                        </div>
                    </div>                
                )}   
                {imagen_final!=="" && (<img className="IM_imgIcono24" src={imagen_final} alt=""/>)}   
                {imagen_entrar!=="" && (<img className="IM_imgIcono24" src={imagen_entrar} alt=""/>)}  
            </div>

            {hayCamposJug && (
                <div style={estiloCampos}>
                    {!showCampos && (
                        <div style={{cursor:"pointer", display:"flex", flexWrap:"nowrap", alignItems:"center", fontSize:"12px", textAlign:"left", paddingLeft:"25px", paddingRight:"10px"  }} onClick={() => clickInfoAdicional("SHOW_CAMPOS") }>
                            <img className="IM_imgIcono20" src={ic_bajar_gris}  alt=""/>
                            <div style={{flex:"1", color:C_uniformeAzulClaro, marginInline:"6px"}}>{"Ver Información Adicional."}</div>
                            {hayCamposSinRegistrar && (<div style={{color:C_uniformeRosa}}>{"Datos SIN REGISTRAR"}</div>)}
                        </div>
                    )}                    
                    {showCampos && (
                        <div style={{margin:"2px"}}>
                            <div className='BF_cajaBlanca' >
                                <div style={{display:"flex", justifyContent:"right"}}><img className="IM_imgIcono20" style={{padding:"4px", cursor:"pointer"}} src={ic_cancelar_gris} alt="" onClick={() => clickInfoAdicional("HIDE_CAMPOS") }/></div>
                                {datos.camposAdicionales.length>0 && (<> 
                                        {(datos.camposAdicionales.map((reg, index) => (
                                            <div key={index}>
                                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", fontSize:"14px", textAlign:"left", margin:"2px"}}>
                                                    
                                                    {infoCampoSel===index && reg.descripcion!=="" && (
                                                        <div style={{position:"relative"}}>
                                                            <div className='BF_cajaAccent' style={{position:"absolute", zIndex:"9", whiteSpace:"pre", marginTop:"-10px", marginLeft:"30px", cursor:"pointer"}} onClick={() => setInfoCampoSel(-1)}>
                                                                {reg.descripcion}
                                                                </div>
                                                        </div>
                                                    )}
                                                    
                                                      
                                                    {true && (<>
                                                        <img className="IM_imgIcono20" src={ic_informacion_accent} alt="" style={{padding:"4px", cursor:"pointer"}} onClick={() => {infoCampoSel===index ? setInfoCampoSel(-1) : setInfoCampoSel(index)}}/>
                                                        <div style={{flex:"2", flexGrow:"2", flexShrink:"2", color:C_uniformeNegro, minWidth:"100px"}}>{reg.nombre}</div>
                                                        <div style={{flex:"3", fontSize:"14px", flexGrow:"3", flexShrink:"3"}}>
                                                            {(reg.tipo_campo==="1") && (
                                                                <div>  
                                                                    {fechaModificacionesActiva ? (<>
                                                                        <input type="text" value={arrCampos[index].valor} className={"IP_entradas"} style={{marginInline:"4px", maxWidth:"150px", fontSize:"14px", height:"22px"}} placeholder="Texto"  onChange={(e) => validarCampo(e, reg.tipo_campo, index)} onBlur={(e) => clickGenerico("SALE_CAMPO_ADICIONAL",[reg.tipo_campo, index])} />  
                                                                        {arrCampos[index].error!=="" && (<div className='TX_error' style={{padding:"0px", margin:"0px", fontSize:"12px", textAlign:"left"}}>{arrCampos[index].error}</div>)}
                                                                    </>) : (<>
                                                                        <div className='IP_entradas' style={{marginInline:"4px", maxWidth:"150px", fontSize:"14px", height:"22px", paddingLeft:"4px", display:"flex", alignItems:"center"}}  onClick={() => clickGenerico("CLICK_MODIFCAR_CAMPO_ADICIONAL")}><span>{arrCampos[index].valor}</span></div>  
                                                                    </>)}                                                              
                                                                    
                                                                </div>
                                                            )}
                                                            {(reg.tipo_campo==="2" || reg.tipo_campo==="3") && (
                                                                <div>
                                                                    {fechaModificacionesActiva ? (<>
                                                                        <input type="number" value={arrCampos[index].valor} className={"IP_entradas"} style={{marginInline:"4px", maxWidth:"100px", fontSize:"14px", height:"22px"}} placeholder="Número" onChange={(e) => validarCampo(e, reg.tipo_campo, index)} onBlur={(e) => clickGenerico("SALE_CAMPO_ADICIONAL",[reg.tipo_campo, index])} />  
                                                                        {arrCampos[index].error!=="" && (<div className='TX_error' style={{padding:"0px", margin:"0px", fontSize:"12px", textAlign:"left"}}>{arrCampos[index].error}</div>)}
                                                                    </>) : (<>
                                                                        <div className='IP_entradas' style={{marginInline:"4px", maxWidth:"150px", fontSize:"14px", height:"22px", paddingLeft:"4px", display:"flex", alignItems:"center"}}  onClick={() => clickGenerico("CLICK_MODIFCAR_CAMPO_ADICIONAL")}><span>{arrCampos[index].valor}</span></div>  
                                                                    </>)} 
                                                                    
                                                                </div>
                                                            )}
                                                            {(reg.tipo_campo==="4") && (
                                                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}  >
                                                                    <input disabled={true} type="text" value={arrCampos[index].valor!=="" ? (formatearFecha(arrCampos[index].valor,6)) : ("")} className={"IP_entradas"} style={{marginInline:"4px", maxWidth:"120px", fontSize:"14px", height:"22px"}} placeholder="Seleccione..."    />  
                                                                    <img className="IM_imgIcono24" src={ic_programar} alt="" style={{padding:"2px", cursor:"pointer"}} onClick={() => {fechaModificacionesActiva ? clickGenerico("ABRIR_CALENDARIO", index) : clickGenerico("CLICK_MODIFCAR_CAMPO_ADICIONAL")}} />
                                                                </div>
                                                            )}
                                                            {(reg.tipo_campo==="5") && (
                                                                <div>
                                                                    <img className="IM_imgIcono46" src={arrCampos[index].valor!=="" ? (arrCampos[index].valor) : (ic_seleccionar_imagen)} alt="" style={{padding:"0px", marginInline:"4px", marginBlock:"0px", cursor:"pointer"}} onClick={() => {fechaModificacionesActiva ? clickGenerico("CLICK_SELECCIONAR_FOTO", index) : clickGenerico("MOSTRAR_FOTO", index)}}/>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>)}

                                                    
                                                </div>
                                            </div>
                                        ))  )}     
                                </>)} 
                            </div>
                        </div>
                    )}
                </div>
            )}

            
            

            {incluirLinea && (<div className='BF_lineaInferior'></div>)}
            
            </>
        );

    }catch(err){
        reportarError(nomClase, nomFuncion,"",err);
    } 

};


export default DinamicoElementosTorneo;