import ic_entrar_gris from '../recGraficos/ic_entrar_gris.png'
import ic_copia_primary from '../recGraficos/ic_copia_primary.png'
import { C_uniformeAzulClaro } from '../constantes/generales';


const TemplateLista2Items = ({ llevaImagen, imagen, dato1, dato2, llevaEntrar, modDestino, paramDestino, modOrigen, paramOrigen, definirModulo, tipoRegistro="BASE", copiar=false }) => {

    
    if(!dato1) return null;

    let numCols=3;
    let cursor={
      cursor: "pointer"
    }

    if(llevaImagen) numCols++;

    let imgEntrar=ic_entrar_gris;
    if(!llevaEntrar){
      imgEntrar="";
      cursor={};
    } 


    let stDato1={
        textTransform: "capitalize",
        fontWeight: "bold",
        verticalAlign: "text-top",
        align: "left",
        width: "35%",
    }

    let stDato2={
        verticalAlign: "text-top",
        align: "left",
        width: "65%",
        color: "gray",
        whiteSpace: "pre-wrap"

    }


    const clickRegistro = () => {
      if(copiar){
        navigator.clipboard.writeText(dato2);
        if(definirModulo!==null){
          definirModulo("CLICK_COPY_CLIPBOARD");
        }
      }
      else{
        if(llevaEntrar){
          definirModulo(0,modOrigen,paramOrigen,modDestino,paramDestino);  
        }  
      }
                
    }  

    if(tipoRegistro==="BASE"){
      return (
        <>
          <tr style={cursor} onClick={clickRegistro}>
              {llevaImagen && (<td><img className="IM_imgRedondeada36" style={{marginRight:"6px"}} src={imagen} alt=""/></td>)}
              <td style={stDato1}>{dato1}</td>
              <td style={stDato2}>{dato2}</td>
              {imgEntrar!=="" && (<td><img className="IM_imgIcono24" src={imgEntrar} alt=""/></td>)}
          </tr>
          <tr><td colSpan={numCols} align="center"><div className="BF_lineaInferior"></div></td></tr>
        </>
      );
    }
    else{
      stDato1.width="100%";      
      stDato2.width="100%";
      if(dato2!==""){        
        imgEntrar=ic_copia_primary;
      }
      else {
        stDato1.textTransform=""
        stDato1.color=C_uniformeAzulClaro;
      }
      
      return (
        <>
          <tr style={cursor} onClick={clickRegistro}>
              {llevaImagen && (<td><img className="IM_imgRedondeada36" style={{marginRight:"6px"}} src={imagen} alt=""/></td>)}
              <td colSpan={2}>
                  <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                    <div style={{flex:"1"}}>
                      <div style={stDato1}>{dato1}</div>
                      <div style={stDato2}>{dato2}</div>
                    </div>
                    <div><img className="IM_imgIcono24" src={imgEntrar} alt="" style={{marginRight:"14px"}}/></div>
                  </div>                  
              </td>
          </tr>
          <tr><td colSpan={numCols} align="center"><div className="BF_lineaInferior"></div></td></tr>
        </>
      );
    }
    
  };
  
  export default TemplateLista2Items;