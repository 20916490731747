import { useEffect, useState } from "react";
import ShowError from "../recVisual/ShowError";
import Loader from "../recVisual/Loader";
import { crearTabla, dividirLista, esformatoNumero, formatearFecha, formatearTexto, getConfirmacion, guardarDatoLocal, isFlagUpdate,  operacionFechas, presentarError, presentarLog, quitarCharEspeciales, reconstruirDatosOrigen, recuperarDatoLocal, registrarLogEventos, reportarError, setFlagUpdate, showMensajePantalla, superaTiempoActualizacion, valorNumero } from "../helpers/funciones";
import { getDatos } from "../gestionDatos/getDatos";
import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeBlanco,  C_colorPrimaryDark, SEP_3, SEP_4, SEP_D_1, SEPARADOR_PARAMETROS_BD, C_colorTextoSinInfo, C_colorTextoError, ID_MODULO_INFO_JUGADOR, ID_MODULO_PRINCIPAL_JUGADOR, ID_MODULO_GESTION_MICUENTA } from "../constantes/generales";


import ic_escudo_base_gris from '../recGraficos/ic_escudo_base_gris.png';
import ic_salir from '../recGraficos/ic_salir_w.png'
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_flecha_expandir from '../recGraficos/ic_flecha_expandir.png';
import ic_tomarfoto_dark from '../recGraficos/ic_tomarfoto_dark.png';
import ic_silbato_blanco from '../recGraficos/ic_silbato_blanco.png';
import ic_jugador_registro from '../recGraficos/ic_jugador_registro.png';
import ic_entrar from '../recGraficos/ic_entrar_gris.png';
import ic_seleccionar_imagen from '../recGraficos/ic_seleccionar_imagen.png';
import ic_mi_informacion_blanco from '../recGraficos/ic_mi_informacion_blanco.png';
import ic_mi_informacion from '../recGraficos/ic_mi_informacion.png';
import ic_manual_primary from '../recGraficos/ic_manual_primary.png';


import DinamicoElementosTorneo from "../gestionVisual/DinamicoElementosTorneo";
import { useModal } from "../helpers/useModal";

import "../estilos/BF_BordesFondos.css";
import GestionImagen from "../gestionVisual/GestionImagen";
import ModalFull from "../recVisual/ModalFull";
import Modal from "../recVisual/Modal";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { estilosSelect } from "../estilos/estilosSelect";
import { listaDosItems } from "../recVisual/listaDesplegable";
import Select from "react-select";


const ruta=recuperarDatoLocal("rutaRecursos");
const nomClase="Info_Jugador_WB";
const idModulo=ID_MODULO_INFO_JUGADOR;

let idUser="";
let tipoUser=recuperarDatoLocal("tipoUser");;

let idJugador="";
let idTorneo="";
let idEquipo="";


let datosInicial = {
    tituloVentana:"Administración Jugadores",
    fotoUsuario:ic_jugadorsinimagen,
    rolUsuario:"",
    nomUsuario:"",
    nomEmpresa:"",
    nomEquipo:"",
    escudoEquipo:ic_escudo_base_gris
};

let matrizCampos=[
    {id: "NOMBRE",  idx: 0, valor: "", ultValor:"", error: "Ingrese su nombre"},
    {id: "APELLIDO",  idx: 1, valor: "", ultValor:"", error: "Ingrese su apellido"},
    {id: "IDENTIFICACION",  idx: 2, valor: "", ultValor:"", error: "Ingrese un número válido"},
    {id: "TELEFONO",  idx: 3, valor: "", ultValor:"", error: "Ingrese un teléfono válido"},
    {id: "TELEFONO2",  idx: 4, valor: "", ultValor:"", error: "Ingrese un teléfono válido"},
    {id: "FECHA_NACIMIENTO",  idx: 5, valor: "", ultValor:"", error: ""},
    {id: "ESTATURA",  idx: 6, valor: "", ultValor:"", error: ""},
    {id: "PESO",  idx: 7, valor: "", ultValor:"", error: ""},
    {id: "CORREO",  idx: 8, valor: "", ultValor:"", error: ""},
    {id: "FOTO",  idx: 9, valor: "", ultValor:"", error: ""},
    {id: "MD_CAMISETA",  idx: 10, valor: "", ultValor:"", error: "Debe ingresar un número válido"}
];


let estaRegistrado=true;
let puedeModificar=false;

let telefonoACAmbiar="";

let titulo="";
let mensaje="";
let ventanaMensaje;

let imgUsuario=ic_jugadorsinimagen;
let eqSeleccionado=0;

let listaOrigenSel="";
let regListaSel=-1;
let regCampoSel=-1;


let infoUsuarioO = [];
let infoUsuario = [];
function crearInfoUsuario(datos){    
    const nomFuncion="crearInfoUsuario";

    try{        
        let usuario = {
            idusuario:"",
            nombre:"",
            apellido:"",
            telefono:"",
            telefono2:"",
            documento:"",
            fechanacimiento:"",
            estatura:"",
            peso:"",
            edad:"",
            foto:"",
            indice:"",
            actualDate:"", 
            correo:""
        }
    
        infoUsuarioO = [];
        infoUsuario = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , usuario);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idusuario=datos[i][j];
                if(j===1) registro.nombre=datos[i][j];
                if(j===2) registro.apellido=datos[i][j];
                if(j===3) registro.telefono=datos[i][j];
                if(j===4) registro.telefono2=datos[i][j];
                if(j===5) registro.documento=datos[i][j];
                if(j===6) registro.fechanacimiento=datos[i][j];
                if(j===7) registro.estatura=datos[i][j];
                if(j===8) registro.peso=datos[i][j];
                if(j===9) registro.edad=datos[i][j];
                if(j===10) registro.foto=datos[i][j];
                if(j===11) registro.indice=datos[i][j];
                if(j===12) registro.actualDate=datos[i][j];
                if(j===13) registro.correo=datos[i][j];
            }

            infoUsuarioO.push(registro);
            infoUsuario.push(datos[i]); 

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let infoJugadorO = [];
let infoJugador = [];
function crearInfoJugador(datos){    
    const nomFuncion="crearInfoJugador";

    try{        
        let jugador = {
            idusuario:"",
            idjugador:"",
            idequipo:"",
            idposicion:"",
            nomEmpresa:"",
            nomSede:"",
            nomTorneo:"",
            nomEquipo:"",
            escudo:"",
            posicion:"",
            camiseta:"",
            esadmin:"",
            activo:"",
            fecha_maxreg:"",
            numMaxJugadores:"",
            idtorneo:""
        }
    
        infoJugadorO = [];
        infoJugador = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idusuario=datos[i][j];
                if(j===1) registro.idjugador=datos[i][j];
                if(j===2) registro.idequipo=datos[i][j];
                if(j===3) registro.idposicion=datos[i][j];
                if(j===4) registro.nomEmpresa=datos[i][j];
                if(j===5) registro.nomSede=datos[i][j];
                if(j===6) registro.nomTorneo=datos[i][j];
                if(j===7) registro.nomEquipo=datos[i][j];
                if(j===8) registro.escudo=datos[i][j];
                if(j===9) registro.posicion=datos[i][j];
                if(j===10) registro.camiseta=datos[i][j];
                if(j===11) registro.esadmin=datos[i][j];
                if(j===12) registro.activo=datos[i][j];
                if(j===13) registro.fecha_maxreg=datos[i][j];
                if(j===14) registro.numMaxJugadores=datos[i][j];
                if(j===15) registro.idtorneo=datos[i][j];
            }

            infoJugadorO.push(registro);
            infoJugador.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let infoArbitroO = [];
let infoArbitro = [];
function crearInfoArbitro(datos){    
    const nomFuncion="crearInfoArbitro";

    try{        
        let arbitro = {
            idusuario:"",
            idarbitro:"",
            nomEmpresa:"",
            nomSede:"",
            horario:""
        }
    
        infoArbitroO = [];
        infoArbitro = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , arbitro);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idusuario=datos[i][j];
                if(j===1) registro.idarbitro=datos[i][j];
                if(j===2) registro.nomEmpresa=datos[i][j];
                if(j===3) registro.nomSede=datos[i][j];
                if(j===4) registro.horario=datos[i][j];
            }
            infoArbitroO.push(registro);
            infoArbitro.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let infoJugadoresEqO = [];
let infoJugadoresEq = [];
function crearInfoJugadoresEq(datos){    
    const nomFuncion="crearInfoJugadoresEq";

    try{        
        let jugador = {
            idequipo:"",
            idjugador:"",
            camiseta:"",
            nombre:"",
            activo:"",
            idposicion:""
        }
    
        infoJugadoresEqO = [];
        infoJugadoresEq = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idequipo=datos[i][j];
                if(j===1) registro.idjugador=datos[i][j];
                if(j===2) registro.camiseta=datos[i][j];
                if(j===3) registro.nombre=datos[i][j];
                if(j===4) registro.activo=datos[i][j];
                if(j===5) registro.idposicion=datos[i][j];
            }
            infoJugadoresEqO.push(registro);
            infoJugadoresEq.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let infoCamposO = [];
let infoCampos = [];
function crearInfoCampos(datos){    
    const nomFuncion="crearInfoCampos";

    try{        
        let campo = {
            idcampo:"",
            idconfigC:"",
            idequipo:"",
            idjugador:"",
            valor:"",
            indice:"",
            sininfo:"",
            idtorneo:"",
            idconfig:"",
            tipo_campo:"",
            nombre:"",
            descripcion:"",
            min:"",
            max:"",
            obligatorio:""
        }
    
        infoCamposO = [];
        infoCampos = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , campo);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idcampo=datos[i][j];
                if(j===1) registro.idconfigC=datos[i][j];
                if(j===2) registro.idequipo=datos[i][j];
                if(j===3) registro.idjugador=datos[i][j];
                if(j===4) registro.valor=datos[i][j];
                if(j===5) registro.indice=datos[i][j];
                if(j===6) registro.sininfo=datos[i][j];
                if(j===7) registro.idtorneo=datos[i][j];
                if(j===8) registro.idconfig=datos[i][j];
                if(j===9) registro.tipo_campo=datos[i][j];
                if(j===10) registro.nombre=datos[i][j];
                if(j===11) registro.descripcion=datos[i][j];
                if(j===12) registro.min=datos[i][j];
                if(j===13) registro.max=datos[i][j];
                if(j===14) registro.obligatorio=datos[i][j];
            }

            infoCamposO.push(registro);
            infoCampos.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}



const Info_Jugador = ({params="", definirModulo, modOrigen=0, paramOrigen=""}) => {
    const [esError, setEsError] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showMensaje, setShowMensaje] = useState(false);
    const [msjModal, setMsjModal] = useState("");

    const [isOpenBusqueda, openBusqueda, closeBusqueda] = useModal(false);
    const [isOpenImagen, openImagen, closeImagen] = useModal(false);
    const [isOpenCalendar, openCalendar, closeCalendar] = useModal(false);
    const [isOpenCambioPC, openCambioPC, closeCambioPC] = useModal(false);
    const [isOpenFoto, openFoto, closeFoto] = useModal(false);
    
    const [estaCambiandoTelefonoUS, setEstaCambiandoTelefonoUS] = useState(false);

    const [arrCampos, setArrCampos] = useState(matrizCampos);
    const [datosEncontrados, setDatosEncontrados] = useState([]);
    

    const [showAlertaSinTelefono, setShowAlertaSinTelefono] = useState(false);
    const [permiteSinTelefonoUS, setPermiteSinTelefonoUS] = useState(false);
    const [confirmoSinTelefonoUS, setConfirmoSinTelefonoUS] = useState(false);
    const [aceptoSinTelefonoUS, setAceptoSinTelefonoUS] = useState(false);

    const [selectedDate, setSelectedDate] = useState(null);

    const [infoPosicion, setInfoPosicion] = useState([]);
    const [posicionSel, setPosicionSel] = useState(0);
    const [nombreJug, setNombreJug] = useState("");

    const [datosEquipos, setDatosEquipos] = useState([]);

    const [tipoImagenSelUS, setTipoImagenSelUS] = useState("");
    const [imgActualSelUS, setImgActualSelUS] = useState("");

    const [srcFotoUS, setSrcFotoUS] = useState("");


    var respuesta = {
        datos: "",
        exito: false,
        conectado: false,
        hayDatos: false,
        mensaje: ''
    }

    useEffect(() => {
        const nomFuncion="useEffect";

        try{          

            let tempPosicion=[
                {value:"0", label:"SIN ASIGNAR", texto2:""},
                {value:"1", label:"ARQUERO", texto2:""},
                {value:"2", label:"DEFENSA", texto2:""},
                {value:"3", label:"VOLANTE", texto2:""},
                {value:"4", label:"DELANTERO", texto2:""},
                {value:"5", label:"CUERPO TECNICO", texto2:""}
            ];
            setInfoPosicion(tempPosicion);
            
            let ultParametros=recuperarDatoLocal("ultParametros" + idModulo);

            if(params!==""){
                let actualizar=false;
                let info=dividirLista(params,SEP_4,5);
                
                idUser=ultParametros;

                if(idUser==="" || idUser!==info[0]){
                    actualizar=true;
                    idUser=info[0];
                }

                if(!actualizar) {
                    actualizar= (isFlagUpdate() || superaTiempoActualizacion(idModulo));
                }
                
                guardarDatoLocal("ultParametros" + idModulo, idUser);
                //actualizar=true;
                if(actualizar){
                    BD_solicitarDatos("INFO_INICIAL",[]);    
                }
                else{
                    cargarDatosGuardadosModulo();
                }
                
            }
    

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }     
        

    }, [params]);


    if(params==="") return null;

    const BD_solicitarDatos = (tipoDatos, params) => {
        let nomFuncion="BD_solicitarDatos"; 

        try{
            let actualizar=true;
            let SEP=SEPARADOR_PARAMETROS_BD;
            let paramQuery="";
            let tipoConsulta="";
            let funcion="";
            let consulta="";
            let zonaHoraria="0";
            let mostrarError=true;
            let mostrarProcesando=true;

            let codigoFinal="";
            
            if(actualizar){                

                if(tipoDatos==="INFO_INICIAL"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleUsuario";
                    consulta="INFO_DETALLE_INFO_JUGADOR";
                    
                    paramQuery= idUser;
                } else if(tipoDatos==="ACTUALIZAR_CAMPOS_USUARIO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarUsuario";
                    consulta="ACTUALIZAR_CAMPOS_USUARIO";
                    mostrarProcesando=false;

                    let txtParejasUdt="";
                    let parejas=params.split(SEP_D_1);
                    for(let i=0;i<parejas.length;i++){
                        let columnas=parejas[i].split(SEP_3);
                        let valor="";
                        if(columnas.length===2) valor=columnas[1].trim();

                        if(valor==="NULL"){
                            if(txtParejasUdt==="") txtParejasUdt=columnas[0] + "=NULL ";
                            else txtParejasUdt=txtParejasUdt + ", " + columnas[0] + "=NULL ";
                        }
                        else{
                            if(txtParejasUdt==="") txtParejasUdt=columnas[0] + "='" + valor + "' ";
                            else txtParejasUdt=txtParejasUdt + ", " + columnas[0] + "='" + valor + "' ";
                        }
                    }
                    
                    paramQuery= idUser + SEP + txtParejasUdt;
                } else if(tipoDatos==="GUARDAR_IMAGEN_SERVIDOR"){
                    tipoConsulta="ENVIAR_IMAGEN";
                    funcion="usuario";
                    consulta="";
                    mostrarProcesando=false;

                    let imagen=params.replace("data:image/jpeg;base64,","");;
                    let id="";
                    let indice="0";

                    if(listaOrigenSel===""){
                        id=idUser;
                        indice=infoUsuarioO[0].indice;
                    }
                    else{                        
                        funcion="campoAdicional";
                        let regInfocampos=-1;

                        regInfocampos=datosEquipos[regListaSel].camposAdicionales[regCampoSel].regCampos;


                        if(regInfocampos>=0){
                            if(infoCamposO[regInfocampos].idcampo!==""){
                                id=infoCamposO[regInfocampos].idcampo;
                                indice=infoCamposO[regInfocampos].indice;
                            }
                            else{
                                let idConfig="";
                                let idEq="";
                                let idJug="";
            
                                idConfig=infoCamposO[regInfocampos].idconfig;
                                idEq=datosEquipos[regListaSel].idequipo;
                                idJug=datosEquipos[regListaSel].idjugador;

                                id=idConfig + "|" + idEq + "|" + idJug
                            }
                        }                        
                        
                    }

                    paramQuery=[imagen,id,indice];
                } else if(tipoDatos==="BORRAR_IMAGEN_SERVIDOR_JUGADOR"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarUsuario";
                    consulta="BORRAR_IMAGEN_SERVIDOR_USUARIO";
                    mostrarProcesando=false;

                    paramQuery=idUser;
                } else if(tipoDatos==="ACTUALIZAR_POSICION_CAMISETA"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarJugador";
                    consulta="ACTUALIZAR_POSICION_CAMISETA";
                    mostrarProcesando=false;

                    let idJugModificado= infoJugadorO[eqSeleccionado].idjugador;
                    let idEquipoModificado= infoJugadorO[eqSeleccionado].idequipo;
                    let posicion=params[0];
                    let camiseta=params[1]
                    paramQuery= idJugModificado + SEP + idEquipoModificado + SEP + posicion + SEP + camiseta;
                } else if(tipoDatos==="ACTUALIZAR_CAMPOS_ADICIONALES"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarCamposAdicionales";
                    consulta="ACTUALIZAR_CAMPOS_ADICIONALES";
                    mostrarProcesando=false;

                    let idCampo = params[0];
                    let valor = params[1];                                       
                    
                    paramQuery= idCampo + SEP + valor;
                    
                } else if(tipoDatos==="INSERTAR_CAMPOS_ADICIONALES"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarCamposAdicionales";
                    consulta="INSERTAR_CAMPOS_ADICIONALES";
                    mostrarProcesando=false;

                    let idconfig = params[0];
                    let idequipo = params[1];
                    let idjugador = params[2];
                    let valor = params[3];                  
                    
                    paramQuery= idconfig + SEP + idequipo + SEP + idjugador + SEP + valor;
                }
                
                if(mostrarProcesando) setLoading(true);

                getDatos()
                .get(tipoConsulta,funcion,consulta,paramQuery,zonaHoraria)
                .then((res) => {
                    nomFuncion="BD_solicitarDatos.respuesta"; 

                    try{
                        respuesta=res;
                        if (respuesta.exito) {
                            if(tipoDatos==="INFO_INICIAL"){
                                presentarLog(nomClase + " - SOLICITA_DATOS"); 
    
                                guardarDatoLocal("ultAcceso" + idModulo,Date.now());
                                guardarDatoLocal("datos" + idModulo, respuesta.datos);
    
                                crearInfoUsuario(crearTabla(respuesta.datos,"1"));
                                crearInfoJugador(crearTabla(respuesta.datos,"2"));
                                crearInfoArbitro(crearTabla(respuesta.datos,"3"));
                                crearInfoJugadoresEq(crearTabla(respuesta.datos,"4"));
                                crearInfoCampos(crearTabla(respuesta.datos,"5"));
    
                                generarDatosPresentar();
                                generarListaEquipos();
                            
                                registrarLogEventos(idModulo, "1", idUser,"");
                            } else if(tipoDatos==="ACTUALIZAR_CAMPOS_USUARIO"){
                                
                                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
    
                                let llaves=params.split(SEP_D_1);
                                for(let i=0;i<llaves.length;i++){
                                    let columnas=llaves[i].split(SEP_3);
                                    if(columnas.length===2){
                                        if(columnas[0]==="nombre1") {
                                            infoUsuario[0][1]=columnas[1];
                                            infoUsuarioO[0].nombre=columnas[1];
                                            tempArreglo[0].valor=columnas[1];
                                            tempArreglo[0].ultValor=columnas[1];
                                            setFlagUpdate(true);
                                        }
                                        else if(columnas[0]==="apellido1")  {
                                            infoUsuario[0][2]=columnas[1];
                                            infoUsuarioO[0].apellido=columnas[1];
                                            tempArreglo[1].valor=columnas[1];
                                            tempArreglo[1].ultValor=columnas[1];
                                            setFlagUpdate(true);
                                        }
                                        else if(columnas[0]==="telefono2")  {
                                            infoUsuario[0][5]=columnas[1];
                                            infoUsuarioO[0].documento=columnas[1];
                                            tempArreglo[2].valor=columnas[1];
                                            tempArreglo[2].ultValor=columnas[1];
                                        }
                                        else if(columnas[0]==="documento")  {
                                            infoUsuario[0][3]=columnas[1];
                                            infoUsuarioO[0].telefono=columnas[1];
                                            tempArreglo[3].valor=columnas[1];
                                            tempArreglo[3].ultValor=columnas[1];
                                        }
                                        else if(columnas[0]==="telefono1")  {
                                            infoUsuario[0][4]=columnas[1];
                                            infoUsuarioO[0].telefono2=columnas[1];
                                            tempArreglo[4].valor=columnas[1];
                                            tempArreglo[4].ultValor=columnas[1];
                                        }
                                        else if(columnas[0]==="fechanacimiento")  {
                                            infoUsuario[0][6]=columnas[1];
                                            infoUsuarioO[0].fechanacimiento=columnas[1];
                                            tempArreglo[5].valor=columnas[1];
                                            tempArreglo[5].ultValor=columnas[1];
                                        }
                                        else if(columnas[0]==="estatura")  {
                                            infoUsuario[0][7]=columnas[1];
                                            infoUsuarioO[0].estatura=columnas[1];
                                            tempArreglo[6].valor=columnas[1];
                                            tempArreglo[6].ultValor=columnas[1];
                                        }
                                        else if(columnas[0]==="peso")  {
                                            infoUsuario[0][8]=columnas[1];
                                            infoUsuarioO[0].peso=columnas[1];
                                            tempArreglo[7].valor=columnas[1];
                                            tempArreglo[7].ultValor=columnas[1];
                                        }
                                    }
                                }
    
                                setArrCampos(tempArreglo);                                                     
                                titulo="";
                                mensaje="Información actualizada con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");
                                reconstruirListasModulo();   
                                generarDatosPresentar();

                            } else if(tipoDatos==="GUARDAR_IMAGEN_SERVIDOR"){
                                if(listaOrigenSel===""){
                                    let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                                    tempArreglo[9].valor=params;    
                                    setArrCampos(tempArreglo);
    
                                    infoUsuario[0][10]=params;
                                    infoUsuarioO[0].foto=params;

                                    setFlagUpdate(true);
                                }
                                else{
                                    let regInfoCampos=-1;
                                    regInfoCampos=datosEquipos[regListaSel].camposAdicionales[regCampoSel].regCampos;
                                        

                                    let newIndice = 1;
                                    let retorno =  respuesta.datos;

                                    let datosRec=retorno.split("|");

                                    let idImagenFinal =datosRec[0];
                                    let rutaFinal =datosRec[1];
            
                                    if(infoCamposO[regInfoCampos].idcampo!==""){
                                        newIndice = valorNumero(infoCamposO[regInfoCampos].indice) + 1;

                                        infoCamposO[regInfoCampos].valor=rutaFinal;
                                        infoCamposO[regInfoCampos].indice=newIndice;
                                        infoCampos[regInfoCampos][4]=rutaFinal;
                                        infoCampos[regInfoCampos][5]=newIndice;
                                    }
                                    else{
                                        let idcampo = idImagenFinal;
                                        let idConfig=infoCamposO[regInfoCampos].idconfig;
                                        let valor=rutaFinal;

                                        infoCamposO[regInfoCampos].idcampo=idcampo;
                                        infoCamposO[regInfoCampos].idconfigC=idConfig;
                                        infoCamposO[regInfoCampos].valor=valor;
                                        infoCamposO[regInfoCampos].indice=newIndice;
        
                                        infoCampos[regInfoCampos][0]=idcampo;
                                        infoCampos[regInfoCampos][1]=idConfig;
                                        infoCampos[regInfoCampos][4]=valor;                 
                                        infoCampos[regInfoCampos][5]=newIndice;
                                        
                                    }
                                }

                                titulo="";
                                mensaje="Imágen actualizada con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA"); 
                                
                                generarListaEquipos();
                                reconstruirListasModulo(); 
                                generarDatosPresentar();
                                
                            } else if(tipoDatos==="BORRAR_IMAGEN_SERVIDOR_JUGADOR"){
                                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                                tempArreglo[9].valor="";
                                setArrCampos(tempArreglo);

                                infoUsuario[0][10]="";
                                infoUsuarioO[0].foto="";

                                titulo="";
                                mensaje="Imágen BORRADA con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA"); 

                                reconstruirListasModulo(); 
                                generarDatosPresentar();
                                setFlagUpdate(true);
                            } else if(tipoDatos==="ACTUALIZAR_POSICION_CAMISETA"){
                                titulo="";
                                mensaje="Información actualizadas con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");
                                
                                reconstruirListasModulo();  
                            } else if(tipoDatos==="ACTUALIZAR_CAMPOS_ADICIONALES"){
                                                            
                                let valor = params[1];  
                                let regCampo=params[2];

                                infoCamposO[regCampo].valor=valor;
                                infoCampos[regCampo][4]=valor;

                                titulo="";
                                mensaje="Información actualizada con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");

                                generarListaEquipos();
                                reconstruirListasModulo();

                            } else if(tipoDatos==="INSERTAR_CAMPOS_ADICIONALES"){
                                
                                let idcampo = respuesta.datos;
                                let idConfig=params[0];
                                let valor=params[3];
                                let regCampos=params[4];

                                infoCamposO[regCampos].idcampo=idcampo;
                                infoCamposO[regCampos].idconfigC=idConfig;
                                infoCamposO[regCampos].valor=valor;

                                infoCampos[regCampos][0]=idcampo;
                                infoCampos[regCampos][1]=idConfig;
                                infoCampos[regCampos][4]=valor;

                                titulo="";
                                mensaje="Información actualizada con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");

                                generarListaEquipos();
                                reconstruirListasModulo();
                            }      
                                                       
            
                        } else {
                            if(respuesta.conectado){
                                reportarError( nomClase, nomFuncion, respuesta.mensaje, "");
                                if(mostrarError) setEsError(1);
                            } 
                            else{    
                                presentarError(nomClase,nomFuncion,respuesta.mensaje,"");                        
                                setEsError(2);
                            }                        
                        } 

                    }catch(err){
                        reportarError( nomClase, nomFuncion, "", err);
                    } 
                    
        
                    setLoading(false);
                    
                });
            }
            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 

    const cargarDatosGuardadosModulo = () => {
        let nomFuncion="cargarDatosGuardadosModulo"; 

        try{             
            presentarLog(nomClase + " - RECUPERA_DATOS"); 
            let datosModulo=recuperarDatoLocal("datos" + idModulo);

            if(datosModulo!==""){
                crearInfoUsuario(crearTabla(datosModulo,"1"));
                crearInfoJugador(crearTabla(datosModulo,"2"));
                crearInfoArbitro(crearTabla(datosModulo,"3"));
                crearInfoJugadoresEq(crearTabla(datosModulo,"4")); 
                crearInfoCampos(crearTabla(datosModulo,"5"));
                
                generarDatosPresentar();
                generarListaEquipos();
            } 
            else BD_solicitarDatos("INFO_INICIAL",[]);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
    }
    
    const reconstruirListasModulo = () => {
        let nomFuncion="reconstruirListasModulo"; 

        try{ 
            
            let datosFinales=[];
            datosFinales=reconstruirDatosOrigen(datosFinales,infoUsuario,"1");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoJugador,"2");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoArbitro,"3");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoJugadoresEq,"4");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoCampos,"5");
            
            guardarDatoLocal("datos" + idModulo, datosFinales);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 

    const generarDatosPresentar = () => {
        let nomFuncion="generarDatosPresentar";

        try{
       
            //Información del Jugador            
            if(true){               
                let formularioTemp=JSON.parse(JSON.stringify(arrCampos)); 

                formularioTemp[0].valor=infoUsuarioO[0].nombre;
                formularioTemp[0].ultValor=infoUsuarioO[0].nombre;
                if(formularioTemp[0].valor.length>=3) formularioTemp[0].error="";
                formularioTemp[1].valor=infoUsuarioO[0].apellido;
                formularioTemp[1].ultValor=infoUsuarioO[0].apellido;
                if(formularioTemp[1].valor.length>=3) formularioTemp[1].error="";
                formularioTemp[2].valor=infoUsuarioO[0].documento;
                formularioTemp[2].ultValor=infoUsuarioO[0].documento;
                if(esformatoNumero(formularioTemp[2].valor) && formularioTemp[2].valor.length>=6) formularioTemp[2].error="";
                formularioTemp[3].valor=infoUsuarioO[0].telefono;
                formularioTemp[3].ultValor=infoUsuarioO[0].telefono;
                if(esformatoNumero(formularioTemp[3].valor) && formularioTemp[3].valor.length>=10) formularioTemp[3].error="";
                formularioTemp[4].valor=infoUsuarioO[0].telefono2;
                formularioTemp[4].ultValor=infoUsuarioO[0].telefono2;
                if(formularioTemp[4].valor==="" || (esformatoNumero(formularioTemp[4].valor) && formularioTemp[4].valor.length>=10)) formularioTemp[4].error="";
                formularioTemp[5].valor=infoUsuarioO[0].fechanacimiento;
                formularioTemp[5].ultValor=infoUsuarioO[0].fechanacimiento;
                formularioTemp[6].valor=infoUsuarioO[0].estatura;
                formularioTemp[6].ultValor=infoUsuarioO[0].estatura;
                formularioTemp[7].valor=infoUsuarioO[0].peso;
                formularioTemp[7].ultValor=infoUsuarioO[0].peso;
                formularioTemp[8].valor=infoUsuarioO[0].correo;
                
                if(infoUsuarioO[0].foto!=="") { 
                    if(infoUsuarioO[0].foto.includes("data:image/")) formularioTemp[9].valor= infoUsuarioO[0].foto;
                    else formularioTemp[9].valor= ruta + infoUsuarioO[0].foto;
                }
                else formularioTemp[9].valor= ic_seleccionar_imagen;

                if(infoUsuarioO[0].fechanacimiento!=="") setSelectedDate(infoUsuarioO[0].fechanacimiento+ "T00:00:00");
                
                setArrCampos(formularioTemp);
                
            } 


            //Datos de Equipos del jugador
            //setDatosEquipos(infoEquiposO);


    

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

    }

    const validarCampo = (e, campo, maxlen) => {
        let nomFuncion="validarCampo"; 

        try{
            let newValor = e.target.value;
            if(maxlen!==null && newValor.length>maxlen) newValor=newValor.substring(0,maxlen);

            let tempArreglo=JSON.parse(JSON.stringify(arrCampos));

            if(campo==="NOMBRE"){  
                tempArreglo[0].error=matrizCampos[0].error;
                
                tempArreglo[0].valor=newValor; 
                if(newValor.length>=3) tempArreglo[0].error="";   
                
                setArrCampos(tempArreglo);  
            } else if(campo==="APELLIDO"){  
                tempArreglo[1].error=matrizCampos[1].error;
                
                tempArreglo[1].valor=newValor;  
                if(newValor.length>=3) tempArreglo[1].error="";     
                
                setArrCampos(tempArreglo);  
            } else if(campo==="IDENTIFICACION"){
                tempArreglo[2].error=matrizCampos[2].error;
                tempArreglo[2].valor=newValor; 
                if(newValor.length>=6) tempArreglo[2].error="";   

                setArrCampos(tempArreglo);  
            } else if(campo==="TELEFONO"){
                tempArreglo[3].error=matrizCampos[3].error;
                tempArreglo[3].valor=newValor; 
                if(esformatoNumero(newValor) && newValor.length>=10) tempArreglo[3].error="";   
                
                setArrCampos(tempArreglo);  
            } else if(campo==="TELEFONO2"){
                tempArreglo[4].error=matrizCampos[4].error;
                tempArreglo[4].valor=newValor; 
                if(newValor.trim()==="" || (esformatoNumero(newValor) && newValor.length>=10)) tempArreglo[4].error="";   
                
                setArrCampos(tempArreglo);  
            } else if(campo==="ESTATURA"){
                tempArreglo[6].error=""; 
                tempArreglo[6].valor=newValor;

                if(newValor!=="" && valorNumero(newValor)>250) tempArreglo[6].error="Valor Máximo 250"; 
                
                setArrCampos(tempArreglo);  
            } else if(campo==="PESO"){
                tempArreglo[7].error=""; 
                tempArreglo[7].valor=newValor;   
                
                if(newValor!=="" && valorNumero(newValor)>200) tempArreglo[7].error="Valor Máximo 200"; 
                
                setArrCampos(tempArreglo);  
            } else if(campo==="MD_CAMISETA"){
                tempArreglo[10].valor=newValor;
                if(esformatoNumero(newValor) && valorNumero(newValor)>0) {
                    let valCamiseta=validarCamiseta(newValor);
                    if(valCamiseta==="") tempArreglo[10].error="";    
                    else tempArreglo[10].error=valCamiseta;
                }  
                else{
                    tempArreglo[10].error=matrizCampos[10].error;
                }
                setArrCampos(tempArreglo); 
            }          
                    
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }         
        
    }

    const clickGenerico = (donde, params=null) => {
        let nomFuncion="clickGenerico"; 

        try{
            
            let tempArreglo=null;
            
            switch(donde) {
                  
                case "CLICK_MODIFCAR_FOTO":
                    listaOrigenSel="";
                    setTipoImagenSelUS("USUARIO");
                    setImgActualSelUS(arrCampos[9].valor);
                    openImagen();

                    break;
                case "SALE_NOMBRE":
                    if(arrCampos[0].error===""){
                        let nuevoDato=quitarCharEspeciales(arrCampos[0].valor);
                        nuevoDato=formatearTexto(nuevoDato,2);
                        let txtActualizar="nombre1" + SEP_3 + nuevoDato;
                        if(nuevoDato!==arrCampos[0].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_USUARIO", txtActualizar);
                    }
                    break;
                case "SALE_APELLIDO":
                    if(arrCampos[1].error===""){
                        let nuevoDato=quitarCharEspeciales(arrCampos[1].valor);
                        nuevoDato=formatearTexto(nuevoDato,2);
                        let txtActualizar="apellido1" + SEP_3 + nuevoDato;
                        if(nuevoDato!==arrCampos[1].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_USUARIO", txtActualizar);
                    }
                    break;        
                case "SALE_IDENTIFICACION":
                    if(arrCampos[2].error===""){
                        let nuevoDato=quitarCharEspeciales(arrCampos[2].valor);
                        //nuevoDato=formatearTexto(nuevoDato,2);
                        let txtActualizar="telefono2" + SEP_3 + nuevoDato;
                        if(nuevoDato!==arrCampos[2].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_USUARIO", txtActualizar);
                    }
                    break;  
                case "SALE_TELEFONO":
                    if(arrCampos[3].error===""){
                        let nuevoDato=quitarCharEspeciales(arrCampos[3].valor);
                        //nuevoDato=formatearTexto(nuevoDato,2);
                        let txtActualizar="documento" + SEP_3 + nuevoDato;
                        if(nuevoDato!==arrCampos[3].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_USUARIO", txtActualizar);
                    }
                    break; 
                case "SALE_TELEFONO2":
                    if(arrCampos[4].error===""){
                        let nuevoDato=quitarCharEspeciales(arrCampos[4].valor);
                        //nuevoDato=formatearTexto(nuevoDato,2);
                        let txtActualizar="telefono1" + SEP_3 + nuevoDato;
                        if(nuevoDato!==arrCampos[4].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_USUARIO", txtActualizar);
                    }
                    break;  
                case "SALE_ESTATURA":
                    if(arrCampos[6].error===""){
                        let nuevoDato=quitarCharEspeciales(arrCampos[6].valor);
                        //nuevoDato=formatearTexto(nuevoDato,2);
                        let txtActualizar="estatura" + SEP_3 + nuevoDato;
                        if(nuevoDato!==arrCampos[6].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_USUARIO", txtActualizar);
                    }
                    break; 
                case "SALE_PESO":
                    if(arrCampos[7].error===""){
                        let nuevoDato=quitarCharEspeciales(arrCampos[7].valor);
                        //nuevoDato=formatearTexto(nuevoDato,2);
                        let txtActualizar="peso" + SEP_3 + nuevoDato;
                        if(nuevoDato!==arrCampos[7].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_USUARIO", txtActualizar);
                    }
                    break;                 
                case "CERRAR_MODAL_IMAGEN":
                    closeImagen();
                    break;
                case "GUARDAR_IMAGEN_SERVIDOR":
                    closeImagen();

                    if(listaOrigenSel!==""){
                        let tempDatos=JSON.parse(JSON.stringify(datosEquipos));
                        tempDatos[regListaSel].camposAdicionales[regCampoSel].valor=params;
                        tempDatos[regListaSel].camposAdicionales[regCampoSel].ultValor=params;
                        setDatosEquipos(tempDatos);
                    }
                    else{
                        let tempDatos=JSON.parse(JSON.stringify(arrCampos));
                        tempDatos[9].valor=params;
                        setArrCampos(tempDatos);
                    }

                    BD_solicitarDatos("GUARDAR_IMAGEN_SERVIDOR",params);                   

                    break;
                case "SOLICITAR_CONFIRMACION_BORRADO_IMAGEN":
                    titulo="CONFIRMAR BORRADO";
                    mensaje="Esta acción borrará la Imágen Actualmente cargada. Esta de acuerdo?";
                    ventanaMensaje=getConfirmacion(titulo,mensaje,setShowMensaje,null,clickGenerico,"ACEPTAR_BORRADO_IMAGEN");

                    break;
                case "ACEPTAR_BORRADO_IMAGEN":
                    setShowMensaje(false);
                    closeImagen();

                    if(listaOrigenSel!==""){
                        let tempDatos=JSON.parse(JSON.stringify(datosEquipos));
                        tempDatos[regListaSel].camposAdicionales[regCampoSel].valor="";
                        tempDatos[regListaSel].camposAdicionales[regCampoSel].ultValor="";
                        setDatosEquipos(tempDatos);

                        let regInfocampos=datosEquipos[regListaSel].camposAdicionales[regCampoSel].regCampos; 

                        if(regInfocampos>=0){
                            let idCampo=infoCamposO[regInfocampos].idcampo;
                            BD_solicitarDatos("ACTUALIZAR_CAMPOS_ADICIONALES",[idCampo,"",regInfocampos]);
                        }
                    }
                    else{
                        let tempDatos=JSON.parse(JSON.stringify(arrCampos));
                        tempDatos[9].valor=ic_jugadorsinimagen;
                        setArrCampos(tempDatos);

                        BD_solicitarDatos("BORRAR_IMAGEN_SERVIDOR_JUGADOR");
                    }

                    

                    
                    break;                
                case "SELECCIONAR_FECHA_NACIMIENTO":
                        listaOrigenSel="";
                        setSelectedDate(arrCampos[5].valor);
                        openCalendar();
    
                        break;
                case "SELECCIONAR_FECHA":
                    setSelectedDate(params);
                    closeCalendar();

                    let fecha=params;
                    let ano=fecha.getFullYear();
                    let mes=fecha.getMonth() + 1;
                    let dia=fecha.getUTCDate();
                    
                    mes="00" + mes;
                    let lenMes=mes.length;
                    mes=mes.substring(lenMes-2);

                    dia="00" + dia;
                    let lenDia=dia.length;
                    dia=dia.substring(lenDia-2);

                    let newFecha=ano + "-" + mes + "-" + dia;

                    if(listaOrigenSel===""){
                        tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                        tempArreglo[5].valor=newFecha;
                        setArrCampos(tempArreglo);
    
                        let txtActualizar="fechanacimiento" + SEP_3 + newFecha;
                        if(newFecha!==arrCampos[5].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_USUARIO", txtActualizar);
                    }
                    else{  
                        let regInfocampos=-1;
                        regInfocampos=datosEquipos[regListaSel].camposAdicionales[regCampoSel].regCampos; 
    
                        if(regInfocampos>=0){   
                            let tempDatos=JSON.parse(JSON.stringify(datosEquipos));
                            tempDatos[regListaSel].camposAdicionales[regCampoSel].valor=newFecha;
                            tempDatos[regListaSel].camposAdicionales[regCampoSel].ultValor=newFecha;
                            setDatosEquipos(tempDatos);

                            if(infoCamposO[regInfocampos].idcampo!==""){
                                let idCampo=infoCamposO[regInfocampos].idcampo;
                                BD_solicitarDatos("ACTUALIZAR_CAMPOS_ADICIONALES",[idCampo,newFecha,regInfocampos]);
                            }
                            else{
                                let idConfig="";
                                let idEq="";
                                let idJug="";
            
                                idConfig=infoCamposO[regInfocampos].idconfig;
                                idEq=datosEquipos[regListaSel].idequipo;
                                idJug=datosEquipos[regListaSel].idjugador;

                                if(idConfig!=="" && idEq!=="" && idJug!==""){
                                    BD_solicitarDatos("INSERTAR_CAMPOS_ADICIONALES",[idConfig,idEq,idJug,newFecha,regInfocampos]);
                                }
                            }
                        }
                    }

                    
                    

                    break;
                case "EDITAR_DATOS_DE_JUEGO":
                        eqSeleccionado=params;

                        if(infoJugadorO[eqSeleccionado].activo==="1"){
                            tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                            let posicion=valorNumero(infoJugadorO[eqSeleccionado].idposicion);
                            
                            let camiseta=valorNumero(infoJugadorO[eqSeleccionado].camiseta);
                            if(camiseta>0 && camiseta<=999){
                                tempArreglo[10].valor="" + camiseta;
                                tempArreglo[10].error="";
                            }
                            else tempArreglo[10].valor="";
    
                            setNombreJug(infoJugadorO[eqSeleccionado].nomEquipo);
                            setPosicionSel(posicion);
                            setArrCampos(tempArreglo);
                            
                            openCambioPC();
                        } else{
                            titulo="NO MODIFICABLE";
                            mensaje="Los datos de juego para el equipo seleccionado no pueden ser modificados. Estas INACTIVO en el equipo.";
                            ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO","MEDIA"); 
                        }                        

                        break;
                case "CAMBIAR_POSICION":
                    let posOriginal=infoJugadorO[eqSeleccionado].idposicion;
                    let posFinal=valorNumero(params.value);
                    let cambiar=true;

                    titulo="NO MODIFICABLE";
                    if(tipoUser!=="administrador") {
                        let puedeModificar=true;
                        if(infoJugador[eqSeleccionado][13]!==""){
                            let fechaActual=infoUsuario[0][12];

                            let comp=operacionFechas(3,fechaActual,infoJugador[eqSeleccionado][13]);
                            if(comp==="2") puedeModificar=false;
                        }

                        if (posOriginal !== posFinal && posFinal === 5) {
                            if (!puedeModificar) {
                                cambiar=false;                                
                                mensaje="Fecha de Modificaciones superada.\n\nSi cambia su posición a CUERPO TÉCNICO ya no podrá cambiarla a una posición de Jugador.\n\nEl CUERPO TÉCNICO no puede actuar como jugador en los partidos.";
                            }
                        } else if(posOriginal!==posFinal && posOriginal===5){
                            if(puedeModificar){
                                let maxJugadores=valorNumero(infoJugador[eqSeleccionado][14]);
                                let jugadoresActivos=buscarJugadoresActivos(infoJugador[eqSeleccionado][2]);

                                if(maxJugadores<=jugadoresActivos){
                                    cambiar=false;   
                                    mensaje="No es posible cambiar a posición de Jugador. Número máximo de Jugadores Activos alcanzado";
                                }
                            }
                            else{
                                cambiar=false;
                                mensaje="Fecha de Modificaciones superada. No puede Modificar los datos de registro de los jugadores. Consulte con Organizador del Torneo.";
                            }

                        }

                        
                    }

                    if(cambiar) setPosicionSel(posFinal);
                    else ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","ALTA");

                    break;
                case "ACEPTAR_CAMBIAR_POSICION":

                    let numC=arrCampos[10].valor;
                    if(arrCampos[10].error===""){
                        if(infoJugadorO[eqSeleccionado].idposicion!==("" + posicionSel) || infoJugadorO[eqSeleccionado].camiseta!==numC){

                            infoJugador[eqSeleccionado][9]=infoPosicion[posicionSel].label;
                            infoJugador[eqSeleccionado][10]=numC;
                            infoJugador[eqSeleccionado][3]="" + posicionSel;

                            infoJugadorO[eqSeleccionado].posicion=infoPosicion[posicionSel].label;
                            infoJugadorO[eqSeleccionado].camiseta=numC;
                            infoJugadorO[eqSeleccionado].idposicion="" + posicionSel;

                            closeCambioPC();

                            BD_solicitarDatos("ACTUALIZAR_POSICION_CAMISETA",[posicionSel,numC]);

                            //reconstruirListasModulo(); 
                            //generarDatosPresentar();
                        }
                        else{
                            titulo="SIN MODIFICACIONES";
                            mensaje="Datos de posición y camiseta NO presentan modificaciones";
                            ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO","BAJA");
                        }
                    }

                    break;
                case "CLICK_MODIFCAR_CAMPO_ADICIONAL":
                    titulo="NO MODIFICABLE";
                    mensaje="Fecha de Modificaciones superada. No puede Modificar los datos adicionales. Consulte con Organizador del Torneo.";
                    ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO");
                        
                    break;
                case "SELECCIONAR_IMAGEN_CAMPO_ADICIONAL":
                        if(params.length>0){
                            listaOrigenSel=params[0];
                            regListaSel=params[1];
                            regCampoSel=params[2];
    
                            let imagen="";
    
                            imagen=datosEquipos[regListaSel].camposAdicionales[regCampoSel].valor;    
    
                            if(imagen==="") imagen=ic_seleccionar_imagen;
    
                            setTipoImagenSelUS("CAMPO_ADICIONAL");
                            setImgActualSelUS(imagen);
    
                            openImagen();
                        }
    
                        break;
                case "MOSTRAR_FOTO":
                        if(params!==""){
                            setSrcFotoUS(params);
                            openFoto();
                        }
    
                        titulo="NO MODIFICABLE";
                        mensaje="Fecha de Modificaciones superada. No puede Modificar los datos adicionales. Consulte con Organizador del Torneo.";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO");
                        
    
                        break;
                case "ABRIR_CALENDARIO_CAMPO_ADICIONAL":
                    if(params.length>0){
                        listaOrigenSel=params[0];
                        regListaSel=params[1];
                        regCampoSel=params[2];
                        let fecha="";

                        fecha=datosEquipos[regListaSel].camposAdicionales[regCampoSel].valor;

                        setSelectedDate(fecha);
                        openCalendar();

                    }

                    break;
                case "ACTUALIZAR_CAMPOS_ADICIONALES":
                    if(params.length>0){
        
                        let listaOrigen=params[0];
                        let regLista=params[1];
                        let regCampo=params[2];
                        let valor=params[3];
    
                        
                        let regInfocampos=-1;

                        regInfocampos=datosEquipos[regLista].camposAdicionales[regCampo].regCampos; 
    

                        if(regInfocampos>=0){
                            if(infoCamposO[regInfocampos].idcampo!==""){
                                let idCampo="";
                                idCampo=infoCamposO[regInfocampos].idcampo;
                                BD_solicitarDatos("ACTUALIZAR_CAMPOS_ADICIONALES",[idCampo,valor,regInfocampos]);
                            }
                            else{
                                let idConfig="";
                                let idEq="";
                                let idJug="";
            
                                idConfig=infoCamposO[regInfocampos].idconfig;
                                idEq=datosEquipos[regLista].idequipo;
                                idJug=datosEquipos[regLista].idjugador;

                                if(idConfig!=="" && idEq!=="" && idJug!==""){
                                    BD_solicitarDatos("INSERTAR_CAMPOS_ADICIONALES",[idConfig,idEq,idJug,valor,regInfocampos]);
                                }
                            }
                        }
    
                    }
                    

                    break;                
                default:
                    break;
            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 


    const buscarJugadoresActivos = (idEquipo) => {
        let nomFuncion="buscarJugadoresActivos";

        let respuesta=0;
        try{

            let temp=0;
            if(infoJugadoresEq.length>0){
                for(let i=0;i<infoJugadoresEq.length;i++){
                    if(infoJugadoresEq[i][0]===idEquipo){
                        if(infoJugadoresEq[i][4]==="1" && infoJugadoresEq[i][5]!=="5") temp++;
                    }
                }
            }

            respuesta=temp;


        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

        return respuesta;

    }

    const validarCamiseta = (numC) => {
        let nomFuncion="validarCamiseta";

        let resultado="";

        try{

            let i;

            for(i=0;i<infoJugadoresEq.length;i++){
                if(infoJugadoresEq[i][0]===infoJugador[eqSeleccionado][2] && numC===infoJugadoresEq[i][2] && infoJugadoresEq[i][1]!==infoJugador[eqSeleccionado][1]){
                    resultado="Número de camiseta ya existe, lo tiene " + infoJugadoresEq[i][3] + ". Verifique con el Administrador del Equipo.";
                    i=infoJugadoresEq.length;
                }
            }
           


        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  

        return resultado;
    }

    const generarListaEquipos = () => {
            let nomFuncion="generarListaEquipos"; 
    
            try{
    
                let tempCA={
                    regConfig:-1,
                    regCampos:-1,
                    tipo_campo:"",
                    min:"",
                    max:"",
                    obligatorio:"",
                    descripcion:"",
                    nombre:"",
                    valor:"",
                    ultValor:"",
                    indice:0,
                    error:""
                }

    
                let jugadores=[];
                let i;
    
                
                for(i=0;i<infoJugadorO.length;i++){

                    if(infoJugadorO[i].idjugador!==""){
                        let idJug=infoJugadorO[i].idjugador;
                        let idEq=infoJugadorO[i].idequipo;
                        let idTor=infoJugadorO[i].idtorneo;

                        let tempInfoJug=JSON.parse(JSON.stringify(infoJugadorO[i]));
                        tempInfoJug.camposAdicionales=[];
                        tempInfoJug.hayCamposSinInfo=false;
                        tempInfoJug.puedeModificar=true;

                        let fechaActual="";
                        if(infoUsuario.length>0) fechaActual=infoUsuario[0][12];

                    
                        let comp=operacionFechas(3,fechaActual,infoJugadorO[i].fecha_maxreg);
                        if(comp==="2") tempInfoJug.puedeModificar=false;


    
    
                        //ESPACIO QUE PROCESA EN EL CASO QUE TENGA CAMPOS ADICIONALES
                        if(infoCamposO.length>0){
                            tempInfoJug.hayCamposSinInfo=tieneCamposSinInfo(idTor,idEq,idJug);
    
                            for(let j=0;j<infoCamposO.length;j++){
                                
                                if(idTor===infoCamposO[j].idtorneo && idEq===infoCamposO[j].idequipo && idJug===infoCamposO[j].idjugador){
                                    let registro=Object.assign({} , tempCA);
    
                                    registro.regConfig=j;
                                    registro.tipo_campo=infoCamposO[j].tipo_campo;
                                    registro.min=infoCamposO[j].min;
                                    registro.max=infoCamposO[j].max;
                                    registro.obligatorio=infoCamposO[j].obligatorio;
                                    registro.descripcion=infoCamposO[j].descripcion;
                                    registro.nombre=infoCamposO[j].nombre;
        
                                    let minimo=valorNumero(registro.min);
                                    let maximo=valorNumero(registro.max);
    
                                    registro.regCampos=j;
                                    registro.valor=infoCamposO[j].valor;
                                    registro.ultValor=infoCamposO[j].valor;
                                    registro.indice=infoCamposO[j].indice;
                                        
        
                                    if(registro.obligatorio==="1"){
                                        registro.nombre= registro.nombre + "*";
                                    }
        
                                    if(registro.descripcion!=="" || registro.obligatorio===""){
                                        if(registro.descripcion!==""){
                                            if(registro.obligatorio==="1"){
                                                registro.descripcion=registro.descripcion + "\nCampo OBLIGATORIO";
                                            }
                                        }
                                    }
        
                                    if(registro.tipo_campo==="1" || registro.tipo_campo==="3"){ //Campo de tipo texto o texto numerico
                                        if(registro.valor.length>maximo) registro.valor= registro.valor.substring(0,maximo);
                                    }
                                    else if(registro.tipo_campo==="2"){ //Campo de tipo numero
                                        if(valorNumero(registro.valor)<minimo || valorNumero(registro.valor)>maximo) registro.valor="";
                                    }
                                    else if(registro.tipo_campo==="5"){ //Campo de tipo imagen
                                        
                                        if(registro.valor.includes("data:image/")) registro.valor= registro.valor;
                                        else {
                                            if(registro.valor!=="") registro.valor = ruta + registro.valor;
                                            else registro.valor="";
                                        } 
                                        
                                    }
        
                                    tempInfoJug.camposAdicionales.push(registro);
                                }
                                
    
                            }
                            
    
                        }
                        ////CAMPOS ADICIONALES HASTA ACA
    
    
                        jugadores.push(tempInfoJug);
                    }                
                }
    
                setDatosEquipos(jugadores);
    
            }catch(err){
                reportarError( nomClase, nomFuncion, "", err);
            }  
            
        }

        const tieneCamposSinInfo = (idTor, idEq, idJug) => {
            let nomFuncion="tieneCamposSinInfo";
            let resultado=false;
    
            try{
                if(infoCampos.length>0){
                    for(let i=0;i<infoCampos.length;i++){
                        if(infoCampos[i][7]===idTor && infoCampos[i][14]==="1"){
                            resultado=true;
                            if(infoCampos[i][2]===idEq && infoCampos[i][3]===idJug  && infoCampos[i][4]!==""){
                                resultado=false;
                            }
                        }
    
                        if(resultado){
                            i=infoCampos.length;
                        }
                    }
                }
    
    
            }catch(err){
                reportarError( nomClase, nomFuncion, "", err);
            } 
            return resultado;
        }
    


    const salir = () => {       
        //definirModulo(0, idModulo, params, modOrigen, paramOrigen);
        /*if(modOrigen===0 || paramOrigen===""){
            definirModulo(0,0,"",ID_MODULO_INFO_EQUIPO,idEquipo);     
        }
        else  definirModulo(0,0,"",modOrigen,paramOrigen); */

        definirModulo(0,idModulo,params,ID_MODULO_PRINCIPAL_JUGADOR,recuperarDatoLocal("idUser"));     
    }



    return (
        <>

            {isOpenImagen && (
                <ModalFull isOpen={isOpenImagen} closeModal={closeImagen} isCancelable={false} > 
                    <div><GestionImagen tipoImagen={tipoImagenSelUS} imgActual={imgActualSelUS} clickGenerico={clickGenerico} cortarImg={tipoImagenSelUS==="CAMPO_ADICIONAL" ? (false) : (true)} /></div>                
                </ModalFull>
            )}

            {isOpenCalendar && (
                <ModalFull isOpen={isOpenCalendar} closeModal={closeCalendar} isCancelable={true} tipoVentana="SMALL"> 
                    <div><Calendar onChange={(date) => clickGenerico("SELECCIONAR_FECHA",date)} value={selectedDate} maxDate={listaOrigenSel==="" ? (new Date()) : ("")} showNavigation={true} /></div>                
                </ModalFull>
            )} 

            {isOpenBusqueda && (
                <Modal isOpen={isOpenBusqueda} closeModal={closeBusqueda} titulo={"Modificar Teléfono"}  isCancelable={true} txtCancelar="" txtAceptar="" >
                <div style={{margin:"10px", minHeight:"300px", maxHeight:"500px", maxWidth:"400px", fontSize:"16px", color:C_uniformeGrisOscuro}}>                    
                    
                    <div>Escriba o copie el número de teléfono que desea asignar para el jugador. Mínimo 10 dígitos.</div>

                    {showAlertaSinTelefono && (
                        <div className='IP_entradas' style={{margin:"20px"}}></div>
                    )}

                    {!showAlertaSinTelefono && (!permiteSinTelefonoUS || (permiteSinTelefonoUS && !aceptoSinTelefonoUS)) && (
                        <div style={{margin:"20px"}}><input type="number" value={arrCampos[6].valor} className='IP_entradas' style={{width:"100%"}} placeholder="" onChange={(e) => validarCampo(e, "FILTRO_BUSQUEDA_TELEFONO", 14)}/></div>
                    )}

                    {permiteSinTelefonoUS && !showAlertaSinTelefono && aceptoSinTelefonoUS && (
                        <div style={{margin:"20px"}}><input type="text" value={arrCampos[6].valor} className='IP_entradas' style={{width:"100%"}} placeholder="" onChange={(e) => validarCampo(e, "FILTRO_BUSQUEDA_NOMBRE", 40)}/></div>
                    )}
                    

                    {permiteSinTelefonoUS && !aceptoSinTelefonoUS && (
                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"-10px", marginBottom:"10px", marginInline:"20px", cursor:"pointer"}} onClick={()=> clickGenerico("CLICK_INCLUIR_SINTELEFONO")}>
                            <div><img className="IM_imgIcono20" src={ic_entrar} alt=""/></div>
                            <div className="TX_letraPeq" style={{flex:"1", marginLeft:"10px", textAlign:"left"}}>{"Incliuir Jugador SIN registrar Teléfono"}</div>
                        </div>
                        
                    )}
                    {permiteSinTelefonoUS && showAlertaSinTelefono && (
                        <div style={{marginInline:"20px"}}>
                            <div style={{fontSize:"14px", color:C_colorTextoError}}>{"* Al ingresar un Jugador SIN número de teléfono, NO PODRÁ registrarse como jugador en MarcaGol y únicamente podrá consultar la información del torneo como Espectador."}</div>
                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                <label style={{display:"flex", alignItems:"center", flex:"1", justifyContent:"left", textAlign:"left", cursor:"pointer"}}>
                                    <input type="checkbox" name="opcionMenu" onChange={(e)=> setConfirmoSinTelefonoUS(e.target.checked)}/>
                                    <span style={{color:C_uniformeGrisOscuro, marginInline: "10px"}}>{"Estoy de acuerdo"}</span>
                                </label>
                                {confirmoSinTelefonoUS ? 
                                (<div className="BF_botonPrimaryFull" style={{margin:"0px",paddingInline:"0px"}} onClick={() => clickGenerico("ACEPTO_ALERTA_SINTELEFONO")}>Aceptar</div>)
                                : 
                                (<div className="BF_botonTransparenteFull" style={{margin:"0px",paddingInline:"0px"}} onClick={() => clickGenerico("CLICK_CANCELAR_SINTELEFONO")}>Cancelar</div>)
                                }
                                
                            </div>
                        </div>
                    )}
                    
                    
                    {datosEncontrados.length>0 && (<>
                        {(datosEncontrados.map((reg, index) => (<DinamicoElementosTorneo key={index} registro={index} numRegistro={index+1} tipoLista={"busqueda_jugadores"} datos={reg} definirModulo={clickGenerico} incluirLinea={false} />))  )}     
                    </>)} 

                    {datosEncontrados.length===0 && arrCampos[6].error!=="" && (<div style={{color:C_colorTextoSinInfo, whiteSpace:"pre-wrap", marginTop:"10px"}}>
                          {arrCampos[6].error}
                    </div>)} 
                    

                </div> 
                </Modal>
            )}

            {isOpenCambioPC && (
                <Modal isOpen={isOpenCambioPC} closeModal={closeCambioPC} titulo={nombreJug}  isCancelable={false} clickAceptar={() => clickGenerico("ACEPTAR_CAMBIAR_POSICION")} >
                <div style={{margin:"20px", minHeight:"300px", maxWidth:"300px"}}>                    
                    
                    <div>Define la posición y el número de camiseta con los que juegas en este equipo.</div>
                    <div style={{display:"flex", alignItems:"center", flexWrap:"nowrap", minWidth:"150px", maxWidth:"400px", marginTop:"30px"}}>
                        <div style={{flex:"1", marginRight:"10px", textAlign:"left"}}>Posición</div>
                        <div style={{width:"180px", backgroundColor:"white"}}>           
                        <Select     
                            styles={estilosSelect}
                            value={infoPosicion[posicionSel]}
                            formatOptionLabel={listaDosItems}
                            options={infoPosicion}
                            isSearchable={false}   
                            onChange={(e,index) => {clickGenerico("CAMBIAR_POSICION",e)}}            
                        />   
                        </div>   
                    </div>
                    <div style={{display:"flex", alignItems:"center", flexWrap:"nowrap", minWidth:"150px", maxWidth:"400px", marginTop:"20px"}}>
                        <div style={{flex:"1", marginRight:"10px", textAlign:"left"}}>Número Camiseta</div>
                        <input type="number" value={arrCampos[10].valor} className='IP_entradas' style={{width:"100px"}} placeholder="" onChange={(e) => validarCampo(e, "MD_CAMISETA", 3)}/>
                        
                    </div>
                    {arrCampos[10].error!=="" && (
                        <div style={{marginTop:"4px", marginBottom:"0px", textAlign:"center"}}>
                                    <span className="TX_error">{arrCampos[10].error}</span>
                        </div>
                    )}
                    

                </div> 
                </Modal>
            )}
            
            {isOpenFoto && (
                <ModalFull isOpen={isOpenFoto} closeModal={closeFoto} isCancelable={true} tipoVentana="SMALL"> 
                    <div style={{display:"flex"}}>
                    <div style={{flex:"1", flexShrink:"1", flexGrow:"1", minWidth:"300px", maxWidth:"500px", minHeight:"300px", maxHeight:"500px"}}>
                        <img src={srcFotoUS} className='IM_imgIcono250' style={{border:"1px solid gray"}}  />
                    </div>   
                    </div>                       
                </ModalFull>
            )} 
            

            {showMensaje && (ventanaMensaje)}


            <div className="CT_zonaFija">
                <div className="CT_encabezado">
                    <div className="CT_elementoEncabezado" onClick={salir}><img src={ic_salir} className="IM_imgIcono24" alt="" style={{padding: "8px", cursor:"pointer"}} />  </div>
                    <div className="CT_elementoEncabezado"><img className="IM_imgIcono28" height="36px" width="36px" src={ic_mi_informacion_blanco} alt="" /></div>
                    <div className="CT_elementoEncabezado"><p className="TX_unaSolaLinea">{"Mi Información"}</p></div>
                </div>
            </div>

            <div style={{paddingTop:"50px"}}>

                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", background:C_colorPrimaryDark, color:C_uniformeBlanco}}>
                    <img className="IM_imgRedondeada46" src={arrCampos[9].valor===ic_seleccionar_imagen ? ic_jugadorsinimagen : arrCampos[9].valor} alt="" style={{padding:"8px"}}/>
                    <div style={{flex:"1", fontSize:"14px", textAlign:"left" }}>
                        <div className="TX_unaSolaLinea2"><span style={{color:C_uniformeBlanco}}>{arrCampos[0].valor + " " + arrCampos[1].valor}</span></div>
                        <div className="TX_unaSolaLinea2"><span style={{color:C_colorAccent}}>{"USUARIO"}</span></div>
                    </div> 
                </div>                  
            
            </div>
                 
                          

            {!loading && esError===0 && (
                <div style={{margin: "10px", paddingBottom:"20px"}}>  
                    <div className="CT_contenedorTitulo" style={{marginBottom:"20px", cursor:"pointer"}} onClick={salir}>
                        <img className='IM_imgIcono28' src={ic_flecha_expandir} alt='' />   
                        <img className='IM_imgIcono28' src={ic_mi_informacion} alt='' style={{marginInline:"10px"}}/>   
                        <div style={{fontSize:"18px", whiteSpace:"pre", textAlign:"left"}}>{"Administra tus datos"}</div>             
                    </div>


                    <div style={{display:"flex", flexWrap:"wrap", gap:"20px", justifyContent:"center", alignItems:"start"}}>

                        <div style={{flex:"1", flexGrow:"1", flexShrink:"1", minWidth:"250px", maxWidth:"500px", alignItems:"center"}}>
                            
                            <div style={{display:"flex", flexGrow:"1", flexShrink:"1", minWidth:"250px", maxWidth:"500px", alignItems:"center"}}>
                                
                                <div style={{position:"relative", cursor:"pointer"}} onClick={() => {if(!estaCambiandoTelefonoUS) clickGenerico("CLICK_MODIFCAR_FOTO")} }>
                                    <img className="IM_imgRedondeada80" src={arrCampos[9].valor} alt="" style={{padding:"0px", zIndex:"1"}}/>
                                    <div style={{position:"absolute", bottom:"0px", left:"0px"}}>
                                        <img className="IM_imgIcono24" src={ic_tomarfoto_dark} alt="" style={{padding:"0px", zIndex:"2"}}/>
                                    </div>
                                </div>
                                
                                
                                <div style={{flex:"1", display:"block", justifyContent:"left", textAlign:"left"}}>
                                    <div style={{fontSize:"16px", color:"black"}}>Nombre*:</div>
                                    <div style={{display:"flex", alignItems:"center"}}>                                    
                                        <input type="text" value={arrCampos[0].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px"}} placeholder="" onChange={(e) => validarCampo(e, "NOMBRE", 40)} onBlur={(e) => clickGenerico("SALE_NOMBRE")} /> 
                                    </div>
                                    {arrCampos[0].error!==""  && (
                                        <div>
                                            <span className="TX_error">{arrCampos[0].error}</span>
                                        </div>
                                    )}

                                    <div style={{fontSize:"16px", color:"black", marginTop:"10px"}}>{"Apellido*:"}</div>
                                    <div style={{display:"flex"}}>                                    
                                        <input type="text" value={arrCampos[1].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px"}} placeholder="" onChange={(e) => validarCampo(e, "APELLIDO", 30)} onBlur={(e) => clickGenerico("SALE_APELLIDO")}/>  
                                    </div>
                                    {arrCampos[1].error!==""  && (
                                        <div>
                                            <span className="TX_error">{arrCampos[1].error}</span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div style={{marginTop:"10px", textAlign:"left"}}>
                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                                    <div style={{width:"150px"}}>No. Identificación*:</div>
                                    <input type="number" value={arrCampos[2].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "150px"}} placeholder="" onChange={(e) => validarCampo(e, "IDENTIFICACION", 14)} onBlur={(e) => clickGenerico("SALE_IDENTIFICACION")}/>   
                                </div>
                                {arrCampos[2].error!==""  && (
                                        <div>
                                            <span className="TX_error">{arrCampos[2].error}</span>
                                        </div>
                                )}

                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                    <div style={{width:"150px"}}>Teléfono Principal*:</div>
                                    <input type="number" value={arrCampos[3].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "150px"}} placeholder="" onChange={(e) => validarCampo(e, "TELEFONO", 14)} onBlur={(e) => clickGenerico("SALE_TELEFONO")}/>   
                                </div>
                                {arrCampos[3].error!==""  && (
                                        <div>
                                            <span className="TX_error">{arrCampos[3].error}</span>
                                        </div>
                                )}

                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                    <div style={{width:"150px"}}>Teléfono Alterno:</div>
                                    <input type="number" value={arrCampos[4].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "150px"}} placeholder="" onChange={(e) => validarCampo(e, "TELEFONO2", 14)} onBlur={(e) => clickGenerico("SALE_TELEFONO2")}/>   
                                </div>
                                {arrCampos[4].error!==""  && (
                                        <div>
                                            <span className="TX_error">{arrCampos[4].error}</span>
                                        </div>
                                )}

                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                    <div style={{width:"150px"}}>Fecha Nacimiento:</div>
                                    <div className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "150px", paddingLeft:"5px",  cursor:"pointer", display:"flex", alignItems:"center", fontSize:"14px"}} onClick={() => clickGenerico("SELECCIONAR_FECHA_NACIMIENTO")} >
                                                <span>{formatearFecha(arrCampos[5].valor,6)}</span>
                                    </div>                                    
                                    
                                </div>

                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                    <div style={{width:"150px"}}>Estatura(cm):</div>
                                    <input type="number" value={arrCampos[6].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "150px"}} placeholder="" onChange={(e) => validarCampo(e, "ESTATURA", 3)} onBlur={(e) => clickGenerico("SALE_ESTATURA")}/>   
                                </div>
                                {arrCampos[6].error!==""  && (
                                        <div>
                                            <span className="TX_error">{arrCampos[6].error}</span>
                                        </div>
                                )}

                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                    <div style={{width:"150px"}}>Peso(kg):</div>
                                    <input type="number" value={arrCampos[7].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "150px"}} placeholder="" onChange={(e) => validarCampo(e, "PESO", 3)} onBlur={(e) => clickGenerico("SALE_PESO")}/>   
                                </div>
                                {arrCampos[7].error!==""  && (
                                        <div>
                                            <span className="TX_error">{arrCampos[7].error}</span>
                                        </div>
                                )}
                                

                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                    <div style={{width:"90px"}}>Correo:</div>
                                    <div className="TX_sinInfo" style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px", display:"flex", alignItems:"center"}}><span>{arrCampos[8].valor}</span></div>
                                </div>

                                


                            </div>

                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center", marginBlock:"10px"}} onClick={() => definirModulo(0,ID_MODULO_PRINCIPAL_JUGADOR,idUser, ID_MODULO_GESTION_MICUENTA,idUser)}>
                                <img className="IM_imgIcono20" src={ic_manual_primary} alt=""/>
                                <div className="BF_botonTransparenteFull" style={{flex:"1", margin:"0px"}}>Cambiar CORREO o CONTRASEÑA</div>
                            </div>
                            
                            
                        </div>

                        
                        <div style={{flex:"1", display:"flex", flexWrap:"wrap", flexGrow:"1", flexShrink:"1", minWidth:"250px", maxWidth:"500px", gap:"20px"}}>
                            
                            {infoJugadorO.length>0 && (
                                <div className='BF_cajaAccent' style={{minWidth:"250px", maxWidth:"500px", flexGrow:"1", flexShrink:"1", }}>
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}> 
                                        <img className='IM_imgIcono28' style={{padding:"4px"}} src={ic_jugador_registro} alt='' /> 
                                        <p className='TX_tituloCaja' style={{flex:"1"}}>{"Equipos Activos Jugador"}</p> 
                                    </div>

                                    <div className='BF_cajaBlanca'>  
                                        
                                        {datosEquipos.length>0 && (<> 
                                            {(datosEquipos.map((reg, index) => (<DinamicoElementosTorneo key={index} registro={index} numRegistro={index+1} tipoLista={"EQUIPOS_INFO_JUGADOR"} datos={reg} definirModulo={clickGenerico} incluirLinea={true} fechaModificacionesActiva={reg.puedeModificar} />))  )}     
                                        </>)}                                          
                                    </div>
                                </div>
                            )}

                            {infoArbitroO.length>0 && (
                                <div className='BF_cajaAccent' style={{minWidth:"250px", maxWidth:"500px", flexGrow:"1", flexShrink:"1", }}>
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}> 
                                        <img className='IM_imgIcono28' style={{padding:"4px"}} src={ic_silbato_blanco} alt='' /> 
                                        <p className='TX_tituloCaja' style={{flex:"1"}}>{"Sedes Activas Arbitro"}</p> 
                                    </div>

                                    <div className='BF_cajaBlanca'>  
                                        
                                        {infoArbitroO.length>0 && (<> 
                                            {(infoArbitroO.map((reg, index) => (<DinamicoElementosTorneo key={index} registro={index} numRegistro={index+1} tipoLista={"ARBITRO_INFO_JUGADOR"} datos={reg} definirModulo={clickGenerico} incluirLinea={false} />))  )}     
                                        </>)}                                          
                                    </div>
                                </div>
                            )}
                            

                        </div>
                        

                        

                    </div>

                    


                </div>
            )}               
              
            

            {esError!==0 && <ShowError tipoError={esError} funcionCerrar={salir} />}

            {loading && <Loader tipo={"TOTAL"}/>}   
        </>
    );

};

export default Info_Jugador;