import { useEffect, useState } from 'react';
import { getDatos } from '../gestionDatos/getDatos';

import {   valorFloat, formatearPorcentaje, dividirLista, formatearDecimal, recuperarDatoLocal, valorLong, guardarDatoLocal, isFlagUpdate, superaTiempoActualizacion, presentarLog } from "../helpers/funciones";

import {registrarLogEventos, reportarError, presentarError, crearTabla, valorNumero} from '../helpers/funciones';
import Loader from '../recVisual/Loader';

import "../estilos/CT_Contenedor.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/TX_Textos.css";
import "../estilos/BF_BordesFondos.css";
import "../estilos/IP_Inputs.css";

import ic_uniforme_oscuro from '../recGraficos/ic_uniforme_oscuro.png';
import ic_uniforme_claro from '../recGraficos/ic_uniforme_claro.png';
import ic_torneo from '../recGraficos/ic_torneo_gris_w.png';
import ic_reloj from '../recGraficos/ic_reloj.png';
import ic_salir from '../recGraficos/ic_salir_w.png';
import ic_balon_autogol from '../recGraficos/ic_balon_autogol.png';
import ic_escudo_base_gris from '../recGraficos/ic_escudo_base_gris.png';
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_disparo_arco from '../recGraficos/ic_disparo_arco.png';
import ic_atajada from '../recGraficos/ic_atajada.png';
import ic_falta_cometida from '../recGraficos/ic_falta_cometida.png';
import ic_amarilla from '../recGraficos/ic_amarilla.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_tarjeta_azul from '../recGraficos/ic_tarjeta_azul.png';
import ic_equipo from '../recGraficos/ic_equipo.png';
import ic_cambiojugador from '../recGraficos/ic_cambiojugador.png';

import ic_balon_gol from '../recGraficos/ic_balon_gol.png';
import ic_asistencia from '../recGraficos/ic_asistencia.png';
import ic_disparo from '../recGraficos/ic_disparo.png';
import ic_porcentajetiempo from '../recGraficos/ic_porcentajetiempo.png';
import ic_arco from '../recGraficos/ic_arco.png';
import ic_falta_recibida from '../recGraficos/ic_falta_recibida.png';
import ic_fairplay from '../recGraficos/ic_fairplay.png';

import ic_fase_grupos from '../recGraficos/ic_fase_grupos.png';
import ic_fase_eliminacion from '../recGraficos/ic_fase_eliminacion.png';
import ic_entrar_gris from '../recGraficos/ic_entrar_gris.png';
import ic_comparar from '../recGraficos/ic_comparar.png';
import ic_ranking from '../recGraficos/ic_ranking.png';




import ShowError from '../recVisual/ShowError';
import DinamicoPartidosProgramados from '../gestionVisual/DinamicoPartidosProgramados';
import DE_InfoEstadisticasEquipo from '../gestionVisual/DE_InfoEstadisticasEquipo';
import DinamicoResumenBarra from '../gestionVisual/DinamicoResumenBarra';
import DinamicoResumenDisparos from '../gestionVisual/DinamicoResumenDisparos';
import DinamicoResumenEfectividad from '../gestionVisual/DinamicoResumenEfectividad';
import DinamicoRankingIndicador from '../gestionVisual/DinamicoRankingIndicador';
import DinamicoRankingIndicadores from '../gestionVisual/DinamicoRankingIndicadores';
import { C_colorAccent, C_colorPrimary, C_colorPrimaryDark, C_uniformeBlanco, C_uniformeGrisOscuro, C_uniformeNegro, C_uniformeRojo, ID_MODULO_DETALLE_EQUIPO, ID_MODULO_DETALLE_JUGADOR, ID_MODULO_DETALLE_TORNEO, SEPARADOR_PARAMETROS_BD } from '../constantes/generales';



const ruta=recuperarDatoLocal("rutaRecursos");
const nomClase="Detalle_Equipo_WB";
const idModulo=ID_MODULO_DETALLE_EQUIPO;

let idEquipo="";
//let idSede="";
let idTorneo="";

let paramsAjustado="";
//let paramsBase="";

let nombreEq="";
let escudoEq=ic_escudo_base_gris;
let uniforme1=ic_uniforme_claro;
let uniforme2=ic_uniforme_claro;
let tipo_torneo="1";
let tipo_registroinfo="1";
let escudoTorneo=ic_torneo;

let cantidadEq=-1;
let posicionEq=-1;

let numSel=0;
let textoPSel="Goles";
let textoSSel="Mayor Cantidad de Goles Realizados";
let imagenSel=ic_balon_gol;

let tieneTarjetaAzul=false;



let columnasASumar=[6,7,8,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,38];
let columnasOrdenar=[0,1,1,0,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,1,1,1];
let columnasOrdenarJug=[5,6,7,8,9,10,11,12,13,14,15,16,17,20];

let infoOrdenadaEquipos=[];
let infoOrdenadaJugadores = [];
let columnasSuma = [];
let columnasAvg = [];
let torneos=[];


var infoEquipoO = [];
var infoEquipo = [];
var infoTorneo = [];
function crearInfoEquipo(datos){    
    const nomFuncion="crearInfoEquipo";

    try{        
        let equipo = {
            idequipo:"",
            idsede:"",
            nombrelargo:"",
            nombrecorto:"",
            escudo:"",
            uniforme1:"",
            uniforme2:"",
            fotos:"",
            activo:"",
            idtorneo:"",
            nomtorneo:"",
            nomsede:"",
            tipo_torneo:"",
            tipo_registroinfo:"",
            escudotorneo:"",
            puntosGanador:"",
            tiene_tarjeta_azul:""
        }
    
        infoEquipoO = [];
        infoEquipo = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , equipo);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idequipo=datos[i][j];
                if(j===1) registro.idsede=datos[i][j];
                if(j===2) registro.nombrelargo=datos[i][j];
                if(j===3) registro.nombrecorto=datos[i][j];
                if(j===4) registro.escudo=datos[i][j];
                if(j===5) registro.uniforme1=datos[i][j];
                if(j===6) registro.uniforme2=datos[i][j];
                if(j===7) registro.fotos=datos[i][j];
                if(j===8) registro.activo=datos[i][j];
                if(j===9) registro.idtorneo=datos[i][j];
                if(j===10) registro.nomtorneo=datos[i][j];
                if(j===11) registro.nomsede=datos[i][j];
                if(j===12) registro.tipo_torneo=datos[i][j];
                if(j===13) registro.tipo_registroinfo=datos[i][j];
                if(j===14) registro.escudotorneo=datos[i][j];
                if(j===15) registro.puntosGanador=datos[i][j];
                if(j===16) registro.tiene_tarjeta_azul=datos[i][j];
            }
            infoEquipoO.push(registro);
            infoEquipo.push(datos[i]);

            if(i===0){
                let temp = [datos[i][9],datos[i][10],datos[i][11],datos[i][12],datos[i][13],datos[i][14]];
                infoTorneo.push(temp);

                //idSede=registro.idsede;
                idTorneo=registro.idtorneo;
                tipo_torneo=registro.tipo_torneo;
                tipo_registroinfo=registro.tipo_registroinfo;

                nombreEq=registro.nombrelargo;
                if(registro.escudo!=="") escudoEq= ruta + registro.escudo;
                else escudoEq=ic_escudo_base_gris;
                if(registro.uniforme1!=="") uniforme1= ruta + registro.uniforme1;
                else uniforme1=ic_uniforme_claro;
                if(registro.uniforme2!=="") uniforme2= ruta + registro.uniforme2;
                else uniforme2=ic_uniforme_claro;
                if(registro.escudotorneo!=="") escudoTorneo= ruta + registro.escudotorneo;
                else escudoTorneo=ic_torneo;
                if(registro.tiene_tarjeta_azul==="1") tieneTarjetaAzul=true;
                else tieneTarjetaAzul=false;
            }
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


var infoClasificacionO = [];
var infoClasificacion = [];
function crearInfoClasificacion(datos){    
    const nomFuncion="crearInfoClasificacion";

    try{        
        let clasificacion = {
            idtorneo:"",
            sininfo1:"",
            idequipo:"",
            nombrecorto:"",
            escudo:"",
            posicion:"",
            partidosjugados:"",
            golesafavor:"",
            golesencontra:"",
            goldiferencia:"",
            puntos:"",
            faltasrecibidas:"",
            faltascometidas:"",
            tarjetasamarillas:"",
            tarjetasrojas:"",
            fairplay:"",
            atajadas:"",
            disparosarco:"",
            disparosdentro:"",
            autogoles:"",
            asistencias:"",
            golesjugada:"",
            golestirolibre:"",
            golespenalti:"",
            promediojugadores:"",            
            promediocambios:"",
            tiempojugadot1:"",
            tiempojugadot2:"",
            tiempojugadototal:"",
            efectividaddisparo:"",
            efectividadatajada:"",
            tc_posicion:"",
            tc_partidosjugados:"",
            tc_golesafavor:"",
            tc_golesencontra:"",
            tc_goldiferencia:"",
            tc_puntos:"",
            tc_numgrupo:"",
            tarjetasazules:""
        }
    
        infoClasificacionO = [];
        infoClasificacion = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , clasificacion);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.sininfo1=datos[i][j];
                if(j===2) registro.idequipo=datos[i][j];
                if(j===3) registro.nombrecorto=datos[i][j];
                if(j===4) registro.escudo=datos[i][j];
                if(j===5) registro.posicion=datos[i][j];
                if(j===6) registro.partidosjugados=datos[i][j];
                if(j===7) registro.golesafavor=datos[i][j];
                if(j===8) registro.golesencontra=datos[i][j];
                if(j===9) registro.goldiferencia=datos[i][j];
                if(j===10) registro.puntos=datos[i][j];
                if(j===11) registro.faltasrecibidas=datos[i][j];
                if(j===12) registro.faltascometidas=datos[i][j];
                if(j===13) registro.tarjetasamarillas=datos[i][j];
                if(j===14) registro.tarjetasrojas=datos[i][j];
                if(j===15) registro.fairplay=datos[i][j];
                if(j===16) registro.atajadas=datos[i][j];
                if(j===17) registro.disparosarco=datos[i][j];
                if(j===18) registro.disparosdentro=datos[i][j];
                if(j===19) registro.autogoles=datos[i][j];
                if(j===20) registro.asistencias=datos[i][j];
                if(j===21) registro.golesjugada=datos[i][j];
                if(j===22) registro.golestirolibre=datos[i][j];
                if(j===23) registro.golespenalti=datos[i][j];
                if(j===24) registro.promediojugadores=datos[i][j];
                if(j===25) registro.promediocambios=datos[i][j];
                if(j===26) registro.tiempojugadot1=datos[i][j];
                if(j===27) registro.tiempojugadot2=datos[i][j];
                if(j===28) registro.tiempojugadototal=datos[i][j];
                if(j===29) registro.efectividaddisparo=datos[i][j];
                if(j===30) registro.efectividadatajada=datos[i][j];
                if(j===31) registro.tc_posicion=datos[i][j];
                if(j===32) registro.tc_partidosjugados=datos[i][j];
                if(j===33) registro.tc_golesafavor=datos[i][j];
                if(j===34) registro.tc_golesencontra=datos[i][j];
                if(j===35) registro.tc_goldiferencia=datos[i][j];
                if(j===36) registro.tc_puntos=datos[i][j];
                if(j===37) registro.tc_numgrupo=datos[i][j];
                if(j===38) registro.tarjetasazules=datos[i][j];
            }
            infoClasificacionO.push(registro);
            infoClasificacion.push(datos[i]);
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}

let partido = {
    idTorneo:"",
    nomTorneo:"",
    idPartido:"",
    numJornada:"",
    numCancha:"",
    idEquipo1:"",
    nomCortoEq1:"",
    imgEscudoEq1:"",
    idEquipo2:"",
    nomCortoEq2:"",
    imgEscudoEq2:"",
    nomArbitro1:"",
    nomArbitro2:"",
    fechaPartido:"",
    horaPartido:"",
    golesEq1:"",
    golesEq2:"",
    parte:"",
    tiempoActual:"",
    tiempoTotal:"",
    idEstado:"",
    nomEstado:"",
    fechaActual:"",
    horaActual:"",
    idJornada:"",
    estadoTiempo:"",
    fechaInicioTiempo:"",
    confirmoAsistencia:"",
    fechaConfirmo:"",
    tipoTorneo:"",
    tipoRegistroInfo:"",
    partidoActivo:"",
    idSede:"",
    idEstadoInfArbitro:"",
    imgArbitro1:"",
    calificacionPartido:"",
    penaltisEq1:"",
    penaltisEq2:"",
    tipoJornada:"",
    nomJornada:"",
    semFechainicial:"",
    semFechaFinal:"",
    esSemanaActual:"",
    resumenJornada:"",
    tipoJornada2:"",
    nomJornada2:"",
    idsemana:"",
    numsemana:"",
    semFechainicial2:"",
    semFechaFinal2:"",
    tipoJornadaEliminacion:"",
    idcopa:"",
    nombreCopa:"",
    ordenCopa:""
}
var infoPartidosO = [];
var infoPartidos = [];
function crearInfoPartidos(datos){   
    const nomFuncion="crearInfoPartido";

    try{       
    
        infoPartidosO = [];
        infoPartidos = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , partido);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idTorneo=datos[i][j];
                if(j===1) registro.nomTorneo=datos[i][j];
                if(j===2) registro.idPartido=datos[i][j];
                if(j===3) registro.numJornada=datos[i][j];
                if(j===4) registro.numCancha=datos[i][j];
                if(j===5) registro.idEquipo1=datos[i][j];
                if(j===6) registro.nomCortoEq1=datos[i][j];
                if(j===7) registro.imgEscudoEq1=datos[i][j];
                if(j===8) registro.idEquipo2=datos[i][j];
                if(j===9) registro.nomCortoEq2=datos[i][j];
                if(j===10) registro.imgEscudoEq2=datos[i][j];
                if(j===11) registro.nomArbitro1=datos[i][j];
                if(j===12) registro.nomArbitro2=datos[i][j];
                if(j===13) registro.fechaPartido=datos[i][j];
                if(j===14) registro.horaPartido=datos[i][j];
                if(j===15) registro.golesEq1=datos[i][j];
                if(j===16) registro.golesEq2=datos[i][j];
                if(j===17) registro.parte=datos[i][j];
                if(j===18) registro.tiempoActual=datos[i][j];
                if(j===19) registro.tiempoTotal=datos[i][j];
                if(j===20) registro.idEstado=datos[i][j];
                if(j===21) registro.nomEstado=datos[i][j];
                if(j===22) registro.fechaActual=datos[i][j];
                if(j===23) registro.horaActual=datos[i][j];
                if(j===24) registro.idJornada=datos[i][j];
                if(j===25) registro.estadoTiempo=datos[i][j];
                if(j===26) registro.fechaInicioTiempo=datos[i][j];
                if(j===27) registro.confirmoAsistencia=datos[i][j];
                if(j===28) registro.fechaConfirmo=datos[i][j];
                if(j===29) registro.tipoTorneo=datos[i][j];
                if(j===30) registro.tipoRegistroInfo=datos[i][j];
                if(j===31) registro.partidoActivo=datos[i][j];
                if(j===32) registro.idSede=datos[i][j];
                if(j===33) registro.idEstadoInfArbitro=datos[i][j];
                if(j===34) registro.imgArbitro1=datos[i][j];
                if(j===35) registro.calificacionPartido=datos[i][j];
                if(j===36) registro.penaltisEq1=datos[i][j];
                if(j===37) registro.penaltisEq2=datos[i][j];
                if(j===38) registro.tipoJornada=datos[i][j];
                if(j===39) registro.nomJornada=datos[i][j];
                if(j===40) registro.semFechainicial=datos[i][j];
                if(j===41) registro.semFechaFinal=datos[i][j];
                if(j===42) registro.esSemanaActual=datos[i][j];
                if(j===43) registro.resumenJornada=datos[i][j];
                if(j===44) registro.tipoJornada2=datos[i][j];
                if(j===45) registro.nomJornada2=datos[i][j];
                if(j===46) registro.idsemana=datos[i][j];
                if(j===47) registro.numsemana=datos[i][j];
                if(j===48) registro.semFechainicial2=datos[i][j];
                if(j===49) registro.semFechaFinal2=datos[i][j];
                if(j===50) registro.tipoJornadaEliminacion=datos[i][j];
                if(j===51) registro.idcopa=datos[i][j];
                if(j===52) registro.nombreCopa=datos[i][j];
                if(j===53) registro.ordenCopa=datos[i][j];
            }
            infoPartidosO.push(registro);
            infoPartidos.push(datos[i]);
         
        }


    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}

var infoJugadoresO = [];
var infoJugadores = [];
function crearInfoJugadores(datos){   
    const nomFuncion="crearInfoJugadores";

    try{
        let jugador = {
            idjugador:"",
            nombre:"",
            idposicion:"",
            nom_posicion:"",
            num_camiseta:"",
            foto:""
        }
    
        infoJugadoresO = [];
        infoJugadores = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idjugador=datos[i][j];
                if(j===1) registro.nombre=datos[i][j];
                if(j===2) registro.idposicion=datos[i][j];
                if(j===3) registro.nom_posicion=datos[i][j];
                if(j===4) registro.num_camiseta=datos[i][j];
                if(j===5) registro.foto=datos[i][j];
            }
            infoJugadoresO.push(registro);
            infoJugadores.push(datos[i]);
        }


    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }      

}


var infoEstadisticasO = [];
var infoEstadisticas = [];
function crearInfoEstadisticas(datos){    
    const nomFuncion="crearInfoEstadisticas";

    try{
        let estadistica = {
            idtorneo:"",
            idjugador:"",
            nom_jugador:"",
            nom_posicion:"",
            pjugados:"",
            goles:"",
            autogoles:"",
            asistencias:"",
            atajadas:"",
            disparosarco:"",
            disparosdentro:"",
            faltasrecibidas:"",
            faltascometidas:"",
            tarjetasamarillas:"",
            tarjetasrojas:"",
            seg_jugadototal:"",
            participacion_goles:"",
            efectividad:"",
            foto:"",
            idequipo:"",
            tarjetasazules:""
        }
    
        infoEstadisticasO = [];
        infoEstadisticas = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , estadistica);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.idjugador=datos[i][j];
                if(j===2) registro.nom_jugador=datos[i][j];
                if(j===3) registro.nom_posicion=datos[i][j];
                if(j===4) registro.pjugados=datos[i][j];
                if(j===5) registro.goles=datos[i][j];
                if(j===6) registro.autogoles=datos[i][j];
                if(j===7) registro.asistencias=datos[i][j];
                if(j===8) registro.atajadas=datos[i][j];
                if(j===9) registro.disparosarco=datos[i][j];
                if(j===10) registro.disparosdentro=datos[i][j];
                if(j===11) registro.faltasrecibidas=datos[i][j];
                if(j===12) registro.faltascometidas=datos[i][j];
                if(j===13) registro.tarjetasamarillas=datos[i][j];
                if(j===14) registro.tarjetasrojas=datos[i][j];
                if(j===15) registro.seg_jugadototal=datos[i][j];
                if(j===16) registro.participacion_goles=datos[i][j];
                if(j===17) registro.efectividad=datos[i][j];
                if(j===18) registro.foto=datos[i][j];
                if(j===19) registro.idequipo=datos[i][j];
                if(j===20) registro.tarjetasazules=datos[i][j];
            }
            infoEstadisticasO.push(registro);
            infoEstadisticas.push(datos[i]);
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    } 

    
}

var infoEquiposGrupoO = [];
var infoEquiposGrupo = [];
function crearInfoEquiposGrupo(datos){    
    const nomFuncion="crearInfoEquiposGrupo";

    try{        
        let grupo = {
            idtorneo:"",
            idequipo:"",
            nombrecorto:"",
            escudo:""
        }
    
        infoEquiposGrupoO = [];
        infoEquiposGrupo = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , grupo);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.idequipo=datos[i][j];
                if(j===2) registro.nombrecorto=datos[i][j];
                if(j===3) registro.escudo=datos[i][j];
            }
            infoEquiposGrupoO.push(registro);
            infoEquiposGrupo.push(datos[i]);
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}



function ordenarInfomacionEquipos(){
    const nomFuncion="ordenarInfomacionEquipos";

    try{

        infoOrdenadaEquipos=[];
        columnasSuma=[];
        columnasAvg=[];

        let i,j;
        let suma=0;
        let promedio=0;
        let contTieneInfo=0;

        if(infoClasificacion.length>0) {
            let id = "";
            let cont = 0;
            let tempTorneos = dividirLista("","|",infoClasificacion.length);

            for (i = 0; i < infoClasificacion.length; i++) {
                if (id!==infoClasificacion[i][0]) {
                    id = infoClasificacion[i][0];
                    tempTorneos[cont] = infoClasificacion[i][0];
                    cont++;
                }
            }


            if (cont > 0) {
                torneos = dividirLista("","|",cont);

                for (i = 0; i < tempTorneos.length; i++) {
                    if (tempTorneos[i]!==("")) torneos[i] = tempTorneos[i];
                    else i = tempTorneos.length;
                }

                //columnasSuma = new int[cont][columnasOrdenar.length];
                //columnasAvg = new float[cont][columnasOrdenar.length];

                let tempColumnasSuma=dividirLista("","|",columnasOrdenar.length);
                let tempColumnasAvg=dividirLista("","|",columnasOrdenar.length);

                for (i = 0; i < columnasASumar.length; i++) {
                    let col = columnasASumar[i];
                    let datosTemp = [];
                    suma = 0;
                    promedio = 0;
                    id = "";
                    let contTorneos = 0;
                    //let contEquipos = 0;
                    let partJugados=0;
                    contTieneInfo=0;

                    //tempColumnasSuma=dividirLista("","|",columnasOrdenar.length);
                   //tempColumnasAvg=dividirLista("","|",columnasOrdenar.length);

                    for (j = 0; j < infoClasificacion.length; j++) {
                        id = infoClasificacion[j][0];
                        if (id!==(torneos[contTorneos])) {
                            if (contTieneInfo > 0)
                                promedio = (suma / contTieneInfo);
                            else promedio = 0;

                            tempColumnasSuma[i] = suma;
                            tempColumnasAvg[i] = promedio;

                            //columnasSuma[contTorneos][i] = suma;
                            //columnasAvg[contTorneos][i] = promedio;
                            suma = 0;
                            promedio = 0;
                            contTorneos++;
                            contTieneInfo = 0;
                        }

                        let valor=valorNumero(infoClasificacion[j][col]);
                        datosTemp.push(valor);
                        suma = suma + valor;
                        //contEquipos++;
                        partJugados=valorNumero(infoClasificacion[j][6]);
                        if(partJugados>0) contTieneInfo++;
                    }

                    if (contTieneInfo > 0) promedio = ( suma / contTieneInfo);
                    else promedio = 0;

                    tempColumnasSuma[i] = suma;
                    tempColumnasAvg[i] = promedio;
                    //columnasSuma[contTorneos][i] = suma;
                    //columnasAvg[contTorneos][i] = promedio;

                    

                    if (columnasOrdenar[i] === 1) {
                        let datosTemp2 = organizarListaEquipos(datosTemp);
                        infoOrdenadaEquipos.push(datosTemp2);
                    }
                    else{    
                        infoOrdenadaEquipos.push(datosTemp);
                    } 

                }

                columnasSuma.push(tempColumnasSuma);
                columnasAvg.push(tempColumnasAvg);
            }
        }



    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }

}

function organizarListaEquipos(list){
    const nomFuncion="organizarListaEquipos";

    let posiciones=[];

    try{
        let k, i, j;
        for(k=0;k<list.length;k++){
            posiciones.push(k);
        }

        for(i =0; i< list.length; i++){
            let sorted = true; // asumo que para la iteración i el listado es ordenado,
            for(j=0; j< list.length - i - 1; j++){ // en cada iteración los elementos desde la posición (length-i) estan ordenados, por lo tanto solo recorro hasta esa posición
                let idt1=valorNumero(infoClasificacion[j][0]);
                let idt2=valorNumero(infoClasificacion[j+1][0]);
                if(idt1>idt2){
                    let temp = list[j];
                    list[j] = list[j+1];
                    list[j+1] = temp;
                    sorted = false;

                    temp=posiciones[j];
                    posiciones[j]=posiciones[j+1];
                    posiciones[j+1]=temp;
                }
                else if(idt1===idt2){
                    if(list[j] < list[j+1]){
                        let temp = list[j];
                        list[j] = list[j+1];
                        list[j+1] = temp;
                        sorted = false;

                        temp=posiciones[j];
                        posiciones[j]=posiciones[j+1];
                        posiciones[j+1]=temp;
                    }
                    else if(list[j] === list[j+1]){
                        let partJugados=valorNumero(infoClasificacion[posiciones[j]][6]);
                        let partJugados2=valorNumero(infoClasificacion[posiciones[j+1]][6]);
                        let tempNum=0;
                        let tempNum2=0;
                        if(partJugados>0) tempNum = ( list[j] / partJugados);
                        if(partJugados2>0) tempNum2 = ( list[j + 1] / partJugados2);

                        if(tempNum<tempNum2){
                            let temp = list[j];
                            list[j] = list[j+1];
                            list[j+1] = temp;
                            sorted = false;

                            temp=posiciones[j];
                            posiciones[j]=posiciones[j+1];
                            posiciones[j+1]=temp;
                        }
                        else if(tempNum===tempNum2){
                            if(partJugados<partJugados2){
                                let temp = list[j];
                                list[j] = list[j+1];
                                list[j+1] = temp;
                                sorted = false;

                                temp=posiciones[j];
                                posiciones[j]=posiciones[j+1];
                                posiciones[j+1]=temp;
                            }
                        }

                    }
                }

            }
            if(sorted){
                return posiciones;
            }
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }

    return posiciones;
}


function ordenarInfomacionJugadores(){
    const nomFuncion="ordenarInfomacionJugadores";

    try{
        infoOrdenadaJugadores=[];

        let i,j;

        for(i=0;i<columnasOrdenarJug.length;i++){
            let col=columnasOrdenarJug[i];
            let datosTemp = []; // =new int [infoEstadisticas.length];
            for(j=0;j<infoEstadisticas.length;j++){
                datosTemp.push(valorNumero(infoEstadisticas[j][col]));
            }

            let datosTemp2=organizarListaJugadores(datosTemp);

            infoOrdenadaJugadores.push(datosTemp2);

        }

    

    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

}

function organizarListaJugadores(list){
    const nomFuncion="organizarListaJugadores";
    
    let posiciones=[];

    try{
        let i,j,k;
        for(k=0;k<list.length;k++){
            posiciones.push(k);
        }

        for(i =0; i< list.length; i++){
            let sorted = true; // asumo que para la iteración i el listado es ordenado,
            for(j =0; j< list.length - i - 1; j++){ // en cada iteración los elementos desde la posición (length-i) estan ordenados, por lo tanto solo recorro hasta esa posición
                let idt1=valorNumero(infoEstadisticas[j][0]);
                let idt2=valorNumero(infoEstadisticas[j+1][0]);
                if(idt1>idt2){
                    let temp = list[j];
                    list[j] = list[j+1];
                    list[j+1] = temp;
                    sorted = false;

                    temp=posiciones[j];
                    posiciones[j]=posiciones[j+1];
                    posiciones[j+1]=temp;
                }
                else if(idt1===idt2){
                    if(list[j] < list[j+1]){
                        let temp = list[j];
                        list[j] = list[j+1];
                        list[j+1] = temp;
                        sorted = false;

                        temp=posiciones[j];
                        posiciones[j]=posiciones[j+1];
                        posiciones[j+1]=temp;
                    }
                    else if(list[j] === list[j+1]){
                        let partJugados=valorNumero(infoEstadisticas[posiciones[j]][4]);
                        let partJugados2=valorNumero(infoEstadisticas[posiciones[j+1]][4]);
                        let tempNum=0;
                        let tempNum2=0;
                        if(partJugados>0) tempNum = (list[j] / partJugados);
                        if(partJugados2>0) tempNum2 = (list[j + 1] / partJugados2);

                        if(tempNum<tempNum2){
                            let temp = list[j];
                            list[j] = list[j+1];
                            list[j+1] = temp;
                            sorted = false;

                            temp=posiciones[j];
                            posiciones[j]=posiciones[j+1];
                            posiciones[j+1]=temp;
                        }
                        else if(tempNum===tempNum2){
                            if(partJugados<partJugados2){
                                let temp = list[j];
                                list[j] = list[j+1];
                                list[j+1] = temp;
                                sorted = false;

                                temp=posiciones[j];
                                posiciones[j]=posiciones[j+1];
                                posiciones[j+1]=temp;
                            }
                        }

                    }
                }


            }
            if(sorted){
                return posiciones;
            }
        }

    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

    return posiciones;
}






const Detalle_Equipo = ({params="", modOrigen, paramOrigen, definirModulo}) => {
    const [esError, setEsError] = useState(0);
    //const [data, setData] = useState(false);
    const [pestana, setPestana] = useState("0");
    const [loading, setLoading] = useState(false);
    const [indicadorSel, setIndicadorSel] = useState(1);

  
    var respuesta = {
        datos: "",
        exito: false,
        conectado: false,
        hayDatos: false,
        mensaje: ''
    }

    useEffect(() => {
        const nomFuncion="useEffect";

        try{ 

            //let ultParametros=recuperarDatoLocal("ultParametros" + idModulo);

            paramsAjustado=params;

            if(params!==""){
                let actualizar=false;
                let info=dividirLista(params,"#",5);
                idEquipo=info[0];
                /*idEquipo=ultParametros;

                if(idEquipo==="" || idEquipo!==info[0]){
                    actualizar=true;
                    guardarDatoLocal("ultParametros" + idModulo,info[0]);
                    idEquipo=info[0];
                }  */

                if(!actualizar) {
                    actualizar= (isFlagUpdate() || superaTiempoActualizacion(idModulo + idEquipo));
                }


                if(info[1]!=="" && info[1]!==pestana){
                    paramsAjustado=info[0] + "#" + info[1];
                    setPestana(info[1]); 
                }  
                else {
                    paramsAjustado=info[0] + "#" + "1";
                    setPestana("1"); 
                }

                //paramsBase=paramsAjustado;

                if(actualizar){
                    BD_solicitarDatos("INFO_INICIAL",[]);      
                }
                else{
                    if(pestana==="") setPestana("1");

                    presentarLog(nomClase + " - RECUPERA_DATOS"); 
                    let datosModulo=recuperarDatoLocal("datos" + idModulo + idEquipo);

                    if(datosModulo!==""){
                        crearInfoEquipo(crearTabla(datosModulo,"1"));
                        crearInfoClasificacion(crearTabla(datosModulo,"2"));
                        crearInfoPartidos(crearTabla(datosModulo,"3"));
                        crearInfoJugadores(crearTabla(datosModulo,"4"));
                        crearInfoEstadisticas(crearTabla(datosModulo,"5"));
                        crearInfoEquiposGrupo(crearTabla(datosModulo,"6"));

                        if(infoClasificacion.length>0) ordenarInfomacionEquipos();
                        if(infoEstadisticas.length>0) ordenarInfomacionJugadores();  

                        numSel=0;
                        textoPSel="Goles";
                        textoSSel="Mayor Cantidad de Goles Realizados";
                        imagenSel=ic_balon_gol;
                        setIndicadorSel(1);         

                        registrarLogEventos(idModulo, "1", idEquipo,"0" );              
                    }  
                    else BD_solicitarDatos("INFO_INICIAL",[]);  
                }
                
            }
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }        

    }, [params]);

    if(params==="") return null;

    const BD_solicitarDatos = (tipoDatos, params) => {
        let nomFuncion="BD_solicitarDatos"; 

        try{
            let actualizar=true;
            let SEP=SEPARADOR_PARAMETROS_BD;
            let paramQuery="";
            let tipoConsulta="";
            let funcion="";
            let consulta="";
            let zonaHoraria="0";
            let mostrarError=true;
            let mostrarProcesando=true;

            let codigoFinal="";
            
            if(actualizar){                
                
                if(tipoDatos==="INFO_INICIAL"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleEquipo";
                    consulta="INFO_DETALLE_EQUIPO";
                    
                    paramQuery= idEquipo;
                } 
                
                if(mostrarProcesando) setLoading(true);

                getDatos()
                .get(tipoConsulta,funcion,consulta,paramQuery,zonaHoraria)
                .then((res) => {
                    nomFuncion="BD_solicitarDatos.respuesta"; 

                    try{
                        respuesta=res;
                        if (respuesta.exito) {
                            if(tipoDatos==="INFO_INICIAL"){
                                presentarLog(nomClase + " - SOLICITA_DATOS"); 

                                //guardarDatoLocal("ultAcceso" + idModulo,Date.now());
                                guardarDatoLocal("ultAcceso" + idModulo + idEquipo,Date.now());
                                guardarDatoLocal("datos" + idModulo + idEquipo, respuesta.datos);

                                crearInfoEquipo(crearTabla(respuesta.datos,"1"));
                                crearInfoClasificacion(crearTabla(respuesta.datos,"2"));
                                crearInfoPartidos(crearTabla(respuesta.datos,"3"));
                                crearInfoJugadores(crearTabla(respuesta.datos,"4"));
                                crearInfoEstadisticas(crearTabla(respuesta.datos,"5"));
                                crearInfoEquiposGrupo(crearTabla(respuesta.datos,"6"));

                                if(infoClasificacion.length>0) ordenarInfomacionEquipos();
                                if(infoEstadisticas.length>0) ordenarInfomacionJugadores();

                            
                                if(pestana===""){
                                    setPestana("1");
                                }                
                                

                                numSel=0;
                                textoPSel="Goles";
                                textoSSel="Mayor Cantidad de Goles Realizados";
                                imagenSel=ic_balon_gol;
                                setIndicadorSel(1);
                                            
        
                                registrarLogEventos(idModulo, "1", idEquipo,"1" );
                            }                          
            
                        } else {   
                            //setData(false);
                            if(respuesta.conectado){
                                reportarError( nomClase, nomFuncion, respuesta.mensaje, "");
                                setEsError(1);
                            } 
                            else{    
                                presentarError(nomClase,nomFuncion,respuesta.mensaje,"");                        
                                setEsError(2);
                            } 
                        } 

                    }catch(err){
                        reportarError( nomClase, nomFuncion, "", err);
                    } 
                    
        
                    setLoading(false);
                    
                });
            }
            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 
    

    function presentarPestana(){ 
        let nomFuncion="presentarPestana";       
        
        try{
        
            if((pestana==="1")) {
                nomFuncion="presentarPestana.1";                 
                
                return(
                    <div  style={{maxWidth:"800px"}}>
                        {incluirTorneo()}                   

                        {incluirInfoTC()}

                        <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"500px", marginTop:"10px", marginBottom:"10px"}}>
                            <p className='TX_tituloCaja'>{"Partidos"}</p>
                            <div className='BF_cajaBlanca'>                                     
                                {incluirInfoPartidos()}                                           
                            </div>
                        </div>  

                    </div>            
                );
            }            
            else if((pestana==="2")) {
                nomFuncion="presentarPestana.2";

                let datosJugadores=[];
                let i;
                let ultimaPos="";

                for (i = 0; i < infoJugadoresO.length; i++) {
                    let posicion="";

                    if (ultimaPos!==(infoJugadoresO[i].nom_posicion)) {
                        posicion=infoJugadoresO[i].nom_posicion;
                        ultimaPos = posicion;
                    }
                    let imagen=ic_jugadorsinimagen;
                    if(infoJugadoresO[i].foto!=="") imagen=ruta + infoJugadoresO[i].foto;

                    datosJugadores.push([imagen,infoJugadoresO[i].nombre,infoJugadoresO[i].num_camiseta, posicion, infoJugadoresO[i].idjugador]);
                
                }
                
                return(
                    <div style={{minWidth:"250px", maxWidth:"500px"}} >
                        {datosJugadores.map((el, index) => (
                            <div key={index}>
                                {el[3]!=="" && (<div style={{textAlign:"left", fontSize:"16px", fontWeight:"bold", color:C_uniformeNegro, margin:"10px"}}>{el[3]}</div>)}
                                
                                <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginLeft:"10px", marginRight:"10px", cursor:"pointer"}} onClick={(e) => clickJugador(e, el[4])}>
                                        <img className='IM_imgRedondeada36' src={el[0]} alt="" style={{marginLeft:"10px", marginRight:"10px"}}/>
                                        <span className='TX_unaSolaLinea2' style={{flex:"1", fontSize:"16px", textAlign:"left", color:C_uniformeGrisOscuro}}>{el[1]}</span>
                                        <div className='IM_imagenConTexto'>
                                            <img className="IM_camiseta" src={ic_uniforme_oscuro} alt=""  />
                                            <span className='IM_numeroCamiseta'>{el[2]}</span>
                                        </div>
                                </div>
                                <div className='BF_lineaInferior'></div>
                            </div>
                        ))}             
                    </div>             
                );            
                
            }     
            else if((pestana==="3")) {
                nomFuncion="presentarPestana.3";

                let puntosGanador="3";
                if(infoEquipoO[0].puntosGanador!=="") puntosGanador=infoEquipoO[0].puntosGanador;
            
                return(
                <div  style={{maxWidth:"1800px"}}>                
                    {incluirTorneo()} 

                    <DE_InfoEstadisticasEquipo idEquipo={idEquipo} infoClasificacion={infoClasificacion} tipo_registroinfo={tipo_registroinfo} puntosGanador={puntosGanador} jugadoresActivos={infoJugadores.length} tieneTarjetaAzul={tieneTarjetaAzul}/>
                </div>
                );
            }         
            else if((pestana==="4")) {
                nomFuncion="presentarPestana.4";
                let golesAnotados1="0", golesAnotados2="0";
                let golesRecibidos1="0", golesRecibidos2="0";
                let asistencias1="0", asistencias2="0";
                let disparosT1="0", disparosT2="0";
                let datosDisparos = ["0","0","0","0"];
                let datosEfectividad=dividirLista("","|",8);
                let atajadas1="0", atajadas2="0";
                let datosPorteria=dividirLista("","|",8);
                let faltasReibidas1="0", faltasReibidas2="0";
                let faltasCometidas1="0", faltasCometidas2="0";
                let tAmarillas1="0", tAmarillas2="0";
                let tAzules1="0", tAzules2="0";
                let tRojas1="0", tRojas2="0";
                let numJugadores1="0", numJugadores2="0";
                let numCambios1="0", numCambios2="0";

                if(infoClasificacion.length){
                    let i;
                    let indice=0;
                    let indice2=0;
                    let sinDatos=true;

                    for (i = 0; i < infoClasificacion.length; i++) {
                        if (infoClasificacion[i][0]===(idTorneo) && infoClasificacion[i][2]===(idEquipo)) {
                            sinDatos = false;
                            indice = i;
                            i = infoClasificacion.length;
                        }
                    }

                    for (i = 0; i < torneos.length; i++) {
                        if (torneos[i]===(idTorneo)) {
                            indice2 = i;
                            i = torneos.length;
                        }
                    }

                        if(!sinDatos){

                            golesAnotados1=infoClasificacion[indice][7];
                            golesAnotados2=formatearDecimal(columnasAvg[indice2][1]);

                            golesRecibidos1=infoClasificacion[indice][8];
                            golesRecibidos2=formatearDecimal(columnasAvg[indice2][2]);

                            if(tipo_registroinfo===("2")){
                                asistencias1=infoClasificacion[indice][20];
                                asistencias2=formatearDecimal(columnasAvg[indice2][13]);

                                disparosT1=infoClasificacion[indice][17];
                                disparosT2=formatearDecimal(columnasAvg[indice2][10]);

                                datosDisparos[0]="" + valorNumero(infoClasificacion[indice][17]) - valorNumero(infoClasificacion[indice][18]);
                                datosDisparos[1]=formatearDecimal((columnasAvg[indice2][10] - columnasAvg[indice2][11]));
                                datosDisparos[2]=infoClasificacion[indice][18];
                                datosDisparos[3]=formatearDecimal(columnasAvg[indice2][11]);

                                datosEfectividad[0]="Efectividad de Disparo";
                                datosEfectividad[1]="Goles/Disparos al Arco";
                                let tempFloat1=valorFloat(infoClasificacion[indice][7]);
                                let tempFloat2=valorFloat(infoClasificacion[indice][18]);
                                let tempNum=0;
                                if(tempFloat2>0) tempNum=(tempFloat1/tempFloat2)*100;
                                datosEfectividad[4]=formatearPorcentaje(tempNum);
                                datosEfectividad[6]=formatearDecimal(tempFloat1) + "/" + formatearDecimal(tempFloat2);

                                tempFloat1=columnasAvg[indice2][1];
                                tempFloat2=columnasAvg[indice2][11];
                                tempNum=0;
                                if(tempFloat2>0) tempNum=(tempFloat1/tempFloat2)*100;
                                datosEfectividad[5]=formatearPorcentaje(tempNum);
                                datosEfectividad[7]=formatearDecimal(tempFloat1) + "/" + formatearDecimal(tempFloat2);
    
                                atajadas1=infoClasificacion[indice][16];
                                atajadas2=formatearDecimal(columnasAvg[indice2][9]);

                                datosPorteria[0]="Efectividad Porteria";
                                datosPorteria[1]="Atajadas/Disparos Recibidos";
                                tempFloat1=valorFloat(infoClasificacion[indice][8]);
                                tempFloat2=valorFloat(infoClasificacion[indice][16]);
                                tempNum=0;
                                if((tempFloat1+tempFloat2)>0) tempNum=(tempFloat2/(tempFloat1+tempFloat2))*100;
                                datosPorteria[4]=formatearPorcentaje(tempNum);
                                datosPorteria[6]=formatearDecimal(tempFloat2) + "/" + formatearDecimal((tempFloat1+tempFloat2));

                                tempFloat1=columnasAvg[indice2][2];
                                tempFloat2=columnasAvg[indice2][9];
                                tempNum=0;
                                if((tempFloat1+tempFloat2)>0) tempNum=(tempFloat2/(tempFloat1+tempFloat2))*100;
                                datosPorteria[5]=formatearPorcentaje(tempNum);
                                datosPorteria[7]=formatearDecimal(tempFloat2) + "/" + formatearDecimal((tempFloat1+tempFloat2));
                    
                            }

                            if(tipo_registroinfo===("2")){
                                faltasReibidas1=infoClasificacion[indice][11];
                                faltasReibidas2=formatearDecimal(columnasAvg[indice2][4]);

                                faltasCometidas1=infoClasificacion[indice][12];
                                faltasCometidas2=formatearDecimal(columnasAvg[indice2][5]);                            
                            }

                            tAmarillas1=infoClasificacion[indice][13];
                            tAmarillas2=formatearDecimal(columnasAvg[indice2][6]);

                            tAzules1=infoClasificacion[indice][38];
                            tAzules2=formatearDecimal(columnasAvg[indice2][24]);

                            tRojas1=infoClasificacion[indice][14];
                            tRojas2=formatearDecimal(columnasAvg[indice2][7]); 

                            numJugadores1=formatearDecimal(valorFloat(infoClasificacion[indice][24])/100);
                            numJugadores2=formatearDecimal(columnasAvg[indice2][17]/100); 

                            numCambios1=formatearDecimal(valorFloat(infoClasificacion[indice][25])/100);
                            numCambios2=formatearDecimal(columnasAvg[indice2][18]/100); 

                        }


                }
            
                return(
                    <>
                        <div className="CT_contenedorTitulo" style={{marginBottom:"10px"}}>
                            <img className='IM_imgIcono36' src={ic_comparar} alt='' />   
                            <div style={{marginLeft: "10px", fontSize:"18px"}}>Equipo vs. Promedio Torneo</div>             
                        </div>  
                        <div  style={{maxWidth:"800px"}}>  
                            {incluirTorneo()}     

                            <div className='CT_contenedorFlex'  style={{marginTop:"20px", marginBottom:"20px"}}>
                                <div>
                                    <img className='IM_imgRedondeada36' src={escudoEq} alt='' />   
                                    <div>{nombreEq}</div>
                                </div>
                                <div>VS</div>
                                <div>
                                    <img className='IM_imgRedondeada36' src={ic_torneo} alt='' />   
                                    <div>{"Promedio Torneo"}</div>
                                </div>
                            </div>    
                            
                            <DinamicoResumenBarra imagen={ic_balon_gol} titulo={"Goles Anotados"} cantidad1={golesAnotados1} cantidad2={golesAnotados2}/>     
                            <DinamicoResumenBarra imagen={""} titulo={"Goles Recibidos"} cantidad1={golesRecibidos1} cantidad2={golesRecibidos2}/>     
                            
                            {tipo_registroinfo==="2" && (
                                <>
                                <DinamicoResumenBarra imagen={ic_asistencia} titulo={"Asistencias"} cantidad1={asistencias1} cantidad2={asistencias2}/>     
                                <DinamicoResumenBarra imagen={ic_disparo_arco} titulo={"Disparos Totales"} cantidad1={disparosT1} cantidad2={disparosT2}/>     
    
                                <DinamicoResumenDisparos tipo={2} datos={datosDisparos} />
                                <DinamicoResumenEfectividad datos={datosEfectividad} />

                                <DinamicoResumenBarra imagen={ic_atajada} titulo={"Atajadas"} cantidad1={atajadas1} cantidad2={atajadas2}/>

                                <DinamicoResumenEfectividad datos={datosPorteria} />
                                </>
                            )}

                            <p width="100%" style={{fontSize:"16px", fontWeight:"bold", color:C_uniformeNegro, textAlign:"center", margin:"20px"}}>DISCIPLINA</p>

                            {tipo_registroinfo==="2" && (<>
                                <DinamicoResumenBarra imagen={ic_falta_recibida} titulo={"Faltas Recibidas"} cantidad1={faltasReibidas1} cantidad2={faltasReibidas2}/>
                                <DinamicoResumenBarra imagen={ic_falta_cometida} titulo={"Faltas Cometidas"} cantidad1={faltasCometidas1} cantidad2={faltasCometidas2}/>
                            </>)}
                            <DinamicoResumenBarra imagen={ic_amarilla} titulo={"Tarjetas Amarillas"} cantidad1={tAmarillas1} cantidad2={tAmarillas2}/>
                            {tieneTarjetaAzul===true && (<DinamicoResumenBarra imagen={ic_tarjeta_azul} titulo={"Tarjetas Azules"} cantidad1={tAzules1} cantidad2={tAzules2}/>)}
                            <DinamicoResumenBarra imagen={ic_tarjeta_roja} titulo={"Tarjetas Rojas"} cantidad1={tRojas1} cantidad2={tRojas2}/>

                            <p width="100%" style={{fontSize:"16px", fontWeight:"bold", color:C_uniformeNegro, textAlign:"center", margin:"20px"}}>JUGADORES</p>
                            <DinamicoResumenBarra imagen={ic_equipo} titulo={"Jugadores Presentes"} cantidad1={numJugadores1} cantidad2={numJugadores2}/>
                            <DinamicoResumenBarra imagen={ic_cambiojugador} titulo={"Cambios Realizados"} cantidad1={numCambios1} cantidad2={numCambios2}/>


                        </div>
                    </>
                
                );
            }            
            else if((pestana==="5")) {                
                nomFuncion="presentarPestana.5";

                let golesAnotados1=0, golesAnotados2=0;
                let valla1=0, valla2=0;
                let totalDisparos1=0, totalDisparos2=0;
                let disparosArco1=0, disparosArco2=0;
                let eDisparo1=0, eDisparo2=0;
                let atajadas1=0, atajadas2=0;
                let eAtajadas1=0, eAtajadas2=0;
                let jLimpio1=0, jLimpio2=0;
                let faltasReibidas1=0, faltasReibidas2=0;
                let faltasCometidas1=0, faltasCometidas2=0;
                let tAmarillas1=0, tAmarillas2=0;
                let tAzules1=0, tAzules2=0;
                let tRojas1=0, tRojas2=0;
                let autogoles1=0, autogoles2=0;


                if(infoClasificacion.length>0){                


                    //let tempInt1=0, tempInt2=0;
                        //let tempFloat1=0, tempFloat2=0;
                        //let tempNum=0;


                        golesAnotados1=encontrarPosicionEquipos(1,false,true);
                        golesAnotados2=0;
                        if(cantidadEq>0) golesAnotados2=100-(((golesAnotados1)/cantidadEq)*100);
                        
                        valla1=encontrarPosicionEquipos(2,false,false);
                        valla2=0;
                        if(cantidadEq>0) valla2=100-(((valla1)/cantidadEq)*100);
            

                        if(tipo_registroinfo===("2")){
                            totalDisparos1=encontrarPosicionEquipos(10,false,true);
                            totalDisparos2=0;
                            if(cantidadEq>0) totalDisparos2=100-(((totalDisparos1)/cantidadEq)*100);
                        
                            disparosArco1=encontrarPosicionEquipos(11,false,true);
                            disparosArco2=0;
                            if(cantidadEq>0) disparosArco2=100-(((disparosArco1)/cantidadEq)*100);
                            
                            eDisparo1=encontrarPosicionEquipos(22,false,true);
                            eDisparo2=0;
                            if(cantidadEq>0) eDisparo2=100-(((eDisparo1)/cantidadEq)*100);                        

                            atajadas1=encontrarPosicionEquipos(9,false,true);
                            atajadas2=0;
                            if(cantidadEq>0) atajadas2=100-(((atajadas1)/cantidadEq)*100);                        

                            eAtajadas1=encontrarPosicionEquipos(23,false,true);
                            eAtajadas2=0;
                            if(cantidadEq>0) eAtajadas2=100-(((eAtajadas1)/cantidadEq)*100);                        
                        }

                        jLimpio1=encontrarPosicionEquipos(8,false,false);
                        jLimpio2=0;
                        if(cantidadEq>0) jLimpio2=100-(((jLimpio1)/cantidadEq)*100);                    

                        if(tipo_registroinfo===("2")){
                            faltasReibidas1=encontrarPosicionEquipos(4,false,true);
                            faltasReibidas2=0;
                            if(cantidadEq>0) faltasReibidas2=100-(((faltasReibidas1)/cantidadEq)*100);
                        }

                        if(tipo_registroinfo===("2")){
                            faltasCometidas1=encontrarPosicionEquipos(5,false,true);
                            faltasCometidas2=0;
                            if(cantidadEq>0) faltasCometidas2=100-(((faltasCometidas1)/cantidadEq)*100);
                        }


                        tAmarillas1=encontrarPosicionEquipos(6,false,true);
                        tAmarillas2=0;
                        if(cantidadEq>0) tAmarillas2=100-(((tAmarillas1)/cantidadEq)*100);

                        tAzules1=encontrarPosicionEquipos(24,false,true);
                        tAzules2=0;
                        if(cantidadEq>0) tAzules2=100-(((tAzules1)/cantidadEq)*100);
                        
                        tRojas1=encontrarPosicionEquipos(7,false,true);
                        tRojas2=0;
                        if(cantidadEq>0) tRojas2=100-(((tRojas1)/cantidadEq)*100);
                        
                        autogoles1=encontrarPosicionEquipos(12,false,true);
                        autogoles2=0;
                        if(cantidadEq>0) autogoles2=100-(((autogoles1)/cantidadEq)*100);
                        
                }
                
                return(
                    <>
                        <div className="CT_contenedorTitulo" style={{marginBottom:"10px"}}>
                            <img className='IM_imgIcono36' src={ic_ranking} alt='' />   
                            <div style={{marginLeft: "10px", fontSize:"18px"}}>Ranking del Equipo</div>             
                        </div>  
                        <div  style={{maxWidth:"800px"}}>  
                            {incluirTorneo()}   

                            <div style={{background:C_colorAccent, fontSize:"14px", color:C_colorPrimaryDark, marginTop:"20px", padding:"4px"}}>Indicadores Positivos</div> 
                            <div className='TX_letraPeq' style={{display:"flex", alignItems:"center", marginLeft:"46px"}}>
                                <div style={{flex:"5", textAlign:"center"}}>{"Indicador / Puntuacion"}</div>
                                <div style={{flex:"2", textAlign:"center"}}>{"%Supera"}</div>   
                                <div style={{flex:"2", textAlign:"center"}}>{"Posición"}</div>                            
                            </div>
                            <div className='BF_lineaInferior'></div>

                            <DinamicoRankingIndicador imagen={ic_balon_gol} indicador={"Goles Anotados"} posicion={golesAnotados1} percentil={golesAnotados2} positivo={true} />
                            <DinamicoRankingIndicador imagen={ic_arco} indicador={"Valla Menos Vencida"} posicion={valla1} percentil={valla2} positivo={true} />
                            
                            {tipo_registroinfo==="2" && (<>
                                <DinamicoRankingIndicador imagen={ic_disparo_arco} indicador={"Total Disparos"} posicion={totalDisparos1} percentil={totalDisparos2} positivo={true} />
                                <DinamicoRankingIndicador imagen={ic_disparo} indicador={"Disparos Al Arco"} posicion={disparosArco1} percentil={disparosArco2} positivo={true} />
                                <DinamicoRankingIndicador imagen={ic_porcentajetiempo} indicador={"Efectividad Disparo"} posicion={eDisparo1} percentil={eDisparo2} positivo={true} />
                                <DinamicoRankingIndicador imagen={ic_atajada} indicador={"Atajadas"} posicion={atajadas1} percentil={atajadas2} positivo={true} />
                                <DinamicoRankingIndicador imagen={ic_porcentajetiempo} indicador={"Efectividad Porteria"} posicion={eAtajadas1} percentil={eAtajadas2} positivo={true} />
                            </>)}

                            <DinamicoRankingIndicador imagen={ic_fairplay} indicador={"Juego Limpio"} posicion={jLimpio1} percentil={jLimpio2} positivo={true} />

                            {tipo_registroinfo==="2" && (<> 
                                <DinamicoRankingIndicador imagen={ic_falta_recibida} indicador={"Faltas Recibidas"} posicion={faltasReibidas1} percentil={faltasReibidas2} positivo={true} />
                            </>)}

                            <div style={{background:C_colorPrimaryDark, fontSize:"14px", color:C_uniformeBlanco, marginTop:"20px", padding:"4px"}}>Indicadores Negativos</div> 
                            <div className='TX_letraPeq' style={{display:"flex", alignItems:"center", marginLeft:"46px"}}>
                                <div style={{flex:"5", textAlign:"center"}}>{"Indicador / Puntuacion"}</div>
                                <div style={{flex:"2", textAlign:"center"}}>{"%Supera"}</div>   
                                <div style={{flex:"2", textAlign:"center"}}>{"Posición"}</div>                            
                            </div>
                            <div className='BF_lineaInferior'></div>

                            {tipo_registroinfo==="2" && (<> 
                                <DinamicoRankingIndicador imagen={ic_falta_cometida} indicador={"Faltas Cometidas"} posicion={faltasCometidas1} percentil={faltasCometidas2} positivo={false} />
                            </>)}

                            <DinamicoRankingIndicador imagen={ic_amarilla} indicador={"Tarjetas Amarillas"} posicion={tAmarillas1} percentil={tAmarillas2} positivo={false} />
                            {tieneTarjetaAzul===true && (<DinamicoRankingIndicador imagen={ic_tarjeta_azul} indicador={"Tarjetas Azules"} posicion={tAzules1} percentil={tAzules2} positivo={false} />)}
                            <DinamicoRankingIndicador imagen={ic_tarjeta_roja} indicador={"Tarjetas Rojas"} posicion={tRojas1} percentil={tRojas2} positivo={false} />
                            <DinamicoRankingIndicador imagen={ic_balon_autogol} indicador={"Autogoles"} posicion={autogoles1} percentil={autogoles2} positivo={false} />
                        
                        </div>
                    </>
                
                );
            }      
            else if((pestana==="6")) {                
                nomFuncion="presentarPestana.6";

                
                return(
                    <>
                    <div className="CT_contenedorTitulo" style={{marginBottom:"10px"}}>
                        <img className='IM_imgIcono36' src={ic_ranking} alt='' />   
                        <div style={{marginLeft: "10px", fontSize:"18px"}}>Ranking de Jugadores</div>             
                    </div>  
                    <div  style={{maxWidth:"800px"}}>  
                        {incluirTorneo()}   


                        {indicadorSel>=1 && (
                        <div className='CT_menuIndicadores' style={tipo_registroinfo==="1" ? ({maxWidth:"400px"}) :  ({maxWidth:"800px"})}>
                        
                            {incluirIndicador(1)}                    
                            {tipo_registroinfo==="2" && incluirIndicador(2)}
                            {tipo_registroinfo==="2" && incluirIndicador(3)}
                            {tipo_registroinfo==="2" && incluirIndicador(4)}
                            {tipo_registroinfo==="2" && incluirIndicador(5)}
                            {tipo_registroinfo==="2" && incluirIndicador(6)}
                            {tipo_registroinfo==="2" && incluirIndicador(7)}
                            {tipo_registroinfo==="2" && incluirIndicador(8)}
                            {tipo_registroinfo==="2" && incluirIndicador(9)}
                            {tipo_registroinfo==="2" && incluirIndicador(10)}
                            {incluirIndicador(11)}
                            {incluirIndicador(12)}
                            {tieneTarjetaAzul===true && incluirIndicador(13)}
                            {incluirIndicador(14)}

                        </div>  
                        ) }

                        <div style={{height:"20px"}}></div>     

                        {indicadorSel>=1 && (
                            <div  className='CT_contenedorFlex'>
                            <DinamicoRankingIndicadores idTorneo={idTorneo} indicador={numSel} titulo={textoPSel} subtitulo={textoSSel} imagen={imagenSel} esporcentaje={numSel===12 ? true : false} idxCant={columnasOrdenarJug[numSel]} infoOrdenadaJugadores={infoOrdenadaJugadores} infoEstadisticas={infoEstadisticas}   modOrigen={idModulo} paramOrigen={paramsAjustado} definirModulo={definirModulo}/>
                            </div>
                        ) }  


                    </div>                 

                </>
                ); 
            }      
            else return(<></>);


        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
        
    }

    const encontrarPosicionEquipos = (indicador, todos, ascendente) => {
        const nomFuncion="encontrarPosicionEquipos";
    
        let posicion=0;
        try{
            let i;
            //let suma=0;
            //let promedio=0;
            let encontrado=false;
            let estaEq=false;
            let contador=0;
    
            if(infoClasificacion.length>0) {
                let col=columnasASumar[indicador];
    
                /*if(!idTorneoSel.equals(idTorneo)){
                    cantidadEq=-1;
                    posicionEq=-1;
                    idTorneoSel=idTorneo;
                }*/
    
                cantidadEq=-1;
                posicionEq=-1;
    
                if(cantidadEq<0){
                    cantidadEq=0;
                    for(i=0;i<infoClasificacion.length;i++){
                        if(infoClasificacion[i][0]===(idTorneo)){
                            cantidadEq++;
                        }
                    }
                }
    
                if(posicionEq<0){
                    for(i=0;i<infoClasificacion.length;i++){
                        if(infoClasificacion[i][0]===(idTorneo) && infoClasificacion[i][2]===(idEquipo)){
                            posicionEq=i;
                            i=infoClasificacion.length;
                            estaEq=true;
                        }
                    }
                }
                else estaEq=true;
    
    
                if(estaEq){
    
                    if(ascendente){
                        for(i=0;i<infoOrdenadaEquipos[0].length;i++){
                            let reg=infoOrdenadaEquipos[indicador][i];
                            if(infoClasificacion[reg][0]===(idTorneo)){
                                contador++;
    
                                if(infoOrdenadaEquipos[indicador][i]===posicionEq){
                                    i=infoOrdenadaEquipos[0].length;
                                    encontrado=true;
                                }
                            }
    
                        }
                        if(encontrado){
                            if(!todos){
                                let partidos=valorNumero(infoClasificacion[posicionEq][6]);
                                let cantidad=valorNumero(infoClasificacion[posicionEq][col]);
    
                                if(partidos>0 && cantidad>0){
                                    posicion=contador;
                                }
    
                            }
                            else posicion=contador;
                        }
                    }
                    else{
                        for(i=infoOrdenadaEquipos[0].length-1;i>=0;i--){
                            let reg=infoOrdenadaEquipos[indicador][i];
                            let partidos=valorNumero(infoClasificacion[reg][6]);
                            if(infoClasificacion[reg][0]===(idTorneo) && (partidos>0 || todos)){
                                contador++;
    
                                if(infoOrdenadaEquipos[indicador][i]===posicionEq){
                                    i=-1;
                                    encontrado=true;
                                    posicion=contador;
                                }
                            }
    
                        }
    
    
                    }
    
                }
    
            }
    
    
    
        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    
        return posicion;
    }

    const selIndicador = (e, sel, num, textoP, textoS, imagen) => {        
        let nomFuncion="selIndicador";

        try{
            if(indicadorSel!==sel){
                numSel=num;
                textoPSel=textoP;
                textoSSel=textoS;
                imagenSel=imagen;
                setIndicadorSel(sel);
            }

        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        } 
    }

    const incluirIndicador = (indicador) => {
        let nomFuncion="incluirIndicador";

        try{
            let fondo="";
            
            let imagen="";
            let texto="";
            let num=0;
            let textoP="";
            let textoS="";

            if(indicador===indicadorSel) fondo="BF_fondolistaspar";

            if(indicador===1){
                imagen=ic_balon_gol;
                texto="G";
                num=0;
                textoP="Goles";
                textoS="Mayor Cantidad de Goles Realizados";
            }
            else if(indicador===2){
                imagen=ic_asistencia;
                texto="A";
                num=2;
                textoP="Asistencias";
                textoS="Mayor Cantidad de Asistencias";
            }
            else if(indicador===3){
                imagen=ic_asistencia;
                texto="G + A";
                num=11;
                textoP="Goles y Asistencias";
                textoS="Mayor Participación en Goles";
            }
            else if(indicador===4){
                imagen=ic_disparo_arco;
                texto="DT";
                num=4;
                textoP="Disparos Totales";
                textoS="Disparos Totales Realizados";
            }
            else if(indicador===5){
                imagen=ic_disparo;
                texto="DA";
                num=5;
                textoP="Disparos al Arco";
                textoS="Disparos Realizados dentro del Arco";
            }
            else if(indicador===6){
                imagen=ic_porcentajetiempo;
                texto="ED";
                num=12;
                textoP="Efectividad de Disparo";
                textoS="Goles por cada 100 Disparos al Arco";
            }
            else if(indicador===7){
                imagen=ic_atajada;
                texto="AT";
                num=3;
                textoP="Atajadas";
                textoS="Total Atajadas Realizadas";
            }
            else if(indicador===8){
                imagen=ic_reloj;
                texto="MJ";
                num=10;
                textoP="Minutos Jugados";
                textoS="Mayor Cantidad de Minutos Jugados";
            }
            else if(indicador===9){
                imagen=ic_falta_recibida;
                texto="FR";
                num=6;
                textoP="Faltas Recibidas";
                textoS="Mayor Cantidad de Faltas Recibidas";
            }
            else if(indicador===10){
                imagen=ic_falta_cometida;
                texto="FC";
                num=7;
                textoP="Faltas Cometidas";
                textoS="Mayor Cantidad de Faltas Cometidas";
            }
            else if(indicador===11){
                imagen=ic_amarilla;
                texto="TA";
                num=8;
                textoP="Tarjetas Amarillas";
                textoS="Mas Tarjetas Amarillas ha Recibido";
            }
            else if(indicador===12){
                imagen=ic_tarjeta_roja;
                texto="TR";
                num=9;
                textoP="Tarjetas Rojas";
                textoS="Mas Tarjetas Rojas ha Recibido";
            }
            else if(indicador===13){
                imagen=ic_tarjeta_azul;
                texto="TZ";
                num=13;
                textoP="Tarjetas Azules";
                textoS="Mas Tarjetas Azules ha Recibido";
            }
            else if(indicador===14){
                imagen=ic_balon_autogol;
                texto="AG";
                num=1;
                textoP="Autogoles";
                textoS="Mas Autogoles Realizados";
            }



        return(      
                <div className="CT_indicador">    
                        <div className={fondo} align="center" style={{minWidth:"20px", maxWidth:"80px", padding:"10px"}} onClick={(e) => selIndicador(e, indicador, num, textoP, textoS, imagen)}  >
                            <img className='IM_imgIcono24' src={imagen} alt="" />
                            <span>{texto}</span>
                        </div>     
                </div>         
        );

        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        } 

    }


    const incluirTorneo = () => {
        const nomFuncion="incluirTorneo";

        try{

            let nomTorneo="";
            let nomSede="";

            if(infoTorneo.length>0){
                nomTorneo=infoTorneo[0][1];
                nomSede="Sede: " + infoTorneo[0][2];
            }


            return(
                <div className='BF_botonBordeAccent'  style={{minWidth:"250px", maxWidth:"500px", display:"flex", alignItems:"center", justifyContent:"left", cursor:"auto"}}>
                        <img className='IM_imgRedondeada36' src={escudoTorneo} alt="" style={{marginLeft:"10px", marginRight:"10px"}}/>
                        <div style={{textAlign:"left"}}>
                            <div className='TX_unaSolaLinea2' style={{fontSize:"16px", color:C_uniformeNegro}}>{nomTorneo}</div>
                            <div className='TX_unaSolaLinea2' style={{fontSize:"14px", color:C_uniformeGrisOscuro}}>{nomSede}</div>
                        </div>
                    </div>
            );

        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        } 
    }

    const incluirInfoTC = () => {
        const nomFuncion="incluirInfoTC";

        try{

            let posicion="-", nombreEquipo="-", pJugados="-", goles="-", golDiferencia="-", puntos="-", escudo=escudoEq;
            let i;

            if(infoClasificacionO.length>0) {
                for (i = 0; i < infoClasificacion.length; i++) {
                    if (infoClasificacionO[i].idtorneo===idTorneo && infoClasificacionO[i].idequipo===idEquipo) {
                        posicion = infoClasificacionO[i].tc_posicion;
                        nombreEquipo = infoClasificacionO[i].nombrecorto;
                        pJugados = infoClasificacionO[i].tc_partidosjugados;
                        goles = infoClasificacionO[i].tc_golesafavor + ":" + infoClasificacionO[i].tc_golesencontra;
                        golDiferencia = infoClasificacionO[i].tc_goldiferencia;
                        puntos = infoClasificacionO[i].tc_puntos;

                        i = infoClasificacion.length;
                    }

                }
            }

            return(
                <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"500px", marginTop:"20px", marginBottom:"20px"}}>
                    <p className='TX_tituloCaja'>{"Clasificación Fase de Grupos"}</p>
                    <div className='BF_cajaBlanca'>
                        <table width="100%">           
                            <tbody>
                                <tr className="TX_textoPeq" align="center">
                                            <td style={{maxWidth:"15px"}}><strong>Pos</strong></td>
                                            <td style={{maxWidth:"40px"}}>{""}</td>
                                            <td style={{maxWidth:"120px"}}>Equipo</td>
                                            <td>PJ</td>
                                            <td>Goles</td>
                                            <td>DG</td>
                                            <td><strong>Pts</strong></td>
                                            <td>{""}</td>
                                </tr>
            
                                <tr style={{cursor:"pointer"}} align="center">
                                    <td><strong>{posicion}</strong></td>
                                    <td> <img className="IM_imgRedondeada36" src={escudo} alt="" /></td>
                                    <td align="left" className="TX_unaSolaLinea2">{nombreEquipo}</td>
                                    <td>{pJugados}</td>
                                    <td>{goles}</td>
                                    <td>{golDiferencia}</td>
                                    <td><strong>{puntos}</strong></td>
                                    <td><img className="IM_imgIcono24" src={ic_entrar_gris} alt="Ingresar"  height="20px" width="20px"  /></td>
                                </tr>
                            
                            </tbody>
                        </table>
                    </div>
                </div>   
            );

        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        } 
    }

    const incluirInfoPartidos = () => {
        const nomFuncion="incluirInfoPartidos";

        try{    
            let partidosFG=[];
            let partidosFE=[];
            let partidosNP=[];

            let i;
            if(infoPartidos.length>0) {
                    for(i=0;i<infoPartidos.length;i++){
                        if (infoPartidos[i][0]===idTorneo && (infoPartidos[i][5]===idEquipo || infoPartidos[i][8]===idEquipo)) {
                            
                            let tempDatos=Object.assign({} , infoPartidosO[i]);
                            //tempDatos=infoPartidosO[i];

                            if(idEquipo!==infoPartidos[i][5]){ //Eq actual es local o visitante
                                let temp=tempDatos.nomCortoEq1;
                                tempDatos.nomCortoEq1=tempDatos.nomCortoEq2;
                                tempDatos.nomCortoEq2=temp;

                                temp=tempDatos.imgEscudoEq1;
                                tempDatos.imgEscudoEq1=tempDatos.imgEscudoEq2;
                                tempDatos.imgEscudoEq2=temp;

                                temp=tempDatos.golesEq1;
                                tempDatos.golesEq1=tempDatos.golesEq2;
                                tempDatos.golesEq2=temp;

                                temp=tempDatos.penaltisEq1;
                                tempDatos.penaltisEq1=tempDatos.penaltisEq2;
                                tempDatos.penaltisEq2=temp;
                            }
                    
                            if(infoPartidos[i][38]===("1")){
                                partidosFG.push(tempDatos);                        
                            }
                            else{
                                partidosFE.push(tempDatos);
                            }

                        }
                    }
            }

            if(infoEquiposGrupo.length && tipo_torneo!=="5"){
                for(i=0;i<infoEquiposGrupo.length;i++){
                    if(infoEquiposGrupo[i][0]===idTorneo){
                        let tempDatos=Object.assign({} , partido);
                        //tempDatos=JSON.parse(JSON.stringify(partido))
                        let tempInfo=Object.assign({} , infoEquiposGrupoO[i]);
                        //let tempInfo;
                        //tempInfo=JSON.parse(JSON.stringify(infoEquiposGrupoO[i]))
                        
                        tempDatos.nomJornada="Por Definir";

                        if(tipo_torneo==="1" || tipo_torneo==="3"){
                            if(!tienenPartido(idEquipo, infoEquiposGrupo[i][1],false)){
                                tempDatos.nomCortoEq2=tempInfo.nombrecorto;
                                tempDatos.imgEscudoEq2=tempInfo.escudo;
                                partidosNP.push(tempDatos); 
                            }
                        }
                        else{
                            if(!tienenPartido(idEquipo, infoEquiposGrupo[i][1],true)){
                                tempDatos.nomCortoEq2=tempInfo.nombrecorto;
                                tempDatos.imgEscudoEq2=tempInfo.escudo;
                                partidosNP.push(tempDatos); 
                            }

                            if(!tienenPartido(infoEquiposGrupo[i][1], idEquipo, true)){
                                tempDatos.nomCortoEq2=tempInfo.nombrecorto;
                                tempDatos.imgEscudoEq2=tempInfo.escudo;
                                partidosNP.push(tempDatos); 
                            }
                        }
                    }
                }


            }
        

            return(
                <>
                {partidosFG.length>0 && 
                (<>
                    <div className='BF_botonBordePrimary'  style={{display:"flex", alignItems:"center", justifyContent:"center", border:"2px solid " + C_colorPrimary, padding:"0px", maxWidth:"800px"}}>
                        <img className='IM_imgIcono24' src={ic_fase_grupos} alt="" style={{marginLeft:"10px", marginRight:"10px"}}/>
                        <div style={{fontSize:"14px", color:C_uniformeGrisOscuro}}>{"FASE DE GRUPOS"}</div>
                    </div>
                    {partidosFG.map((el) => (<DinamicoPartidosProgramados key={el.idPartido} partido={el} linea={false} tipoPartido="dpe" modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo}/>))}
                    <div style={{height:"10px"}}></div>
                </>)}

                {partidosNP.length>0 && 
                (<>
                    {partidosNP.map((el, index) => (<DinamicoPartidosProgramados key={index} partido={el} linea={false} tipoPartido="dpe" modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo}/>))}
                    <div style={{height:"10px"}}></div>
                </>)}

                {partidosFE.length>0 && 
                (<>
                    <div className='BF_botonBordeRojo'  style={{display:"flex", alignItems:"center", justifyContent:"center", border:"2px solid " + C_uniformeRojo, padding:"0px", maxWidth:"800px"}}>
                        <img className='IM_imgIcono24' src={ic_fase_eliminacion} alt="" style={{marginLeft:"10px", marginRight:"10px"}}/>
                        <div style={{fontSize:"14px", color:C_uniformeGrisOscuro}}>{"FASE DE ELIMINACION"}</div>
                    </div>
                    {partidosFE.map((el) => (<DinamicoPartidosProgramados key={el.idPartido} partido={el} linea={false} tipoPartido="dpe" modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo}/>))}
                    <div style={{height:"10px"}}></div>
                </>)}
                
                {partidosFG.length===0 && partidosFE.length===0 && partidosNP.length===0 &&
                (<div className='TX_sinInfo'>{"No Tiene Partidos Registrados"}</div>)}

                </>
            );

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }

    }

    const tienenPartido = (idEquipo1, idEquipo2, mantenerOrden) => {
        const nomFuncion="tienenPartido";

        let resultado=false;
        try{
            // infoPartidos[i][10].equals("1") &&

            if(infoPartidos.length>0){
                let i;
                for(i=0;i<infoPartidos.length;i++){
                    if(infoPartidos[i][0]===(idTorneo) && infoPartidos[i][38]===("1")){
                        if(mantenerOrden){
                            if(infoPartidos[i][5]===(idEquipo1) && infoPartidos[i][8]===(idEquipo2)){
                                resultado=true;
                                i=infoPartidos.length;
                            }
                        }
                        else{
                            if((infoPartidos[i][5]===(idEquipo1) && infoPartidos[i][8]===(idEquipo2)) ||
                                    (infoPartidos[i][5]===(idEquipo2) && infoPartidos[i][8]===(idEquipo1))){
                                resultado=true;
                                i=infoPartidos.length;
                            }
                        }

                    }
                }
            }



        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
        return resultado;
    }

    
    const clickJugador = (e, idJug) => {
        let paramDestino=idJug + "#1#" + idEquipo + "#" + idTorneo; 
        definirModulo(0,modOrigen,paramOrigen,ID_MODULO_DETALLE_JUGADOR,paramDestino);    
    }

    const clickMenu = (e, numPestana) => {
        if(pestana!==numPestana){
            let info=dividirLista(params,"#",5);
            paramsAjustado=info[0] + "#" + numPestana;
            setPestana(numPestana);  
        }      
    }

    const salir = () => {
        if(modOrigen===0 || paramOrigen===""){
            definirModulo(0,0,"",ID_MODULO_DETALLE_TORNEO,idTorneo);     
        }
        else  definirModulo(0,0,"",modOrigen,paramOrigen);      
    }  


    return (
        <>        
            <div className="CT_zonaFija">
                <div className="CT_encabezado">
                        <div className="CT_elementoEncabezado" onClick={salir}><img src={ic_salir} className="IM_imgIcono24" alt="" style={{padding: "8px"}} />  </div>
                        <div className="CT_elementoEncabezado"><img className="IM_imgRedondeada36" height="36px" width="36px" src={ic_escudo_base_gris} alt="" /></div>
                        <div className="CT_elementoEncabezado"><p className="TX_unaSolaLinea">{"Informacion Equipo"}</p></div>
                </div>                
            </div>

            <div style={{paddingTop:"50px"}}>

                <div style={{display:"flex", background:C_colorPrimaryDark, paddingTop:"8px", alignItems:"center", color:C_uniformeBlanco}}>
                    <img className="IM_imgRedondeada46" src={escudoEq} alt="" />
                    <div style={{flex:"1", fontSize:"16px"}}>{nombreEq}</div> 
                    <img className="IM_camiseta55" src={uniforme1} alt="" />
                    <img className="IM_camiseta55" src={uniforme2} alt="" />
                </div>  

                <div className="CT_menuDeslizable">
                        <div className="CT_elementoDeslizable"><div className={pestana==="1" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "1")}>TEMPORADA</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="2" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "2")}>PLANTILLA</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="3" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "3")}>ESTADISTICAS</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="4" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "4")}>EQ Vs LIGA</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="5" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "5")}>RK EQUIPO</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="6" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "6")}>RK JUGADORES</div></div>
                </div>

                <div>

                    {!loading && esError===0 && pestana!=="" && (<div style={{margin:"10px"}}>{presentarPestana()}</div>)  } 
                    
                </div>
                
            </div>
            <div style={{marginBottom:"40px"}}></div>


            {esError!==0 && <ShowError tipoError={esError} funcionCerrar={salir} />}

            {loading && <Loader tipo={"TOTAL"}/>}  

        </>
    );

};

export default Detalle_Equipo;