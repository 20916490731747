import ic_entrar from '../recGraficos/ic_entrar_gris.png';
import ic_activo from '../recGraficos/ic_activo.png';
import ic_responder_gris from '../recGraficos/ic_responder_gris.png';
import ic_guardar_gris from '../recGraficos/ic_guardar_gris.png';
import ic_seleccionar_imagen from '../recGraficos/ic_seleccionar_imagen.png';
import ic_inactivo from '../recGraficos/ic_inactivo.png';
import ic_neutro from '../recGraficos/ic_neutro.png';
import ic_transparente from '../recGraficos/ic_transparente.png';
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';


import "../estilos/IM_Imagenes.css";


import { formatearFecha, recuperarDatoLocal, reportarError } from '../helpers/funciones';
import { C_fondoAccentSuave, C_uniformeGrisOscuro, C_uniformeNegro } from '../constantes/generales';
import { useEffect, useState } from 'react';

const ruta=recuperarDatoLocal("rutaRecursos"); 

let estiloMsjBase = {
    fontWeight:""
}

let imagenInicial=ic_jugadorsinimagen;
let imagenFinal=ic_activo;
let imagenEntrar=ic_entrar;
let empresa="";
let usuario="";
let titulo="";
let fecha="";

let conRespuesta="";
let guardado=ic_transparente;
let conImagen=ic_transparente;


const DinamicoListaMensajes = ({ registro, numRegistro, tipoLista, datos, registroSel=-1, modOrigen=0, paramOrigen="", clickGenerico, incluirLinea=true }) => {
    const nomClase="DinamicoListaMensajes_WB";
    const nomFuncion="DinamicoListaMensajes";

    const [estiloMsjUS, setEstiloMsjUS] = useState({fontWeight:""});
    const [estiloTotalUS, setEstiloTotalUS] = useState({});
    const [estiloFondoUS, setEstiloFondoUS] = useState({});

    


    useEffect(() => {
        const nomFuncion="useEffect";

        try{ 

            if(datos!==null){


                if(datos.idmensaje==="") {
                    let tempEstilo={display:"none"};
                    setEstiloTotalUS(tempEstilo);
                }
                else{
                    setEstiloTotalUS({});
        
                    if(datos.leido==="0"){
                        let tempEstilo={fontWeight:""};
                        tempEstilo.fontWeight="bold";
                        setEstiloMsjUS(tempEstilo);
                    } 
                    else setEstiloMsjUS({fontWeight:""});
        
                    if(registro===registroSel){
                        let tempEstilo={background:C_fondoAccentSuave};
                        setEstiloFondoUS(tempEstilo);
                    }
                    else setEstiloFondoUS({});
                }              

                
    
            }
            
        }catch(err){                            
            reportarError( nomClase, nomFuncion, "", err);                        
        }     
                        
                
    }, [registro, registroSel]);


    try{  

        if(!datos) return null;
       
        if(datos.fotoUser!=="") imagenInicial=ruta + datos.fotoUser;
        else imagenInicial=ic_jugadorsinimagen;
        empresa=datos.empresaUser;
        usuario=datos.nombreUser;
        titulo=datos.titulo;
        fecha=formatearFecha(datos.fecha_envio,5);

        if(datos.idestado_mensaje==="3") imagenFinal=ic_activo;
        else if(datos.idestado_mensaje==="4") imagenFinal=ic_inactivo;
        else imagenFinal=ic_neutro;


        if(datos.contestado==="1") conRespuesta=ic_responder_gris;
        else conRespuesta=ic_transparente;
        if(datos.guardado==="1") guardado=ic_guardar_gris;
        else guardado=ic_transparente;
        if(datos.imagen==="1") conImagen=ic_seleccionar_imagen;
        else conImagen=ic_transparente;


        const manejarClick = () => {
            if(registro===registroSel){
                clickGenerico("CLICK_MENSAJE_BASE",[datos.index,"LIMPIAR", registro]);
                setEstiloFondoUS({});
            }                
            else{
                clickGenerico("CLICK_MENSAJE_BASE",[datos.index,"SELECCIONAR", registro]);
                setEstiloMsjUS(estiloMsjBase);
                let tempEstilo2={background:C_fondoAccentSuave};
                setEstiloFondoUS(tempEstilo2);
            } 
        }



        return(
            <div style={estiloTotalUS} >
                <div  style={estiloFondoUS} className='CT_mensajes' onClick={manejarClick}>
                    <span style={{width:"25px", color:C_uniformeNegro, fontWeight:"bold", textAlign:"center"}}>{numRegistro}</span>
                    <img className="IM_imgRedondeada28" src={imagenInicial} style={{margin:"5px"}} alt=""/>
                    
                    <div style={{flex:"1", flexGrow:"1", flexShrink:"1", minWidth:"100px", textAlign:"left", whiteSpace: "pre-wrap", fontSize:"14px"}}>
                        <div style={{display:"flex", flexWrap:"nowrap"}}>
                            <div style={{flex:"1", flexGrow:"1", flexShrink:"1", minWidth:"100px", marginRight:"4px", color:C_uniformeNegro}}>
                                <div className='TX_unaSolaLinea2' style={estiloMsjUS}>{empresa}</div>
                                <div className='TX_unaSolaLinea2' style={estiloMsjUS}>{usuario}</div>
                            </div>                       

                            <div>
                                <div className='TX_unaSolaLinea2' style={estiloMsjUS}><div style={{textAlign:"right", fontSize:"12px"}}>{fecha}</div></div>
                                <div style={{display:"flex", flexWrap:"nowrap"}}>
                                    <img className="IM_imgIcono16" src={conRespuesta} alt=""/>
                                    <img className="IM_imgIcono16" src={guardado} alt=""/>
                                    <img className="IM_imgIcono16" src={conImagen} alt=""/>
                                </div>
                            </div>

                        </div>
                        
                        <div style={{color:C_uniformeGrisOscuro}}>{titulo}</div>               
                    </div>
    
                    {imagenFinal!=="" && (<img className="IM_imgIcono16" src={imagenFinal} alt=""/>)}   
                    {imagenEntrar!=="" && (<img className="IM_imgIcono24" src={imagenEntrar} alt=""/>)}  
                </div>
                {incluirLinea && (<div className='BF_lineaInferior'></div>)}
            
            </div>
        );

    }catch(err){
        reportarError(nomClase, nomFuncion,"",err);
    } 

};


export default DinamicoListaMensajes;